import {
  MOVIMIENTO_PERSONAL_CALCULANDO,
  MOVIMIENTO_PERSONAL_IMPRIMIENDO,
  GET_DATOS_EMPLEADOR,
  MOVIMIENTO_PERSONAL_MENSAJE,
  MOVIMIENTO_PERSONAL_CARGANDO_EMPLEADOR,
  MOVIMIENTO_PERSONAL_CARGANDO_AFILIADO,
  MOVIMIENTO_PERSONAL_MENSAJE_CARGANDO_AFILIADO,
  GET_DATOS_AFILIADO,
  MOVIMIENTO_PERSONAL_GUARDANDO
} from "../../../config/types";
import axios from "axios";
import uriConfig from "../../../config/api";
import store from "../../../store";
import { toastr } from 'react-redux-toastr';

export const calculando = value => async dispatch => {
  dispatch({
    type: MOVIMIENTO_PERSONAL_CALCULANDO,
    payload: value
  });
};

export const imprimiendo = value => async dispatch => {
  dispatch({
    type: MOVIMIENTO_PERSONAL_IMPRIMIENDO,
    payload: value
  });
};
export const mensaje = value => async dispatch => {
  dispatch({
    type: MOVIMIENTO_PERSONAL_MENSAJE,
    payload: value
  });
};
export const cargandoEmpleador = value => async dispatch => {
  dispatch({
    type: MOVIMIENTO_PERSONAL_CARGANDO_EMPLEADOR,
    payload: value
  });
};
export const guardando = value => async dispatch => {
  dispatch({
    type: MOVIMIENTO_PERSONAL_GUARDANDO,
    payload: value
  });
};
export const cargandoAfiliado = value => async dispatch => {
  dispatch({
    type: MOVIMIENTO_PERSONAL_CARGANDO_AFILIADO,
    payload: value
  });
};

export const mensajeCargandoAfiliado = value => async dispatch => {
  dispatch({
    type: MOVIMIENTO_PERSONAL_MENSAJE_CARGANDO_AFILIADO,
    payload: value
  });
};

export const getDatosEmpleador = () => async dispatch => {
  dispatch(cargandoEmpleador(true));
  dispatch(mensajeCargandoAfiliado(true));
  const { main } = store.getState();
  axios
    .get(`${uriConfig.apiEmpleador.getDatos(main.datosSession.rutEmpresa)}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: GET_DATOS_EMPLEADOR,
          payload: res.data.rows
        });
      }
      dispatch(cargandoEmpleador(false));
    })
    .catch(error => {
      dispatch(cargandoEmpleador(false));
    });
};

export const getDatosAfiliado = data => async dispatch => {
  dispatch(cargandoAfiliado(true));
  dispatch(mensajeCargandoAfiliado(""));
  axios
    .post(`${uriConfig.apiEmpleador.postGetDataAfiliado}`, {
      P_RUT: parseInt(
        data.rut
          .split("-")[0]
          .split(".")
          .join("")
      )
    })
    .then(res => {
      if (res.status === 200) {
        if (res.data.rows.length === 0)
          dispatch(mensajeCargandoAfiliado("No se encontró el rut"));
        dispatch({
          type: GET_DATOS_AFILIADO,
          payload: res.data.rows
        });
      }
      dispatch(cargandoAfiliado(false));
    })
    .catch(error => {
      dispatch(mensajeCargandoAfiliado("Error al cargar datos."));
      dispatch(cargandoAfiliado(false));
    });
};

export const guardarDesvinculacion = data => async dispatch => {
  dispatch(mensaje(""));
  dispatch(guardando(true));

  const { main } = store.getState();
  axios
    .post(
      `${uriConfig.apiEmpleador.postRegistrarFiniquito}`,
      {
        P_AFILIADO: parseInt(
          data.rut
            .split("-")[0]
            .split(".")
            .join("")
        ),
        P_EMPLEADOR: main.datosSession.rutEmpresa,
        P_FINILAB: data.startDate ? data.startDate : "",
        P_FTERLAB: data.startDate2,
        P_CODNOTIF: 2,
        P_USUARIO: main.datosSession.rutUsuario,
        P_ISAPRE: main.datosSession.frontOrigin
      },
      {
        validateStatus: function (status) {
          return status < 500;
        },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf"
        }
      }
    )
    .then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `ComprobanteDesvinculacion${
          main.datosSession.rutEmpresa
          }-${new Date()}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        // dispatch(mensaje("OK"));
        dispatch(
          toastr.success("Felicidades!", "Desvinculación registrada correctamente.")
        );
        dispatch(guardando(false));

      } else if (res.status === 400) {
        console.log(res.data);
        var decodedString = String.fromCharCode.apply(
          null,
          new Uint8Array(res.data)
        );
        var obj = JSON.parse(decodedString);
        const msj = obj.db.outBinds.CODE_OUT;
        let mensaje = '';
        if (msj === "BAN-REC-0002") {
          mensaje = "El rut ingresado no se encuentra registrado como afiliado de la Isapre.";
        } else if (msj === "BAN-REC-0003") {
          mensaje = "El Rut ingresado no se encuentra registrado como empleado de la empresa.";
        } else if (msj === "BAN-REC-0004") {
          mensaje = "Afiliado ya registra desvinculación para la misma fecha y empleador";
        } else {
          mensaje = "Error interno de la aplicación.";
        }

        dispatch(
          toastr.error("Error!", mensaje)
        );

        dispatch(guardando(false));

      }
      dispatch(guardando(false));

    })
    .catch(error => {
      console.log(error.response);
      dispatch(mensaje("Error interno de la aplicación. Intente nuevamente."));
      dispatch(guardando(false));

    });
};

export const pdfSimulacion = params => async dispatch => {
  const { main } = store.getState();
  dispatch(imprimiendo(true));
  axios
    .post(
      `${uriConfig.apiCotizaciones.postSimulacionIntereses}`,
      {
        IMONTO: params.montoFinal,
        IADICIONAL: 0,
        IISAPRE: main.datosSession.frontOrigin,
        IFECHA: params.fechaPago,
        periodo: params.ano.toString() + params.mes.toString()
      },
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf"
        }
      }
    )
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Simulacion${main.datosSession.rutEmpresa}-${new Date()}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      dispatch(imprimiendo(false));
    })
    .catch(error => {
      dispatch(imprimiendo(false));
    });
};
