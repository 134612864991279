import React, { Component } from "react";
import "react-table/react-table.css";
import { connect } from "react-redux";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import "react-datepicker/dist/react-datepicker.css";
import { formatearNumero, limpiarNumero, removeDotsFromString } from "../../../../helpers/util";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import { getCalculoSiete, calcularIntereses, setTotalFinalSuma, setTotalFinalResta } from "../actions";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { FaFileUpload, FaFileDownload } from "react-icons/fa";
import store from "../../../../store";
import {Button, Modal} from "react-bootstrap";
import {InputGroup} from "../../../certificados/DescargaCertificados/view";

class CuadroPeriodo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      calculado: [],
      show:false,
      checked: [],
      adicional: [],
      imponible: [],
      reajuste: [],
      recInteres: [],
      intereses: [],
      gastos: [],
      pretotal: [],
      total: [],
      deuda: [],
      cotiza7: [],
      bloqueados: [],
      totalFinal: 0,
      calculoSiete: this.props.calculoSiete,
      errorformingresar: [],
      checkedID: ''
    };
    this.toggleExpand = this.toggleExpand.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    //modal
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  // componentWillReceiveProps(newProps) {
  //   this.setState({ calculoSiete: newProps.calculoSiete });
  // }
  //antes de que el componente sea montado
  componentWillMount() {
    const adicional = this.props.deuda.map(v => v.ORIGEN === 'DNP' ? v.VOLUNTARIO:0);
    const imponible = this.props.deuda.map(v => v.IMPONIBLE);
    const deuda = this.props.deuda.map(v => v.MONTO_DEUDA);
    const cotiza7 = this.props.deuda.map(v => v.MONTO_DEUDA === v.MONTO_PACTADO ? v.COTIZA7:0);
    const bloqueados = this.props.deuda.map(v => 0);
    const caso = this.props.deuda.map(v => v.MONTO_DEUDA === v.MONTO_PACTADO ? '1' : '2');
    const reajuste = this.props.deuda.map(v => 0);
    const recInteres = this.props.deuda.map(v => 0);
    const intereses = this.props.deuda.map(v => 0);
    const gastos = this.props.deuda.map(v => 0);
    const total = this.props.deuda.map(v => 0);
    const calculado = this.props.deuda.map(v => false);
    const pretotal = this.props.deuda.map(v => v.ORIGEN === 'DNP' ? v.COTIZA7 + v.VOLUNTARIO:0);
    const totalFinal = 0;
    const checked = this.props.deuda.map(v => false);
    const errorformingresar = this.props.deuda.map(v => false);
    const calculo = { sieValue: 0, sieIndex: null, sieId: null };
    this.setState({
      adicional, imponible, deuda, cotiza7, reajuste, recInteres, intereses, gastos, bloqueados, checked, total, caso, calculo, pretotal, errorformingresar
    });
  }

  //actualización de propiedades
  componentWillReceiveProps(news) {
    if (news.calculoSiete.sieId !== '' && news.calculoSiete.sieId !== undefined) {
      this.props.deuda.map((d, index) => {
        if (this.state.checkedID === news.id && d.PERIODO === news.calculoSiete.sieId && index === news.calculoSiete.sieIndex && this.state['checked'][index] === true) {
          this.state['gastos'].splice(index, 1, news.calculoSiete.sieValue.GASTOS);
          this.state['recInteres'].splice(index, 1, news.calculoSiete.sieValue.RECINTERES);
          this.state['reajuste'].splice(index, 1, news.calculoSiete.sieValue.REAJUSTE);
          this.state['intereses'].splice(index, 1, news.calculoSiete.sieValue.INTERESES);
          this.state['total'].splice(index, 1, news.calculoSiete.sieValue.TOTAL.toFixed(0));
          //Si es NDNP y hay que ingresar la renta imponible
          if(d.ORIGEN === 'NODNP'){
            if(d.MONTO_DEUDA === d.MONTO_PACTADO){
              d.COTIZA7 = news.calculoSiete.sieValue.SIETEPORCIENTO;
              d.IMPONIBLE = parseInt(this.state['imponible'][index],10);
              d.ADICIONAL = parseInt(this.state['adicional'][index],10);
            } else {
              d.COTIZA7 = 0;
              d.ADICIONAL = parseInt(this.state['adicional'][index],10);
            }
            this.state['cotiza7'].splice(index, 1, news.calculoSiete.sieValue.SIETEPORCIENTO);
            this.state['pretotal'].splice(index, 1, news.calculoSiete.sieValue.SIETEPORCIENTO + parseInt(this.state['adicional'][index]));

          }
          //Validar tope imponible para enviar msj
          let calculoImponible = typeof this.state['imponible'][index]  === 'string'?
              Math.round(parseFloat(removeDotsFromString(this.state['imponible'][index])) * 0.07)
              :
              Math.round(this.state['imponible'][index]* 0.07);
          if(calculoImponible > news.calculoSiete.sieValue.SIETEPORCIENTO && news.calculoSiete.sieValue.SIETEPORCIENTO > 0){
            this.state['show'] = true;
            this.state['checked'][index] = false;
            this.state['imponible'][index] = news.calculoSiete.sieValue.IMPONIBLE;
            this.state['calculado'][index] = true;
          } else {
            this.state['calculado'][index] = false;
          }
          const arr = {};
          arr['intereses'] = this.state['intereses'];
          arr['gastos'] = this.state['gastos'];
          arr['recInteres'] = this.state['recInteres'];
          arr['reajuste'] = this.state['reajuste'];
          arr['total'] = this.state['total'];
          arr['cotiza7'] = this.state['cotiza7'];
          arr['show'] = this.state['show'];
          arr['pretotal'] = this.state['pretotal'];
          arr['checked'] = this.state['checked'];
          arr['imponible'] = this.state['imponible'];
          arr['calculado'] =  this.state['calculado'];
          arr['checkedID'] = "";
          d.GASTOS_COB = this.state['gastos'][index];
          d.RECINTERES = this.state['recInteres'][index];
          d.REAJUSTE = this.state['reajuste'][index];
          d.INTERES = this.state['intereses'][index];
          d.TOTAL_PAGAR = parseInt(this.state['total'][index],10);
          this.setState(arr);
          if(!this.state['calculado'][index]){
            this.props.dispatch(setTotalFinalSuma(news.calculoSiete.sieValue.TOTAL.toFixed(0)));
            this.props.update(d,true);
          }
        } else if (this.state.checkedID === news.id && d.PERIODO === news.calculoSiete.sieId && index === news.calculoSiete.sieIndex && this.state['checked'][index] === false) {
          let total = this.state['total'][index];
          this.state['intereses'].splice(index, 1, 0);
          this.state['gastos'].splice(index, 1, 0);
          this.state['recInteres'].splice(index, 1, 0);
          this.state['reajuste'].splice(index, 1, 0);
          this.state['total'].splice(index, 1, 0);
          this.state['pretotal'].splice(index, 1, parseInt(this.state['adicional'][index]) + this.state['cotiza7'][index]);
          this.state['show'] = false;
          this.state['calculado'].splice(index, 1, false);
          //this.state['cotiza7'].splice(index, 1, 0);
          this.props.dispatch(setTotalFinalResta(total));
          const arr = {};
          arr['intereses'] = this.state['intereses'];
          arr['gastos'] = this.state['gastos'];
          arr['recInteres'] = this.state['recInteres'];
          arr['reajuste'] = this.state['reajuste'];
          arr['total'] = this.state['total'];
          arr['cotiza7'] = this.state['cotiza7'];
          arr['show'] = this.state['show'];
          arr['pretotal'] = this.state['pretotal'];
          arr['calculado'] =  this.state['calculado'];
          arr['checkedID'] = "";
          this.setState(arr);   
          this.props.update(d,false);
        }
      });
    }

  }
  onChange(event, row, ind) {
    const { id } = this.props;
    const { checked, adicional, imponible, caso, errorformingresar, checkedID } = this.state;

    checked.splice(ind, 1, event.target.checked);
    const arr = {};
    arr[checked] = checked;
    arr['checkedID'] = id;
    this.setState(arr);
    if (checked[ind] === true) {
      let sAdicional = adicional[ind] !== 0 ? parseInt(removeDotsFromString(adicional[ind])) : 0;
      let sImponible = imponible[ind] !== 0 ? parseInt(removeDotsFromString(imponible[ind])) : 0;
      let enviarRequest = true;
      // validar ingreso de datos para calcular los montos
      if (row.ORIGEN === "NODNP") {
        if (sImponible === 0) {
          checked.splice(ind, 1, false);
          errorformingresar.splice(ind, 1, true);
          const arr = {};
          arr[checked] = checked;
          arr[errorformingresar] = errorformingresar;
          this.setState(arr);
          enviarRequest = false;
        } else {
          if (sAdicional === 0 && caso[ind] === '2'){
            checked.splice(ind, 1, false);
            errorformingresar.splice(ind, 1, true);
            const arr = {};
            arr[checked] = checked;
            arr[errorformingresar] = errorformingresar;
            this.setState(arr);
            enviarRequest = false;
          }
        }
      }
      if(enviarRequest){
        const { main } = store.getState();
        errorformingresar.splice(ind, 1, false);
        const arr = {};
        arr[errorformingresar] = errorformingresar;
        this.setState(arr);
        const data = {
          PR_AFIL_NRUT: row.RUT_AFILIADO,
          PR_ISAPRE: main.datosSession.frontOrigin,
          PR_IMPONIBLE: row.MONTO_DEUDA === row.MONTO_PACTADO? sImponible : 0,
          PR_ADICIONAL: sAdicional,
          PR_PERIODO: row.PERIODO,
          PR_TIPO_CALCULO: row.ORIGEN === "DNP"? 1 : 2 
        };

        this.props.dispatch(getCalculoSiete(data, ind, row.PERIODO));
      }
    } else {
      let sAdicional = 0;
      let sImponible = 0;
      const { main } = store.getState();
      const data = {
        PR_AFIL_NRUT: row.RUT_AFILIADO,
        PR_ISAPRE: main.datosSession.frontOrigin,
        PR_IMPONIBLE: sImponible,
        PR_ADICIONAL: sAdicional,
        PR_PERIODO: row.PERIODO,
        PR_TIPO_CALCULO: row.ORIGEN === "DNP"? 1 : 2 
      };
      this.props.dispatch(getCalculoSiete(data, ind, row.PERIODO));
      //si es ndnp, borrar adicional y/o renta imponible al descheckear
      if(row.ORIGEN === 'NODNP') {
        const { adicional, imponible, caso, cotiza7 } = this.state;
        const arr = {};
        if(caso[ind] === '1'){
          adicional.splice(ind, 1, 0);
          imponible.splice(ind, 1, 0);
          cotiza7.splice(ind, 1, 0);
          arr[imponible] = imponible;
          arr[cotiza7] = cotiza7;
        } else {
          adicional.splice(ind, 1, 0);
        }
        arr[adicional] = adicional;
        this.setState(arr);
      }
    }
  }

  onChangeInput(event) {
    const { id, value } = event.target;
    const re = /^[0-9\b]+$/;
    //Si hay un borrado
    let valueRemoveString = 0;
    if(value !== ""){
      valueRemoveString = removeDotsFromString(value);
    } else {
      valueRemoveString = 0;
    }
    //Validar existencia de caracteres
    if (valueRemoveString === '' || re.test(valueRemoveString)) {
      const tipo = id.split("-")[0];
      const index = parseInt(id.split("-")[1]);
      //si el tipo es adicional, sumar al pretotal
      if (tipo === 'adicional'){
        this.state['pretotal'].splice(index, 1,parseInt(valueRemoveString) + parseInt(this.state['cotiza7'][index]));
      }
      //Este calculo funciona pero no se considera el tope imponible (calculo del 7%)
      const arr = {};
      if(tipo === "imponible"){
        const cotiza7 = parseInt(valueRemoveString) * 0.07;
        this.state['cotiza7'].splice(index, 1, cotiza7);
        arr['cotiza7'] = this.state['cotiza7'];
        this.state['pretotal'].splice(index, 1,cotiza7 + parseInt(this.state['adicional'][index]));
      }
      this.state[tipo].splice(index, 1, valueRemoveString);
      arr[tipo] = this.state[tipo];
      arr['pretotal'] = this.state['pretotal'];
      this.setState(arr);
    }


  }

  getDataFromIndex = index => this.props.deuda && this.props.deuda[index];

  toggleExpand() {
    this.setState({ expand: !this.state.expand });
  }

  handleClose () {
    //Cerrar modal
    this.setState({show:false})
  };
  handleShow () {
    this.setState({show:true})
  };

  render() {
    const { deuda, periodo, origen, datosSession } = this.props;
    const { adicional, cotiza7, imponible, recInteres, total, gastos, reajuste, show, pretotal, errorformingresar, caso } = this.state;

    return (
      <React.Fragment>
        <div className="mainDetallePago">
          <div className="row m-r-0">
            <div className="col-6 deudaPagoPeriodoTitulos">{periodo ? periodo.toString().substr(4, 2) + '/' + periodo.toString().substr(0, 4) : ""}</div>
            <div className="col-4 deudaPagoPeriodoTitulos">{origen}</div>
            <div
              className="col-2 deudaPagoPeriodoTitulos p-b-3"
              onClick={this.toggleExpand}
            >
              {!this.state.expand ? (
                <FaFileDownload color="white" />
              ) : (
                  <FaFileUpload color="white" />
                )}
              <Badge variant="warning" className="m-l-xs">
                {this.state.checked.filter(v => v === true).length || ""}
              </Badge>
            </div>
          </div>
          <Modal show={show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Tope Imponible</Modal.Title>
            </Modal.Header>
            <Modal.Body>El valor ingresado supera el tope imponible de este mes, debe ingresar el tope imponible legal vigente</Modal.Body>
            <Modal.Footer>
              <Button
                  variant={
                    datosSession &&
                    datosSession.frontOrigin === "B"
                        ? "danger"
                        : "success"
                  }
                  onClick={this.handleClose}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="row">
            <div className="col-12">
              <Collapse in={this.state.expand}>
                <div id="tabla" className="table-responsive">
                  <Table className="tableDeudaPago m-l-xs m-t-xs m-b-xs">
                    <thead>
                      <tr key={periodo + origen}>
                        <th>Rut Afiliado</th>
                        <th>Nombre Afiliado</th>
                        {origen === "NDNP" ? <th>Monto Pactado</th> : <th>Monto Declarado</th>}
                        {origen === "NDNP" && <th>Deuda</th>}
                        <th>Renta Imponible</th>
                        <th>7% Salud</th>
                        <th>Adicional</th>
                        <th>Total</th>
                        <th>Reajuste</th>
                        <th>Interés + Recargo</th>
                        <th>Gastos</th>
                        <th>Total a pagar</th>
                        <th>Sel</th>
                      </tr>
                    </thead>
                    <tbody>
                      {deuda.map((row, index) => (
                        <tr key={row.NOMBRE_AFILIADO}>
                          {/* RUT AFILIADO */}
                          <td className="nowrap">
                            {row.RUT_AFILIADO}-{row.DV}
                          </td>

                          {/* NOMBRE AFILIADO */}
                          <td className="">{row.NOMBRE_AFILIADO}</td>

                          {/* MONTO PACTADO */}
                          <td className="nowrap">
                            $ {formatearNumero(row.MONTO_PACTADO || 0)}
                          </td>

                          {/* DEUDA */}
                          {origen === "NDNP" && (
                            <td className="nowrap">
                              $ {formatearNumero(this.state.deuda[index] || 0)}
                            </td>
                          )}

                          {/* IMPONIBLE */}
                          {origen === "DNP" || this.state.deuda[index] !== row.MONTO_PACTADO ? (
                            <td className="nowrap">
                              $ {formatearNumero(row.IMPONIBLE || 0)}
                            </td>
                          ) : (
                              <td>
                                {" "}
                                <Form.Control
                                  required
                                  className="campoEditable"
                                  id={`imponible-${index}`}
                                  disabled={this.state.deuda[index] < row.PACTADO || this.state.checked[index]}
                                  onChange={this.onChangeInput}
                                  value={formatearNumero(
                                    (imponible && imponible[index]) || 0
                                  )}
                                />
                                {errorformingresar[index] === true && row.ORIGEN === "NODNP" && caso[index] === "1" ? (
                                    <span style={{color:"red"}}>Campo obligatorio</span>
                                ):(null)}

                              </td>
                            )}

                          {/* 7% */}
                          <td className="nowrap">
                            $ {formatearNumero(cotiza7[index] || 0)}
                          </td>

                          {/* ADICIONAL */}
                          {origen === "DNP" ?  (
                          // {origen === "DNP" || this.state.deuda[index] >= row.MONTO_PACTADO ?  (
                            <td className="nowrap">
                              $ {formatearNumero(adicional[index] || 0)}
                            </td>
                          ) : (
                              <td>
                                {" "}
                                <Form.Control
                                  required
                                  className="campoEditable"
                                  disabled={this.state.checked[index]}
                                  id={`adicional-${index}`}
                                  onChange={(e) => this.onChangeInput(e)}
                                  value={formatearNumero(
                                    (adicional && adicional[index]) || 0
                                  )}
                                />
                                {errorformingresar[index] === true && row.ORIGEN === "NODNP" && caso[index] === "2" ? (
                                    <span style={{color:"red"}}>Campo obligatorio</span>
                                ):(null)}
                              </td>
                            )}

                          {/* TOTAL7ADI */}
                          <td className="nowrap">
                            $ {formatearNumero(pretotal[index] || 0)}
                          </td>
                          {/* REAJUSTE */}
                          <td className="nowrap">
                            $ {formatearNumero(reajuste[index] || 0)}
                          </td>

                          {/* INTERES + RECARGO */}
                          <td className="nowrap">
                            ${" "}
                            {formatearNumero(
                              (recInteres[index] || 0)
                            )}
                          </td>

                          {/* GASTOS */}
                          <td className="nowrap">$ {formatearNumero(gastos[index] || 0)}</td>

                          {/* TOTAL A PAGAR */}
                          <td className="nowrap">
                            ${formatearNumero(total[index] || 0)}
                            {/* {formatearNumero(
                              (adicional[index] || 0) +
                              (row.COTIZA7 || cotiza7[index] || 0) +
                              (row.REAJUSTE || 0) +
                              (row.GASTOS_COB || 0) +
                              (row.INTERES || 0) +
                              (row.RECARGO || 0) +
                              ((origen === "NDNP" && this.state.deuda[index]) || 0)
                            )} */}
                          </td>

                          {/* CHECKBOX */}
                          <td className="nowrap">
                            <Form.Check
                              custom
                              onChange={(e) => this.onChange(e, row, index)}
                              id={`${periodo}_${origen}:${index}`}
                              inline
                              value={this.state.checked[index]}
                              // disabled={origen === "NDNP" && this.state.deuda[index] === row.PACTADO && (imponible[index] === 0 || imponible[index] === "")}
                              checked={this.state.checked[index]}
                              label=""
                              type="checkbox"
                              className="m-r-0"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  calculoSiete: state.pagoCotizaciones.calculoSiete
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CuadroPeriodo);
