const local = window.location.hostname.indexOf("localhost") >= 0;
let host = "";
if (local) host = "http://localhost:5000";
else host = 'https://' + window.location.hostname.replace("web", "api");
let ambiente;
let http;
let puerto;
if (host.indexOf("dev") >= 0) {
  http = "http";
  puerto = ":5555";
  ambiente = "dev-";
}
else if (host.indexOf("qa") >= 0) {
  http = "http";
  puerto = ":5555";
  ambiente = "qa-";
} else {
  http = "https";
  ambiente = "";
  puerto = "";
}

console.log(process.env.NODE_ENV);
console.log(process.env.API_PLANILLA);
console.log(window.location.hostname);

let apiCotizacionesUri = `${host}/v1/cotizaciones`;
let apiAuthorizationUri = `${host}/v1/authorization`;
let apiEmpleadorUri = `${host}/v1/empleador`;
let apiCertificadosUri = `${host}/v1/certificados`;
let apiDeudaEmpresa = `${host}/v1/deudaEmpresa`;
let apiPlanilla = `https://${ambiente}api-planillas.banmedica.cl/api/v1.0/Planillas/`;

const apiCotizaciones = {
  getDetalleDeuda: `${apiCotizacionesUri}/detalleDeuda`,
  getDetalleDeudaPago: `${apiCotizacionesUri}/detalleDeuda/parapago?tipoDeuda=ALL`,
  getExportarExcel: `${apiCotizacionesUri}/detalleDeuda/exportar`,
  postCalcularIntereses: `${apiCotizacionesUri}/calculoIntereses`,
  postSimulacionIntereses: `${apiCotizacionesUri}/calculoIntereses/simulacion`,
  getPagoId: `${apiCotizacionesUri}/pago/pagoID`,
  getPagoIdParams: `${apiCotizacionesUri}/pago/pagoIDParams`,
  getHistorialPago: `${apiCotizacionesUri}/pago/historial`,
  getCalculoSiete: `${apiCotizacionesUri}/pago/calculo-siete`,
  postFinalizarPago: `${apiCotizacionesUri}/pago/finalizar-pago`,
  postInsertarPago: `${apiCotizacionesUri}/pago/insertar-pago`,
  postInsertarPagoCompleto: `${apiCotizacionesUri}/pago/insertar-pago-completo`,
  postInsertarPagoCompletoMDPG: `${apiCotizacionesUri}/pago/insertar-pago-completo-mdpg`,
  postObtenerInformacionPago: `${apiCotizacionesUri}/pago/resumen-pago`,
  postObtenerInformacionPagoMDPG: `${apiCotizacionesUri}/pago/resumen-pago-mdpg`,
  getObtenerPlanillasPago: `${apiPlanilla}PrintPlanillasBancobroEmp`,
  postObtenerLeyUrgencia: `${apiCotizacionesUri}/pago/lista-leyurgencia`,
  postInsertarPagoCompletoLeyUrgencia: `${apiCotizacionesUri}/pago/insertar-pago-completo-ley-urgencia`,
  postObtenerInformacionPagoLeyUrgencia: `${apiCotizacionesUri}/pago/resumen-pago-leyurgencia`,
  postObtenerComprobantePago: `${apiCotizacionesUri}/pago/comprobante-pago-leyurgencia`,
};

const apiCertificados = {
  postGenerarDeuda: `${apiCertificadosUri}/deuda`,
  postBoletinLaboral: `${apiCertificadosUri}/boletin-laboral`,
  postCotizacionesPagadas: `${apiCertificadosUri}/cotizaciones-pagadas`,
};

const apiDeudaEmpresas = {
  getDeudaEmpresa: (fecha) => `${apiDeudaEmpresa}/${fecha}`,
  getExportarExcelDeudaEmpresa: (fecha) => `${apiDeudaEmpresa}/exportar/${fecha}`,
};

const apiAuthorization = {
  getToken: `${apiAuthorizationUri}/token`,
  postTokenData: `${apiAuthorizationUri}/token`,
};

const apiEmpleador = {
  getDatos: (rut) => (`${apiEmpleadorUri}/antecedentes/${rut}`),
  getCasaMatrizSucursales: (rut) => (`${apiEmpleadorUri}/antecedentes/${rut}/sucursales`),
  postActualizarDatos: `${apiEmpleadorUri}/antecedentes`,
  getDatosContacto: (rut) => (`${apiEmpleadorUri}/${rut}/contactos`),
  eliminarContacto: (rut, rutContacto) => (`${apiEmpleadorUri}/${rut}/contactos/${rutContacto}`),
  agregarContacto: (rut) => (`${apiEmpleadorUri}/${rut}/contactos`),
  editarContacto: (rut) => (`${apiEmpleadorUri}/${rut}/contactos`),
  postRegistrarFiniquito: `${apiEmpleadorUri}/movimientoPersonal/registro-desvinculacion`,
  postListadoDesvinculaciones: `${apiEmpleadorUri}/moviientoPersonal/listado-desvinculacione`,
  postGetDataAfiliado: `${apiEmpleadorUri}/movimientoPersonal/datos-afiliado`,
  getComunas: `${apiEmpleadorUri}/antecedentes/comunas`,
  getRegiones: `${apiEmpleadorUri}/antecedentes/regiones`
};

const uriConfig = {
  apiCotizaciones,
  apiAuthorization,
  apiEmpleador,
  apiCertificados,
  ambiente,
  apiDeudaEmpresas,
  http,
  puerto,
};

export default uriConfig;
