import React, { Component } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { withRouter } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  getInformacionPagoLeyUrgencia,
  getDataToken,
  descargarComprobante
} from "./actions";
import Table from "react-bootstrap/Table";
import { formatearNumero } from "../../helpers/util";
const moment = require("moment");

class CotizacionesPagoExitoso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      calculado: false,
      checked: [],
      adicional: [],
      imponible: [],
      deuda: [],
      cotiza7: [],
      bloqueados: []
    };
    this.handleDescargarComprobante = this.handleDescargarComprobante.bind(
      this
    );
    this.redirect = this.redirect.bind(this);

  }

  redirect(){
    this.props.history.push("/pagoLeyUrgencia");
  }

  componentDidMount() {
    this.props.dispatch(getInformacionPagoLeyUrgencia());
  }

  handleDescargarComprobante() {
    this.props.dispatch(descargarComprobante());
  }
  render() {
    const { cargandoTabla, datosPago, descargando } = this.props;
    const { frontOrigin } = this.props.datosSession;

    return (
      <React.Fragment>
        {frontOrigin && (
          <div
            role="main"
            className={`container-fluid ${
              frontOrigin === "B" ? "banmedica" : "vidaTres"
            } contenedor`}
            id="contenedor"
            name="contenedor"
          >
            <div className="row justify-content-left h2 m-l-0">
              <p>Comprobante de pago</p>
            </div>
            <div className="row justify-content-left m-l-0">
              Folio pago: {Math.floor(Math.random() * 100000)}
            </div>
            <div className="row justify-content-left m-l-0">
              Forma de pago: Webpay
            </div>
            <div className="row justify-content-left m-l-0">
              Fecha de pago: {moment().format("DD/MM/YYYY")}
            </div>

            <div className="row justify-content-left m-t m-l-0">
              <b>
                <u>Detalle pago:</u>
              </b>
            </div>

            <div className="col-sm-11 m-l-0 p-l-0 m-t">
              <Table className="tableDeuda">
                <thead>
                  <tr>
                    <th className="vertical-center">Período</th>
                    <th className="vertical-center">RUT</th>
                    <th className="vertical-center">Nombre</th>
                    <th className="vertical-center">Fecha Evento</th>
                    <th className="vertical-center">N°Cuota</th>
                    <th className="vertical-center">Valor total</th>
                  </tr>
                </thead>
                <tbody>
                  {!cargandoTabla ? (
                    datosPago && datosPago.length > 0 ? (
                      datosPago.map(v => (
                        <tr>
                          <td className="text-center">{v.WPLU_NPERIODO}</td>
                          <td className="text-center"> {v.AFIL_NRUT}</td>
                          <td className="text-center">
                            {v.WPLU_NOMBRESAFILIADO}
                          </td>
                          <td className="text-center">{moment(v.WPLU_FEC_EVENTO).format("DD/MM/YYYY HH:mm:ss")}</td>

                          <td className="text-center">{v.WPLU_NCUOTA}</td>
                          <td className="text-center">
                            $ {formatearNumero(v.WPLU_MTOTAL)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="nowrap text-center" colSpan="6">
                          No hay datos.
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td className="text-center" colSpan="6">
                        Cargando...
                        <Spinner
                          className="nowrap m-l"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="row justify-content-center m-t m-l-0">
              <p>
                Nota: Comprobante sujeto a validación de entidad que presta el
                servicio.
              </p>
            </div>
            <div className="row justify-content-center m-t-lg m-l-0">
              <div className="col-sm-5 offset-7 m-t">
                <Button
                  size="sm"
                  onClick={this.handleDescargarComprobante}
                  disabled={descargando || !datosPago || datosPago.length === 0}
                  variant={
                    this.props.datosSession &&
                    this.props.datosSession.frontOrigin === "B"
                      ? "danger"
                      : "success"
                  }
                >
                  Guardar comprobante{" "}
                  {descargando && (
                    <Spinner
                      className="m-l"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </div>{" "}
            </div>

            <div className="row justify-content-center m-t-lg-2 m-l">
                <Button
                  size="sm"
                  className="m-l"
                  variant={
                    this.props.datosSession &&
                    this.props.datosSession.frontOrigin === "B"
                      ? "danger"
                      : "success"
                  }
                  onClick={this.redirect}
                >
                  Volver
                </Button>
              </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  cargandoTabla: state.pagoExitoso.cargandoTabla,
  redirect: state.pagoExitoso.redirect,
  descargando: state.pagoExitoso.descargando,
  exportandoExcel: state.pagoExitoso.exportandoExcel,
  datosSession: state.main.datosSession,
  datosPago: state.pagoExitoso.datosPago
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CotizacionesPagoExitoso));
