import React, { Component } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import {
  generarCertificadoDeuda,
  generarAclaracion,
  descargarCotizaciones
} from "./actions";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { InputGroup } from "react-bootstrap/";
import Col from "react-bootstrap/Col";
import { formatRut, rutValidate, rutClean } from "../../../helpers/rut";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import es from "date-fns/locale/es"; // the locale you want
import { subYears } from "date-fns";
import Error from "../../error/view";
registerLocale("es", es); // register it with the name you want

class DescargaCertificados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 0,
      checkDNP: false,
      checkNODNP: false,
      email: "",
      error: { email: null },
      error2: { rut: null },
      rut: "",
      startDate: new Date(),
      startDate2: new Date(),
      tipoDeuda: '',
    };
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChange2 = this.onChange2.bind(this);
  }

  componentDidMount() { }

  componentWillReceiveProps(newProps) {
    if (
      newProps.mensajeOkAclaracion &&
      this.props.mensajeOkAclaracion !== newProps.mensajeOkAclaracion
    ) {
      const error = { email: null };
      this.setState({ email: "", error });
    }
  }

  onChange(date) {
    this.setState({
      startDate: date
    });
  }

  handleDateChangeRaw = e => {
    e.preventDefault();
  };

  onChange2(date) {
    this.setState({
      startDate2: date
    });
  }

  validateField = () => {
    const error = { email: null };
    const { email } = this.state;

    if (email === "") error.email = "Campo obligatorio";
    else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      error.email = "Mail con formato inválido";
    }
    this.setState({ error: error });

    if (error.email) {
      return false;
    }
    return true;
  };

  handleOnChange(event) {
    const error = { email: null };
    const { id, value } = event.target;
    if (id !== "email" && id !== "rut" && value.length < 50)
      this.setState({ [id]: value });
    if (id === "email") {
      if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        error.email = "Mail con formato inválido";
      }

      this.setState({ error: error, [id]: value });
    }

    if (id === "rut" && value.length < 13)
      this.setState({ [id]: formatRut(rutClean(value)) });
  }

  handleCheck = event => {
    const { id, checked } = event.target;
    if (id === "checkDNP" && checked)
      this.setState({ checkDNP: true, tipoDeuda: 'DNP', checkNODNP: false });
    else if (id === "checkNODNP" && checked)
      this.setState({ checkDNP: false, tipoDeuda: 'NDNP', checkNODNP: true });
    else this.setState({ [id]: checked });
  };

  handleOnClick = event => {
    const { id } = event.target;
    if (id === "generarDeuda")
      this.props.dispatch(generarCertificadoDeuda(this.state));
    if (id === "generarAclaracion") {
      if (this.validateField())
        this.props.dispatch(generarAclaracion(this.state));
    }
    if (id === "generarCotizaciones") {
      if (!this.state.rut)
        this.setState({ error2: { rut: "Campo obligatorio" } });
      else if (rutValidate(this.state.rut)) {
        this.props.dispatch(descargarCotizaciones(this.state));
        this.setState({ error2: { rut: null } });
      } else {
        this.setState({ error2: { rut: "Rut Inválido" } });
      }
    }
  };

  render() {
    const {
      generandoDeuda,
      generandoAclaracion,
      mensajeOkAclaracion,
      descargaCotizaciones,
      descargaError,
      internalerror
    } = this.props;

    if (internalerror.internalerror){
      return <Error msg={internalerror.msg}/> ;
    } else {
      return (
          <React.Fragment>
            <div className="row justify-content-left m-l">
              <h2>Certificados</h2>
            </div>
            <div className="card m-t">
              <div className="card-header">Certificado de deuda</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-8">
                    <Form className="m-l">
                      <Form.Check
                          checked={this.state.checkDNP}
                          onChange={this.handleCheck}
                          custom
                          id="checkDNP"
                          inline
                          label="DNP (Cotizaciones declaradas y no pagadas)"
                          type="checkbox"
                      />
                      <Form.Check
                          checked={this.state.checkNODNP}
                          onChange={this.handleCheck}
                          custom
                          id="checkNODNP"
                          inline
                          label="NDNP (Cotizaciones no declaradas y no pagadas)"
                          type="checkbox"
                      />
                    </Form>
                  </div>
                  <div className="col-sm-4 align-self-center">
                    <Button
                        size="sm"
                        id="generarDeuda"
                        disabled={
                          generandoDeuda ||
                          (!this.state.checkDNP && !this.state.checkNODNP)
                        }
                        onClick={this.handleOnClick}
                        variant={
                          this.props.datosSession &&
                          this.props.datosSession.frontOrigin === "B"
                              ? "danger"
                              : "success"
                        }
                    >
                      Generar
                      {generandoDeuda && (
                          <Spinner
                              className="m-l"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                          />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card m-t">
              <div className="card-header">
                Certificado Aclaración Boletín Laboral
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-8">
                    <Form className="m-l">
                      <Form.Group>
                        <Form.Label>
                          Favor ingresar mail al que desea recibir el certificado.
                        </Form.Label>
                        <InputGroup className="input-addon" size="sm">
                          <Form.Control
                              size="sm"
                              required
                              id="email"
                              type="email"
                              placeholder="correo@dominio.cl"
                              value={this.state.email}
                              onChange={this.handleOnChange}
                          />
                          <Form.Control.Feedback
                              className="displayError "
                              type="invalid"
                          >
                            {this.state.error.email}
                          </Form.Control.Feedback>

                        </InputGroup>
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="col-sm-4 align-self-center">
                    <Button
                        size="sm"
                        disabled={
                          this.state.email === "" ||
                          this.state.error.email ||
                          generandoAclaracion
                        }
                        id="generarAclaracion"
                        onClick={this.handleOnClick}
                        variant={
                          this.props.datosSession &&
                          this.props.datosSession.frontOrigin === "B"
                              ? "danger"
                              : "success"
                        }
                    >
                      Solicitar
                      {generandoAclaracion && (
                          <Spinner
                              className="m-l"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                          />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="card m-t">
              <div className="card-header">
                Certificado de Cotizaciones de Salud Pagadas
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-8">
                    <Form>
                      <Form.Group>
                        <Form.Label>RUT afiliado *</Form.Label>
                        <InputGroup className="input-addon" size="sm">
                          <Form.Control
                              size="sm"
                              id="rut"
                              onChange={this.handleOnChange}
                              value={this.state.rut}
                          />
                          <Form.Control.Feedback
                              className="displayError"
                              type="invalid"
                          >
                            {this.state.error2.rut}
                          </Form.Control.Feedback>
                          {!this.state.error2.rut && (
                              <Form.Control.Feedback
                                  className="displayError"
                                  type="invalid"
                              >
                                {descargaError}
                              </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Form.Group>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Fecha desde</Form.Label>
                          <DatePicker
                              size="sm"
                              className="inputCalendar"
                              id="fechaDesde"
                              selected={this.state.startDate}
                              onChange={this.onChange}
                              dateFormat="dd/MM/yyyy"
                              strictParsing
                              onChangeRaw={this.handleDateChangeRaw}
                              locale="es"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={new Date()}
                              minDate={subYears(new Date(), 10)}
                          />{" "}
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Fecha hasta</Form.Label>
                          <DatePicker
                              size="sm"
                              className="inputCalendar"
                              id="fechaHasta"
                              selected={this.state.startDate2}
                              onChange={this.onChange2}
                              dateFormat="dd/MM/yyyy"
                              strictParsing
                              onChangeRaw={this.handleDateChangeRaw}
                              locale="es"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={new Date()}
                              minDate={this.state.startDate}
                          />{" "}
                        </Form.Group>
                      </Form.Row>
                    </Form>
                  </div>
                  <div className="col-sm-4 align-self-center">
                    <Button
                        size="sm"
                        id="generarCotizaciones"
                        onClick={this.handleOnClick}
                        variant={
                          this.props.datosSession &&
                          this.props.datosSession.frontOrigin === "B"
                              ? "danger"
                              : "success"
                        }
                    >
                      Generar
                      {descargaCotizaciones && (
                          <Spinner
                              className="m-l"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                          />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({
  detalleDeuda: state.detalleDeuda.detalleDeuda,
  cargandoTabla: state.detalleDeuda.cargandoTabla,
  generandoDeuda: state.descargaCertificados.generandoDeuda,
  mensajeOkAclaracion: state.descargaCertificados.mensajeOkAclaracion,
  generandoAclaracion: state.descargaCertificados.generandoAclaracion,
  descargaCotizaciones: state.descargaCertificados.descargaCotizaciones,
  datosSession: state.main.datosSession,
  descargaError: state.descargaCertificados.descargaError,
  internalerror: state.pagoCotizaciones.internalerror
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DescargaCertificados);
