import {
  CALCULO_INTERESES_CALCULANDO,
  CALCULO_INTERESES_IMPRIMIENDO,
  CALCULO_INTERESES_GUARDAR,
  CALCULO_INTERESES_MENSAJE
} from "../../../config/types";
import axios from "axios";
import uriConfig from "../../../config/api";
import store from "../../../store";

export const calculando = value => async dispatch => {
  dispatch({
    type: CALCULO_INTERESES_CALCULANDO,
    payload: value
  });
};

export const imprimiendo = value => async dispatch => {
  dispatch({
    type: CALCULO_INTERESES_IMPRIMIENDO,
    payload: value
  });
};
export const mensaje = value => async dispatch => {
  dispatch({
    type: CALCULO_INTERESES_MENSAJE,
    payload: value
  });
};

export const limpiarIntereses = params => async dispatch => {
  const { main } = store.getState();
  dispatch(mensaje(""));
  dispatch(calculando(false));
  dispatch({
    type: CALCULO_INTERESES_GUARDAR,
    payload: {}
  });
};

export const calcularIntereses = params => async dispatch => {
  const { main } = store.getState();
  dispatch(mensaje(""));
  dispatch(calculando(true));
  let d = new Date(params.fechaPago).toISOString().split('T');
  let fechaPago = new Date(params.fechaPago).toISOString().split('T');
  d = d[0].split('-');

  axios
    .post(`${uriConfig.apiCotizaciones.postCalcularIntereses}`, {
      IMONTO: params.monto,
      IADICIONAL: 0,
      IISAPRE: main.datosSession.frontOrigin,
      IFECHA: d[2].toString(),
      IFECHAPAGO: fechaPago[0],
      periodo: parseFloat(params.ano.toString() + params.mes.toString())
    })
    .then(res => {
      if (res.status === 200) {
        dispatch(calculando(false));
        dispatch(mensaje(""));
        dispatch({
          type: CALCULO_INTERESES_GUARDAR,
          payload: res.data.rows
        });
      } else {
        dispatch(calculando(false));
        dispatch(mensaje("No hay datos en el período seleccionado"));
      }
    })
    .catch(error => {
      dispatch(calculando(false));
      dispatch(mensaje("No hay datos en el período seleccionado"));
    });
};

export const pdfSimulacion = params => async dispatch => {
  const { main } = store.getState();
  dispatch(imprimiendo(true));
  let d = new Date(params.fechaPago).toISOString().split('T');
  let fechaPago = new Date(params.fechaPago).toISOString().split('T');
  d = d[0].split('-');
  axios
    .post(
      `${uriConfig.apiCotizaciones.postSimulacionIntereses}`,
      {
        IMONTO: params.montoFinal,
        IADICIONAL: 0,
        IISAPRE: main.datosSession.frontOrigin,
        IFECHA: d[2],
        IFECHAPAGO: fechaPago[0],
        periodo: params.ano.toString() + params.mes.toString()
      },
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf"
        }
      }
    )
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Simulacion${main.datosSession.rutEmpresa}_${d[2] + '_' + d[1] + '_' + d[0]}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      dispatch(imprimiendo(false));
    })
    .catch(error => {
      dispatch(imprimiendo(false));
    });
};
