import {
  GET_DETALLE_DEUDA,
  DETALLE_DEUDA_CARGANDO_TABLA,
  DETALLE_DEUDA_EXPORTANDO_EXCEL,
  PAGO_REDIRECT,
  PAGO_COTIZACIONES_PAGANDO,
  GET_CALCULO_SIETE,
  SET_CALCULO_FINAL_SUMA,
  SET_CALCULO_FINAL_RESTA,
  INTERNAL_ERROR
} from "../../../config/types";
import pagoCotizacionState from "./state";

export default function (state = pagoCotizacionState, action) {
  switch (action.type) {
    case GET_DETALLE_DEUDA:
      return Object.assign({}, state, {
        detalleDeuda: action.payload
      });
    case SET_CALCULO_FINAL_SUMA:
      return Object.assign({}, state, {
        totalFinal: parseInt(state.totalFinal) + parseInt(action.payload)
      });
    case SET_CALCULO_FINAL_RESTA:
      return Object.assign({}, state, {
        totalFinal: parseInt(state.totalFinal) - parseInt(action.payload)
      });
    case GET_CALCULO_SIETE:
      // action.payload.sieValue.GASTOS = 140000;
      // action.payload.sieValue.INTERESES = 98000;
      // action.payload.sieValue.REAJRECAR = 45000;
      // action.payload.sieValue.TOTAL = 10000;
      // action.payload.sieValue.SIETEPORCIENTO = 2100;
      return Object.assign({}, state, {
        calculoSiete: action.payload
      });
    case DETALLE_DEUDA_CARGANDO_TABLA:
      return Object.assign({}, state, {
        cargandoTabla: action.payload
      });
    case PAGO_COTIZACIONES_PAGANDO:
      return Object.assign({}, state, {
        pagando: action.payload
      });
    case DETALLE_DEUDA_EXPORTANDO_EXCEL:
      return Object.assign({}, state, {
        exportandoExcel: action.payload
      });

    case PAGO_REDIRECT:
      return Object.assign({}, state, {
        redirect: action.payload
      });
    case INTERNAL_ERROR:
      return Object.assign({}, state, {
        internalerror: action.payload
      });
    default:
      return state;
  }
}
