import React, { Component } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { getDetalleDeuda, realizarPago, realizarPagoMDPG } from "./actions";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { InputGroup } from "react-bootstrap/";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import { formatearNumero } from "../../../helpers/util";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import CuadroPeriodo from "./components/cuadroPeriodo";
import uriConfig from "../../../config/api";
import Error from "../../error/view";


class PagoCotizaciones extends Component {
  constructor(props) {
    super(props);
    let arraySeleccionados = [];
    arraySeleccionados.dnp = [];
    arraySeleccionados.nodnp = [];
    this.state = {
      arraySeleccionados: arraySeleccionados,
      //arraySeleccionados: [],
      correo: "",
      totalPagar: 0,
      error: { correo: null },
    };
    this.updateArrayOnChange = this.updateArrayOnChange.bind(this);
    this.handlePagar = this.handlePagar.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getDetalleDeuda({ tipoDuda: 'ALL' }));
  }

  componentWillReceiveProps(newProps) {
    const { frontOrigin } = this.props.datosSession;

    if (newProps.redirect && newProps.redirect !== this.props.redirect){
      console.log("this.props.redirect ", this.props.redirect);
      console.log("newProps  ", newProps.redirect.url);

      window.top.location = newProps.redirect.url;
    }
     
  }

  mapForInserting(w, periodo, origen) {
    const { frontOrigin, rutEmpresa, rutUsuario } = this.props.datosSession;
    return {
      pr_empl_nrut: rutEmpresa || "",
      pr_wuem_nrut: rutUsuario || "",
      pr_afil_nrut: w.RUT_AFILIADO || "",
      pr_isap_cempresa: frontOrigin || "",
      pr_plan_mperiodo: parseInt(periodo) || 0,
      pr_wpce_monto:w.TOTAL_PAGAR,
      pr_wpce_tipo: origen,
      pr_plan_nmcpac: w.MONTO_PACTADO || 0,
      pr_plan_mrimp: w.IMPONIBLE || 0,
      pr_plan_mlegal: w.COTIZA7 || 0,
      pr_wpcd_madicional: origen === 'DNP'? w.VOLUNTARIO : w.ADICIONAL,
      pr_plan_mreaj: w.REAJUSTE || 0,
      pr_plan_mintereses: w.INTERES || 0,
      pr_plan_mint_rec: w.RECINTERES,
      pr_wpcd_mgastos: w.GASTOS_COB || 0,
      pr_wpcd_mtotal: w.TOTAL_PAGAR
    };
  }

  handlePagar() {
    const { arraySeleccionados, totalPagar,correo } = this.state;
    //GCF Seiza-SpA 2019-01
    let dnpArray = null;
    if (arraySeleccionados.dnp.length > 0) {
      dnpArray = arraySeleccionados.dnp.map(w => {
        return this.mapForInserting(w, w.PERIODO, "DNP");
      });
    }
    let nodnpArray= null;
    if (arraySeleccionados.nodnp.length > 0) {
      nodnpArray = arraySeleccionados.nodnp.map(w => {
        // IMPONIBLE = 0 YA QUE FUE UN NODNP PARCIAL Y LLEVA IMPONIBLE, SOLO ADICIONAL
        if (w.MONTO_DEUDA !== w.MONTO_PACTADO){
          w.IMPONIBLE = 0;
        }
        return this.mapForInserting(w, w.PERIODO, "NDNP");
      });
    }
    const { token } = this.props;
    this.props.dispatch(
      realizarPagoMDPG({ dnpArray, nodnpArray, totalPagar, token,correo })
    );
  }

  updateArrayOnChange(data, agregar, total) {
    console.log("aqui paso por update Array on change", this.state);
    //GCF - Seiza 2020-01
    const { arraySeleccionados } = this.state;
    if(agregar){
      //Agregar deuda a pagar
      if(data.ORIGEN === "DNP"){
        arraySeleccionados.dnp.push(data);
      }else{
        arraySeleccionados.nodnp.push(data);
      }
    }else{
      //Borrar deuda a pagar
      if(data.ORIGEN === "DNP"){
        var i = arraySeleccionados.dnp.indexOf(data);
        arraySeleccionados.dnp.splice( i, 1 )
      }else{
        var i = arraySeleccionados.nodnp.indexOf(data);
        arraySeleccionados.nodnp.splice( i, 1 )
      }
    }
  }

  handleOnChange(event) {
    const error = { correo: null };
    const { id, value } = event.target;
    if (id !== "correo" && id !== "rut" && value.length < 50)
      this.setState({ [id]: value });
    if (id === "correo") {
      if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        error.correo = "Mail con formato inválido";
      }

      this.setState({ error: error, [id]: value });
    }
  }

  render() {
    const { detalleDeuda, cargandoTabla, pagando, internalerror } = this.props;
    if (internalerror.internalerror){
      return <Error msg={internalerror.msg}/> ;
    } else {
      return (
          <React.Fragment>
            <div className="justify-content-left h2">
              <p>Pago cotizaciones</p>
            </div>
            <div className="mainDetallePago">
              <div className="row m-r-0 paddingColor">
                <div className="col-6 deudaPagoTitulos">
                  Periodo de remuneración
                </div>
                <div className="col-4 deudaPagoTitulos">Tipo Deuda</div>
                <div className="col-2 deudaPagoTitulos">Detalle</div>
              </div>
            </div>
            {cargandoTabla && (
                <div className="mainDetallePago">
                  <div className="row m-r-0">
                    <div className="col-12 deudaPagoPeriodoTitulos">
                      Cargando...
                      <Spinner
                          className="nowrap m-l"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
            )}
            {!cargandoTabla && detalleDeuda && !detalleDeuda.length && (
                <div className="mainDetallePago">
                  <div className="row m-r-0">
                    <div className="col-12 deudaPagoPeriodoTitulos">
                      No hay datos.
                    </div>
                  </div>
                </div>
            )}
            <div className="addScroll">
              {detalleDeuda &&
              detalleDeuda.length > 0 &&
              detalleDeuda.map((v, i) => {
                let temp = [];
                if (v.dnp && v.dnp.length > 0)
                  temp.push(
                      <CuadroPeriodo
                          key={`${v.periodo}-dnp`}
                          id={`${v.periodo}-dnp-${i}`}
                          update={this.updateArrayOnChange}
                          deuda={v.dnp}
                          origen="DNP"
                          datosSession={this.props.datosSession}
                          periodo={v.periodo}
                          data={v.dnp}
                      />
                  );
                if (v.nodnp && v.nodnp.length > 0)
                  temp.push(
                      <CuadroPeriodo
                          id={`${v.periodo}-nodnp-${i}`}
                          key={`${v.periodo}-nodnp`}
                          update={this.updateArrayOnChange}
                          deuda={v.nodnp}
                          origen="NDNP"
                          periodo={v.periodo}
                          data={v.nodnp}
                          datosSession={this.props.datosSession}
                      />
                  );
                return temp;
              })}
            </div>
            {!cargandoTabla && detalleDeuda && detalleDeuda.length > 0 && (
                <React.Fragment>
                  <div className="mainDetallePago">
                    <div className="row m-r-0">
                      <div className="col-8 deudaPagoTitulos">TOTAL A PAGAR</div>
                      <div className="col-4 deudaPagoTitulosTotal">
                        {/* $ {formatearNumero(this.state.totalPagar) || 0} */}
                        $ {formatearNumero(this.props.totalFinal) || 0}
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-right m-l">
                    <div className="col-sm-12">
                      Nota:
                      <br/>
                      Si es una deuda DNP el sistema automáticamente ingresará el
                      valor de renta imponible y cotización adicional informado.{" "}
                      <br/>
                      Si es una deuda presunta, el empleador debe ingresar renta
                      imponible y la cotización adicional para cancelar la deuda{" "}
                      <br/>
                    </div>
                    <div className="col-sm-6 offset-6 m-t">
                      <InputGroup className="input-addon" size="sm">
                        <Form.Control
                            size="sm"
                            required
                            id="correo"
                            type="email"
                            placeholder="correo@dominio.cl"
                            value={this.state.correo}
                            onChange={this.handleOnChange}
                        />
                        <Form.Control.Feedback
                              className="displayError "
                              type="invalid"
                          >
                            {this.state.error.correo}
                          </Form.Control.Feedback>
                      </InputGroup>
                    </div>
                    <div className="col-sm-4 offset-8 m-t">
                      
                      <br />
                      <Button
                          size="sm"
                          // disabled={pagando || this.state.totalPagar === 0}
                          disabled={pagando || this.props.totalFinal === 0 || this.state.correo == "" || this.state.error.correo}
                          variant={
                            this.props.datosSession &&
                            this.props.datosSession.frontOrigin === "B"
                                ? "danger"
                                : "success"
                          }
                          onClick={this.handlePagar}
                      >
                        Pagar seleccionados{" "}
                        {pagando && (
                            <Spinner
                                className="m-l"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
            )}
          </React.Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({
  detalleDeuda: state.pagoCotizaciones.detalleDeuda,
  cargandoTabla: state.pagoCotizaciones.cargandoTabla,
  redirect: state.pagoCotizaciones.redirect,
  exportandoExcel: state.pagoCotizaciones.exportandoExcel,
  datosSession: state.main.datosSession,
  pagando: state.pagoCotizaciones.pagando,
  calculoSiete: state.pagoCotizaciones.calculoSiete,
  totalFinal: state.pagoCotizaciones.totalFinal,
  internalerror: state.pagoCotizaciones.internalerror,
  email: state.email,
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PagoCotizaciones);
