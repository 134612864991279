import {
  LEYURGENCIA_CARGANDO_TABLA,
  DETALLE_DEUDA_EXPORTANDO_EXCEL,
  PAGO_REDIRECT,
  PAGO_COTIZACIONES_PAGANDO,
  GET_LEY_URGENCIA
} from "../../../config/types";
import pagoLeyUrgenciaState from "./state";

export default function(state = pagoLeyUrgenciaState, action) {
  switch (action.type) {
    case GET_LEY_URGENCIA:
      return Object.assign({}, state, {
        datosLeyUrgencia: action.payload
      });
    case LEYURGENCIA_CARGANDO_TABLA:
      return Object.assign({}, state, {
        cargandoTabla: action.payload
      });
    case PAGO_COTIZACIONES_PAGANDO:
      return Object.assign({}, state, {
        pagando: action.payload
      });
    case DETALLE_DEUDA_EXPORTANDO_EXCEL:
      return Object.assign({}, state, {
        exportandoExcel: action.payload
      });

    case PAGO_REDIRECT:
      return Object.assign({}, state, {
        redirect: action.payload
      });
    default:
      return state;
  }
}
