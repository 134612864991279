import { GET_DATA_TOKEN } from '../../config/types'
import mainState from './state'

export default function (state = mainState, action) {
    switch(action.type){
        case GET_DATA_TOKEN:
            return Object.assign({}, state, {
                datosSession: action.payload,       
            });              
        default:
            return state
    }
}