import {
  CERTIFICADOS_GENERANDO_DEUDA,
  CERTIFICADOS_GENERANDO_ACLARACION,
  CERTIFICADOS_GENERANDO_ACLARACION_MENSAJE_OK,
  CERTIFICADOS_DESCARGA_COTIZACIONES,
  CERTIFICADOS_DESCARGA_COTIZACIONES_ERROR, INTERNAL_ERROR
} from "../../../config/types";
import DescargaCertificadosState from "./state";

export default function(state = DescargaCertificadosState, action) {
  switch (action.type) {
    case CERTIFICADOS_GENERANDO_DEUDA:
      return Object.assign({}, state, {
        generandoDeuda: action.payload
      });
    case CERTIFICADOS_GENERANDO_ACLARACION:
      return Object.assign({}, state, {
        generandoAclaracion: action.payload
      });
    case CERTIFICADOS_GENERANDO_ACLARACION_MENSAJE_OK:
      return Object.assign({}, state, {
        mensajeOkAclaracion: action.payload
      });
    case CERTIFICADOS_DESCARGA_COTIZACIONES:
      return Object.assign({}, state, {
        descargaCotizaciones: action.payload
      });
    case CERTIFICADOS_DESCARGA_COTIZACIONES_ERROR:
      return Object.assign({}, state, {
        descargaError: action.payload
      });
    case INTERNAL_ERROR:
      return Object.assign({}, state, {
        internalerror: action.payload,
      });
    default:
      return state;
  }
}
