import {
  GET_HISTORIAL_PAGO,
  HISTORIAL_PAGO_CARGANDO_TABLA,
  GET_DETALLE_PAGO
} from "../../../config/types";
import axios from "axios";
import data from "../../../helpers/data";
import uriConfig from "../../../config/api";
import store from "../../../store";
import {internalerror} from "../../PagoExitoFracaso/actions";
import {toastr} from "react-redux-toastr";

export const cargandoDatos = value => async dispatch => {
  dispatch({
    type: HISTORIAL_PAGO_CARGANDO_TABLA,
    payload: value
  });
};

export const getHistorialPago = params => async dispatch => {
  dispatch(cargandoDatos(true));
  axios
    .post(`${uriConfig.apiCotizaciones.getHistorialPago}`, {
      PR_EMPL_NRUT: params.rutEmpresa,
      PR_ISAP_CEMPRESA: params.frontOrigin
    })
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: GET_HISTORIAL_PAGO,
          payload: [res.data.rows]
        });
        dispatch(cargandoDatos(false));
      }
      dispatch(cargandoDatos(false));
    })
    .catch(error => {
      dispatch({
        type: GET_HISTORIAL_PAGO,
        payload: data
      });
      dispatch(cargandoDatos(false));
    });
};

export const descargarPlanilla = params => async dispatch => {
  dispatch(cargandoDatos(true));
  axios
    .get(`${uriConfig.apiCotizaciones.getObtenerPlanillasPago}/${params.PPLAN_NFOLIO_PAGO}`)
    .then(res => {
      const link = document.createElement("a");
      link.href = 'data:application/octet-stream;base64,' + res.data.planillas;
      link.download = 'Comprobante_Bancobro.pdf';
      document.body.appendChild(link);
      link.click();
      dispatch(cargandoDatos(false));
    })
    .catch(error => {
      dispatch(cargandoDatos(false));
      toastr.error("Respuesta","Error, favor intentar nuevamente")
    });
};
