import { createStore, applyMiddleware, compose } from 'redux';
import detalleDeudaState from "./components/cotizaciones/DetalleDeuda/state";
import pagoCotizacionesState from "./components/cotizaciones/Pago/state";
import datosEmpleadorState from "./components/empleador/DatosEmpleador/state";
import datosContactoState from "./components/empleador/DatosContacto/state";
import datosDeudaEmpresa from "./components/deudaEmpresa/state";
import mainState from "./components/Main/state";
import descargaCertificadosState from "./components/certificados/DescargaCertificados/state";
import calculoInteresesState from "./components/cotizaciones/CalculoIntereses/state";
import movimientoPersonalState from "./components/empleador/MovimientoPersonal/state";
import pagoExitosoState from "./components/PagoExitoFracaso/state";
import pagoLeyUrgenciaState from "./components/cotizaciones/PagoLeyUrgencia/state";
import errorState from "./components/error/state";

import thunk from  'redux-thunk';

import rootReducer from './reducers';

const rootState = {
    detalleDeuda: detalleDeudaState,
    main: mainState,
    datosEmpleador: datosEmpleadorState,
    descargaCertificados: descargaCertificadosState,
    datosContacto:datosContactoState,
    calculoIntereses: calculoInteresesState,
    pagoCotizaciones: pagoCotizacionesState,
    movimientoPersonal: movimientoPersonalState,
    pagoExitoso: pagoExitosoState,
    pagoLeyUrgencia: pagoLeyUrgenciaState,
    datosdeudaEmpresa: datosDeudaEmpresa,
    error: errorState
};

//add localStorage

//const localStorageState = localStorage.getItem('dates') ? JSON.parse(localStorage.getItem('dates')) : [];
var getComposeEnhancers = () => {
      return compose(
        applyMiddleware(thunk)
        ,window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (a) => a
      );
  };


const store = createStore(rootReducer, rootState, getComposeEnhancers());

export default store;
