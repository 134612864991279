const pagoLeyUrgenciaState = {
    detalleDeuda: [],
    messagesuccess: false,
    cargandoTabla: false,
    exportandoExcel: false,
    generandoAclaracion: false,
    redirect: false,
    pagando: false,
    datosLeyUrgencia: []
}

export default pagoLeyUrgenciaState;
