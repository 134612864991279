import React, { Component } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { calcularIntereses, pdfSimulacion, limpiarIntereses } from "./actions";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card, InputGroup } from "react-bootstrap/";
import "react-datepicker/dist/react-datepicker.css";
import { formatearNumero, limpiarNumero } from "../../../helpers/util";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es); // register it with the name you want

class CalculoIntereses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 0,
      error: { monto: null },
      fechaPago: new Date(),
      mes:
        new Date().getMonth() < 10
          ? "0" + (new Date().getMonth() + 1).toString()
          : (new Date().getMonth() + 1).toString(),
      ano: new Date().getFullYear(),
      monto: "",
      montoFinal: "",
      calculado: false,
      limpiar: false,
      meses: [],
      anos: []
    };
    this.handleCalcular = this.handleCalcular.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleImprimir = this.handleImprimir.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const anos = this.generarAnos();
    const meses = this.generarMeses(parseInt(this.state.ano));

    this.setState({
      mes:
        new Date().getMonth() < 10
          ? "0" + (new Date().getMonth()).toString()
          : (new Date().getMonth() + 1).toString(),
      anos,
      meses
    });

    console.log(this.state)
  }

  onChange(date) {
    this.setState({
      fechaPago: date
    });
  }
  handleDateChangeRaw = e => {
    e.preventDefault();
  };

  validateField = () => {
    const error = { monto: null };
    const { monto } = this.state;

    if (monto === "") error.monto = "Debe ingresar un monto válido.";

    this.setState({ error: error });
    if (error.monto) {
      return false;
    }
    return true;
  };

  generarAnos = () => {
    let i = 2000;
    let temp = [];
    for (i; i <= new Date().getFullYear(); i++) temp.push(i);
    return temp;
  };

  generarMeses = ano => {
    console.log(ano);
    let i = 1;
    let temp = [];
    const maxAno =
      ano === new Date().getFullYear() ? new Date().getMonth() : 12;
    for (i; i <= maxAno; i++)
      temp.push(i < 10 ? "0" + i.toString() : i.toString());
    return temp;
  };

  generarAllMeses() {
    let meses = [];
    for (let i = 1; i <= 12; i++) {
      meses.push(i < 10 ? "0" + i.toString() : i.toString())
    }
    return meses;
  }

  handleOnChange(event) {
    const error = { email: null };
    const { id, value } = event.target;
    const re = /^[0-9\b]+$/;

    if (id === "ano") {
      this.setState({ mes: "01", meses: this.generarAllMeses() })
      console.log(this.state.meses)

    }

    if (id !== "fechaPago" && id !== "monto" && value.length < 50)
      this.setState({ [id]: value });

    if (id === "monto" && value.length < 15) {
      if (re.test(limpiarNumero(value)))
        this.setState({
          error: error,
          [id]: value === "" ? "" : limpiarNumero(value)
        });
    }
  }

  handleCalcular = event => {
    if (this.validateField()) {
      this.setState({ montoFinal: this.state.monto });
      this.props.dispatch(calcularIntereses(this.state));
    }
  };

  handleLimpiar = () => {
    this.props.dispatch(limpiarIntereses());
    this.setState({
      fechaPago: new Date(),
      mes:
        new Date().getMonth() < 10
          ? "0" + (new Date().getMonth()).toString()
          : (new Date().getMonth() + 1).toString(),
      ano: new Date().getFullYear(),
      monto: "",
      montoFinal: "",
      calculado: false
    });
  };

  handleImprimir = event => {
    this.props.dispatch(pdfSimulacion(this.state));
  };

  render() {
    const { calculando, dataIntereses, imprimiendo, mensaje } = this.props;

    return (
      <React.Fragment>
        <div className="row m-l m-t">
          <div className="col-sm-12">
            <h2>Cálculo de intereses</h2>
            <div className="txt-bajada">
              Favor completar la siguiente información para realizar el cálculo
              de sus intereses
            </div>
          </div>
        </div>

        <div className="row m-l m-t">
          <div className="col-sm-12">
            <Card>
              <Card.Header>Información para realizar el cálculo</Card.Header>
              <Card.Body>
                <div className="row">
                  <div className="col-sm-8">
                    <Form>
                      <div className="row">
                        <div className="col-sm-12">
                          <Form.Group>
                            <Form.Label>Monto *</Form.Label>
                            <InputGroup className="input-addon" size="sm">
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                size="sm"
                                id="monto"
                                autoComplete="off"
                                onChange={this.handleOnChange}
                                value={
                                  this.state.monto !== ""
                                    ? formatearNumero(this.state.monto)
                                    : ""
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="displayError"
                              >
                                {this.state.error.monto}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-7">
                          <Form.Group>
                            <Form.Label>Periodo *</Form.Label>
                            <div className="row">
                              <div className="col-sm-4">
                                <Form.Control
                                  size="sm"
                                  id="mes"
                                  as="select"
                                  className="m-r select-especial"
                                  onChange={this.handleOnChange}
                                  value={this.state.mes}
                                >
                                  {this.state.meses.map(value => {
                                    return <option value={value} key={value}>{value}</option>;
                                  })}
                                </Form.Control>
                              </div>
                              <div className="col-sm-8">
                                <Form.Control
                                  size="sm"
                                  id="ano"
                                  as="select"
                                  className="select-especial"
                                  onChange={this.handleOnChange}
                                  value={this.state.ano}
                                >
                                  {this.state.anos.map((value, index) => {
                                    return <option key={value}>{value}</option>;
                                  })}
                                </Form.Control>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-sm-5">
                          <Form.Group>
                            <Form.Label>Fecha de pago *</Form.Label>
                            <InputGroup size="sm">
                              <DatePicker
                                locale="es"
                                size="sm"
                                className="inputCalendar"
                                id="fechaPago"
                                strictParsing
                                onChangeRaw={this.handleDateChangeRaw}
                                selected={this.state.fechaPago}
                                onChange={this.onChange}
                                dateFormat="dd/MM/yyyy"
                                minDate={new Date()}
                                maxDate={
                                  new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth() + 1,
                                    0
                                  )
                                }
                              />
                              <Form.Control.Feedback type="info">
                                Las fechas ingresadas son solo para el mes en
                                curso.
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <div className="col-sm-4">
                    <div className="alinear-boton">
                      <Button
                        size="sm"
                        id="calcularInteres"
                        onClick={this.handleCalcular}
                        variant={
                          this.props.datosSession &&
                            this.props.datosSession.frontOrigin === "B"
                            ? "danger"
                            : "success"
                        }
                        disabled={calculando || imprimiendo}
                      >
                        Calcular
                        {calculando && (
                          <Spinner
                            className="nowrap m-l"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>

        {mensaje === "" &&
          dataIntereses &&
          Object.keys(dataIntereses).length > 0 && (
            <div className="row m-l m-t">
              <div className="col-sm-12">
                <React.Fragment>
                  <Card>
                    <Card.Header>Resultados</Card.Header>
                    <Card.Body>
                      <div className="row justify-content-center m-t m-r">
                        <div className="col-sm-12">
                          <Table
                            striped
                            className="tableCalculoIntereses no-padding"
                          >
                            <tbody>
                              <tr>
                                <td className="titulosTabla-calculoInteres align-middle titulos-horizontal-fix-1 calculoInteres-big">
                                  Monto
                                </td>
                                <td className="text-right valores-calculoInteres">
                                  ${" "}
                                  {!calculando ? (
                                    this.state.monto === 0 ? (
                                      0
                                    ) : (
                                        formatearNumero(this.state.monto)
                                      )
                                  ) : (
                                      <Spinner
                                        className="nowrap m-l"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className="titulosTabla-calculoInteres align-middle calculoInteres-big">
                                  Reajuste
                                </td>
                                <td className="text-right valores-calculoInteres">
                                  ${" "}
                                  {!calculando ? (
                                    dataIntereses.OMONTOREAJUSTE === 0 ? (
                                      0
                                    ) : (
                                        formatearNumero(
                                          Math.trunc(dataIntereses.OMONTOREAJUSTE)
                                        )
                                      )
                                  ) : (
                                      <Spinner
                                        className="nowrap m-l"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className="titulosTabla-calculoInteres align-middle calculoInteres-big">
                                  Interés
                                </td>
                                <td className="text-right valores-calculoInteres">
                                  ${" "}
                                  {!calculando ? (
                                    dataIntereses.OINTERES === 0 ? (
                                      0
                                    ) : (
                                        formatearNumero(
                                          Math.trunc(dataIntereses.OINTERES)
                                        )
                                      )
                                  ) : (
                                      <Spinner
                                        className="nowrap m-l"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className="titulosTabla-calculoInteres align-middle calculoInteres-big">
                                  Recargo
                                </td>
                                <td className="text-right valores-calculoInteres">
                                  ${" "}
                                  {!calculando ? (
                                    dataIntereses.ORECARGO === 0 ? (
                                      0
                                    ) : (
                                        formatearNumero(
                                          Math.trunc(dataIntereses.ORECARGO)
                                        )
                                      )
                                  ) : (
                                      <Spinner
                                        className="nowrap m-l"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className="titulosTabla-calculoInteres align-middle calculoInteres-big">
                                  Gasto cobranza
                                </td>
                                <td className="text-right valores-calculoInteres">
                                  ${" "}
                                  {!calculando ? (
                                    dataIntereses.OGASTOS === 0 ? (
                                      0
                                    ) : (
                                        formatearNumero(
                                          Math.trunc(dataIntereses.OGASTOS)
                                        )
                                      )
                                  ) : (
                                      <Spinner
                                        className="nowrap m-l"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className="total-calculoInteres align-middle calculoInteres-big">
                                  Total
                                </td>
                                <td className="text-right total-calculoInteres">
                                  ${" "}
                                  {!calculando ? (
                                    dataIntereses.OTOTAL === 0 ? (
                                      0
                                    ) : (
                                        formatearNumero(
                                          Math.trunc(dataIntereses.OTOTAL)
                                        )
                                      )
                                  ) : (
                                      <Spinner
                                        className="nowrap m-l p-t-0 p-b-0"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-sm-6 offset-sm-1">
                          <Button
                            size="sm"
                            id="calcularInteres"
                            onClick={this.handleImprimir}
                            disabled={calculando || imprimiendo}
                            variant={
                              this.props.datosSession &&
                                this.props.datosSession.frontOrigin === "B"
                                ? "danger"
                                : "success"
                            }
                          >
                            Imprimir
                            {imprimiendo && (
                              <Spinner
                                className="nowrap m-l"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </Button>
                          <Button
                            size="sm"
                            className="m-l-xs"
                            id="limpiar"
                            onClick={this.handleLimpiar}
                            disabled={calculando || imprimiendo}
                            variant={
                              this.props.datosSession &&
                                this.props.datosSession.frontOrigin === "B"
                                ? "danger"
                                : "success"
                            }
                          >
                            Limpiar
                          </Button>
                        </div>
                      </div>

                      <div className="row justify-content-center m-t">
                        <div className="col-sm-5 m-r-lg">
                          Le recomendamos guardar esta simulación
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </React.Fragment>
              </div>
            </div>
          )}
        {mensaje && !this.state.limpiar && mensaje !== "" && (
          <div className="row justify-content-center m-t m-l">
            <div className="col-sm-12">
              <Card>
                <Card.Header>Cálculo de intereses</Card.Header>
                <Card.Body>
                  <div className="row justify-content-center m-t m-l">
                    No hay datos en el período seleccionado
                  </div>
                  <div className="row justify-content-center m-t m-l">
                    <Button
                      size="sm"
                      id="limpiar"
                      onClick={this.handleLimpiar}
                      disabled={calculando || imprimiendo}
                      variant={
                        this.props.datosSession &&
                          this.props.datosSession.frontOrigin === "B"
                          ? "danger"
                          : "success"
                      }
                    >
                      Limpiar
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  calculando: state.calculoIntereses.calculando,
  dataIntereses: state.calculoIntereses.dataIntereses,
  imprimiendo: state.calculoIntereses.imprimiendo,
  datosSession: state.main.datosSession,
  mensaje: state.calculoIntereses.mensaje
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculoIntereses);
