import {
  GET_HISTORIAL_PAGO,
  HISTORIAL_PAGO_CARGANDO_TABLA,
  GET_DETALLE_PAGO
} from "../../../config/types";
import historialPagoCotizacionState from "./state";

export default function (state = historialPagoCotizacionState, action) {
  switch (action.type) {
    case GET_HISTORIAL_PAGO:
      return Object.assign({}, state, {
        detalleHistorial: action.payload
      });
    case HISTORIAL_PAGO_CARGANDO_TABLA:
      return Object.assign({}, state, {
        cargandoTabla: action.payload
      });
    case GET_DETALLE_PAGO:
      return Object.assign({}, state, {
        cargandoTabla: action.payload
      });
    default:
      return state;
  }
}
