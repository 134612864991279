import React from "react";
import { connect } from "react-redux";
import 'react-table/react-table.css'
import { getDataToken } from './actions';
import DetalleDeuda from '../cotizaciones/DetalleDeuda/view';
import DetalleDeudaDnp from '../cotizaciones/DetalleDeuda/viewDNP';
import DetalleDeudaNdnp from '../cotizaciones/DetalleDeuda/viewNDNP';
import DescargaCertificados from '../certificados/DescargaCertificados/view';
import DatosEmpleador from '../empleador/DatosEmpleador/view';
import DatosContacto from '../empleador/DatosContacto/view';
import CalcularIntereses from '../cotizaciones/CalculoIntereses/view';
import PagoCotizaciones from '../cotizaciones/Pago/view';
import HistorialPagoCotizaciones from '../cotizaciones/HistorialPago/view';
import MovimientoPersonal from '../empleador/MovimientoPersonal/view';
import PagoLeyUrgencia from '../cotizaciones/PagoLeyUrgencia/view';
import PagoExitoso from '../../components/PagoExitoFracaso/pagoExitoso';
import PagoFracaso from '../../components/PagoExitoFracaso/pagoFracaso';
import DeudaEmpresa from '../../components/deudaEmpresa/view';
import ReduxToastr from 'react-redux-toastr'

import queryString from 'query-string';
import pathsConfig from '../../config/paths';


class Main extends React.Component {

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    const { token } = values;
    this.props.dispatch(getDataToken(token));
  }

  render() {
    const { datosSession, direct, destino } = this.props;
    const { frontOrigin, frontPath } = datosSession;
    const values = queryString.parse(this.props.location.search);
    const { token } = values;
    return (
      <React.Fragment>
        { // datosSession && rutEmpresa && frontPath && frontOrigin &&
          <div role="main" className={`container-fluid ${frontOrigin === "B" ? "banmedica" : "vidaTres"} contenedor m-t-0`} id="contenedor" name="contenedor">
            {
              direct ?
                (destino === "cotizaciones" ? <PagoCotizaciones token={token} /> : <PagoLeyUrgencia token={token} />) :
                frontPath === pathsConfig.cotizaciones.detalleDeuda ? <DetalleDeuda /> :
                  frontPath === pathsConfig.cotizaciones.detalleDeudaDnp ? <DetalleDeudaDnp /> :
                    frontPath === pathsConfig.cotizaciones.detalleDeudaNdnp ? <DetalleDeudaNdnp /> :
                      frontPath === pathsConfig.empleador.datosEmpleador ? <DatosEmpleador /> :
                        frontPath === pathsConfig.cotizaciones.certificados ? <DescargaCertificados /> :
                          frontPath === pathsConfig.empleador.datosContacto ? <DatosContacto /> :
                            frontPath === pathsConfig.cotizaciones.calcularIntereses ? <CalcularIntereses /> :
                              frontPath === pathsConfig.cotizaciones.pagoCotizaciones ? <PagoCotizaciones token={token} /> :
                                frontPath === pathsConfig.cotizaciones.historialPagoCotizaciones ? <HistorialPagoCotizaciones token={token} /> :
                                  frontPath === pathsConfig.empleador.movimientoPersonal ? <MovimientoPersonal /> :
                                    frontPath === pathsConfig.cotizaciones.pagoLeyUrgencia ? <PagoLeyUrgencia token={token} /> :
                                      frontPath === pathsConfig.deudaEmpresa ? <DeudaEmpresa /> :
                                        frontPath === "pagoCotizacionesExitoso" ? <PagoExitoso /> :
                                          frontPath === "pagoCotizacionesFracaso" ? <PagoFracaso /> : null
            }

          </div>
        }
      </React.Fragment>

    )
  }
}

const mapStateToProps = (state) => ({
  datosSession: state.main.datosSession,
  statusToken: state.main.statusToken
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => { dispatch(action); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
