import { GET_DETALLE_DEUDA, DETALLE_DEUDA_CARGANDO_TABLA, DETALLE_DEUDA_EXPORTANDO_EXCEL,INTERNAL_ERROR } from '../../../config/types';
import axios from 'axios';
import data from '../../../helpers/data';
import uriConfig from "../../../config/api";
import store from '../../../store';
import { toastr } from 'react-redux-toastr'

export const cargandoDatos = (value) => async dispatch => {
    dispatch({
        type: DETALLE_DEUDA_CARGANDO_TABLA,
        payload: value
    })
}
export const exportandoExcel = (value) => async dispatch => {
    dispatch({
        type: DETALLE_DEUDA_EXPORTANDO_EXCEL,
        payload: value
    })
}
export const internalerror = (value,msg) => async dispatch => {
    dispatch({
        type: INTERNAL_ERROR,
        payload: {internalerror:value,msg:msg}
    });
};
export const getDetalleDeuda = (tipoDeuda) => async dispatch => {
    dispatch(cargandoDatos(true));
    axios.get(`${uriConfig.apiCotizaciones.getDetalleDeuda}?tipoDeuda=${tipoDeuda}`).then((res) => {
        if (res.status === 200) {
            dispatch({
                type: GET_DETALLE_DEUDA,
                payload: res.data.rows
            })
            dispatch(cargandoDatos(false));
        }
        dispatch(cargandoDatos(false));
    }
    ).catch((error) => {
        console.log("error", error.code);
        // dispatch({
        //     type: GET_DETALLE_DEUDA,
        //     payload: data
        // })
        dispatch(cargandoDatos(false));
        if (error.response.status === 408) {
            dispatch(internalerror(true,error.response.data.msg));
        } else {
            dispatch(internalerror(true,"Error desconocido"));
        }
    })
}

export const exportarExcel = (tipoDeuda) => async dispatch => {
    const { main } = store.getState();
    dispatch(exportandoExcel(true));

    axios.get(`${uriConfig.apiCotizaciones.getExportarExcel}?tipoDeuda=${tipoDeuda}`, { responseType: 'blob' })
        .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Detalle_deuda_${tipoDeuda}_${main.datosSession.rutEmpresa}.xlsx`);
            document.body.appendChild(link);
            link.click();
            dispatch(exportandoExcel(false));
        }
        ).catch((error) => {
            console.log("error", error);
            // dispatch({
            //     type: GET_DETALLE_DEUDA,
            //     payload: data
            // })
            dispatch(exportandoExcel(false));
            if (error.response.status === 408) {
                dispatch(internalerror(true,'El servicio se ha demorado bastante en responder'));
            } else {
                dispatch(internalerror(true,"Error desconocido"));
            }
        }
        )

}
