import {
  GET_DATOS_EMPLEADOR,
  GET_DATOS_SUCURSAL,
  DATOS_EMPLEADOR_CARGANDO,
  DATOS_SUCURSAL_CARGANDO,
  DATOS_EMPLEADOR_GUARDANDO,
  GET_DATOS_COMUNAS,
  GET_DATOS_REGIONES
} from "../../../config/types";
import axios from "axios";
import uriConfig from "../../../config/api";
import store from "../../../store";
import { toastr } from 'react-redux-toastr';
export const guardandoDatos = value => async dispatch => {
  dispatch({
    type: DATOS_EMPLEADOR_GUARDANDO,
    payload: value
  });
};
export const cargandoDatos = value => async dispatch => {
  dispatch({
    type: DATOS_EMPLEADOR_CARGANDO,
    payload: value
  });
};

export const cargandoDatossucursal = value => async dispatch => {
  dispatch({
    type: DATOS_SUCURSAL_CARGANDO,
    payload: value
  });
};

export const getDatosEmpleador = () => async dispatch => {
  const { main } = store.getState();
  axios
    .get(`${uriConfig.apiEmpleador.getDatos(main.datosSession.rutEmpresa)}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: GET_DATOS_EMPLEADOR,
          payload: res.data.rows
        });
      }
    })
    .catch(error => { });
};

export const getDatosSucursal = () => async dispatch => {
  dispatch(cargandoDatossucursal(true));
  const { main } = store.getState();
  axios
    .get(
      `${uriConfig.apiEmpleador.getCasaMatrizSucursales(
        main.datosSession.rutEmpresa
      )}`
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: GET_DATOS_SUCURSAL,
          payload: res.data.rows[0]
        });
      }
      dispatch(cargandoDatossucursal(false));

    })
    .catch(error => {
      dispatch(cargandoDatossucursal(false));
    });
};

export const actualizarDatos = data => async dispatch => {
  dispatch(guardandoDatos(true));
  const { main } = store.getState();
  axios
    .post(`${uriConfig.apiEmpleador.postActualizarDatos}`, {
      rutEmpresa: main.datosSession.rutEmpresa,
      isapre: main.datosSession.frontOrigin,
      cepaCcorr: data.CEPA_CCORR,
      usuario: main.datosSession.rutUsuario,
      calle: data.calle,
      tnumero: parseInt(data.numero),
      comuna: data.comuna,
      block: data.block,
      villa: data.villa,
      casilla: data.casilla,
      telefono: data.telefono,
      celular: data.fax,
      depto: data.depto,
      region: parseInt(data.region),
      mailContacto: data.email
    })
    .then(res => {
      if (res.status === 200) {
        dispatch(getDatosSucursal());
        dispatch(guardandoDatos(false));
      }
    })
  };

export const getComunas = () => async dispatch => {
  dispatch(cargandoDatos(true));
  const { main } = store.getState();
  axios
    .get(`${uriConfig.apiEmpleador.getComunas}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: GET_DATOS_COMUNAS,
          payload: res.data
        });
      }
      dispatch(cargandoDatos(false));
    })
    .catch(error => {
      dispatch(cargandoDatos(false));
    });
};

export const getRegiones = () => async dispatch => {
  dispatch(cargandoDatos(true));
  const { main } = store.getState();
  axios
    .get(`${uriConfig.apiEmpleador.getRegiones}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: GET_DATOS_REGIONES,
          payload: res.data
        });
      }
    })
    .catch(error => {
      dispatch(cargandoDatos(false));
    });
};
