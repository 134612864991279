const pagoCotizacionState = {
    detalleDeuda: [],
    messagesuccess: false,
    cargandoTabla: false,
    exportandoExcel: false,
    generandoAclaracion: false,
    redirect: false,
    pagando: false,
    calculoSiete: [],
    totalFinal: 0,
    internalerror: {internalerror:false,msg:null},
};

export default pagoCotizacionState;
