import {
  GET_DETALLE_DEUDA,
  GET_CALCULO_SIETE,
  DETALLE_DEUDA_CARGANDO_TABLA,
  DETALLE_DEUDA_EXPORTANDO_EXCEL,
  PAGO_REDIRECT,
  PAGO_COTIZACIONES_PAGANDO,
  SET_CALCULO_FINAL_SUMA,
  SET_CALCULO_FINAL_RESTA,
  INTERNAL_ERROR
} from "../../../config/types";
import axios from "axios";
import data from "../../../helpers/data";
import uriConfig from "../../../config/api";
import store from "../../../store";

export const internalerror = (value,msg) => async dispatch => {
  dispatch({
    type: INTERNAL_ERROR,
    payload: {internalerror:value,msg:msg}
  });
};

export const cargandoDatos = value => async dispatch => {
  dispatch({
    type: DETALLE_DEUDA_CARGANDO_TABLA,
    payload: value
  });
};

export const seteaCalculoSiete = (value, ind, id) => async dispatch => {
  dispatch({
    type: GET_CALCULO_SIETE,
    payload: { sieValue: value, sieIndex: ind, sieId: id }
  });
};

export const setTotalFinalSuma = (value) => async dispatch => {
  dispatch({
    type: SET_CALCULO_FINAL_SUMA,
    payload: value
  });
};
export const setTotalFinalResta = (value) => async dispatch => {
  dispatch({
    type: SET_CALCULO_FINAL_RESTA,
    payload: value
  });
};

export const pagando = value => async dispatch => {
  dispatch({
    type: PAGO_COTIZACIONES_PAGANDO,
    payload: value
  });
};

export const exportandoExcel = value => async dispatch => {
  dispatch({
    type: DETALLE_DEUDA_EXPORTANDO_EXCEL,
    payload: value
  });
};

export const getDetalleDeuda = () => async dispatch => {
  dispatch(cargandoDatos(true));
  axios
    .get(`${uriConfig.apiCotizaciones.getDetalleDeudaPago}`)
    .then(res => {
      dispatch(cargandoDatos(false));
      if (res.status === 200) {
        dispatch({
          type: GET_DETALLE_DEUDA,
          payload: res.data.rows
        });
      }

    })
    .catch(error => {
      console.log("error", error);
      dispatch(cargandoDatos(false));
      if (error.response.status === 408) {
        dispatch(internalerror(true,error.response.data.msg));
      } else {
        dispatch(internalerror(true,"Error desconocido"));
      }
    });
};

export const realizarPago = params => async dispatch => {
  dispatch(pagando(true));
  const { main } = store.getState();
  dispatch({
    type: PAGO_REDIRECT,
    payload: false
  });
  localStorage.clear();
  axios
    .post(`${uriConfig.apiCotizaciones.postInsertarPagoCompleto}`, {
      dnp: params.dnpArray,
      nodnp: params.nodnpArray,
      monto: params.totalPagar
    })
    .then(res => {
      if (res.status === 201) {
        localStorage.setItem("pagoId", res.data.pagoId);
        localStorage.setItem("origen", "cotizaciones");

        localStorage.setItem(
          "datosSession.rutEmpresa",
          main.datosSession.rutEmpresa
        );
        localStorage.setItem(
          "datosSession.rutUsuario",
          main.datosSession.rutUsuario
        );
        localStorage.setItem(
          "datosSession.frontOrigin",
          main.datosSession.frontOrigin
        );
        localStorage.setItem("token", res.data.token.InicializaPagoResult);
        localStorage.setItem("tokenApi", params.token);
        dispatch(pagando(false));

        dispatch({
          type: PAGO_REDIRECT,
          payload: true
        });
      } else {
        dispatch(pagando(false));
      }
    })
    .catch(error => {
      console.log("error", error);
      dispatch(pagando(false));
      if (error.response.status === 408) {
        dispatch(internalerror(true,error.response.data.msg));
      } else {
        dispatch(internalerror(true,"Error desconocido"));
      }
    });
};

export const realizarPagoMDPG = params => async dispatch => {
  dispatch(pagando(true));
  const { main } = store.getState();
  dispatch({
    type: PAGO_REDIRECT,
    payload: false
  });
  localStorage.clear();
  axios
    .post(`${uriConfig.apiCotizaciones.postInsertarPagoCompletoMDPG}`, {
      dnp: params.dnpArray,
      nodnp: params.nodnpArray,
      monto: params.totalPagar,
      correo: params.correo
    })
    .then(res => {
      if (res.status === 201) {
        localStorage.setItem("pagoId", res.data.pagoId);
        localStorage.setItem("tokenPago", res.data.idTrx);

        dispatch({
          type: PAGO_REDIRECT,
          payload: res.data
        });
      } else {
        dispatch(pagando(false));
      }
    })
    .catch(error => {
      console.log("error", error);
      dispatch(pagando(false));
      if (error.response.status === 408) {
        dispatch(internalerror(true,error.response.data.msg));
      } else {
        dispatch(internalerror(true,"Error desconocido"));
      }
    });
};

export const getCalculoSiete = (params, ind, id) => async dispatch => {
  // dispatch(cargandoDatos(true));
  axios
    .post(`${uriConfig.apiCotizaciones.getCalculoSiete}`, params)
    .then(res => {
      if (res.status === 200) {
        dispatch(seteaCalculoSiete(res.data.rows[0], ind, id));
        // dispatch(cargandoDatos(false));
      }
      // dispatch(cargandoDatos(false));
    })
    .catch(error => {
      console.log("error", error);
      if (error.response.status === 408) {
        dispatch(internalerror(true,error.response.data.msg));
      } else {
        dispatch(internalerror(true,"Error desconocido"));
      }
      // dispatch(cargandoDatos(false));
    });
};

export const calcularIntereses = params => async dispatch => {
  // const { main } = store.getState();
  dispatch(cargandoDatos(true));
  axios
    .post(`${uriConfig.apiCotizaciones.postCalcularIntereses}`, params)
    .then(res => {
      if (res.status === 200) {
        console.log('Total:', res.data.rows.OTOTAL);
        console.log('I+R:', parseFloat(res.data.rows.OINTERES + res.data.rows.ORECARGO));
        console.log('Gastos: ', res.data.rows.OGASTOS);
        console.log('Reajuste:', res.data.rows.OMONTOREAJUSTE);
        dispatch(cargandoDatos(false));
      } else {
        dispatch(cargandoDatos(false));
      }
    })
    .catch(error => {
      console.log("error", error.code);
      dispatch(cargandoDatos(false));
    });
};
