import {
  GET_DATOS_EMPLEADOR,
  GET_DATOS_REGIONES,
  GET_DATOS_COMUNAS,
  GET_DATOS_SUCURSAL,
  DATOS_EMPLEADOR_GUARDANDO,
  DATOS_EMPLEADOR_CARGANDO,
  DATOS_SUCURSAL_CARGANDO
} from "../../../config/types";
import datosEmpleadorState from "./state";

export default function(state = datosEmpleadorState, action) {
  switch (action.type) {
    case GET_DATOS_EMPLEADOR:
      return Object.assign({}, state, {
        datos: action.payload
      });
    case GET_DATOS_SUCURSAL:
      return Object.assign({}, state, {
        sucursal: action.payload
      });
    case GET_DATOS_REGIONES:
      return Object.assign({}, state, {
        regiones: action.payload
      });
    case GET_DATOS_COMUNAS:
      return Object.assign({}, state, {
        comunas: action.payload
      });
    case DATOS_EMPLEADOR_GUARDANDO:
      return Object.assign({}, state, {
        guardando: action.payload
      });
    case DATOS_EMPLEADOR_CARGANDO:
      return Object.assign({}, state, {
        cargando: action.payload
      });
    case DATOS_SUCURSAL_CARGANDO:
      return Object.assign({}, state, {
        cargandoSucursal: action.payload
      });
    default:
      return state;
  }
}
