const MovimientoPersonalState = {
    messagesuccess: false,
    calculando: false,
    imprimiendo: false,
    dataIntereses: {},
    mensaje: "",
    datos: null,
    datosAfiliado: null,
    cargandoEmpleador: false,
    cargandoAfiliado: false,
    mensajeCargandoAfiliado: "",
    guardando: false,
}

export default MovimientoPersonalState;
