import { GET_DATA_TOKEN } from '../../config/types';
import request from '../../config/request';
import uriConfig from "../../config/api";

export const getDataToken = (token) => async dispatch => {
    request.post(`${uriConfig.apiAuthorization.postTokenData}`, {
        token: token,
    }).then( (res) => {
        if ( res.status === 200) {
            request.defaults.headers.common['Authorization'] = 'Bearer '+ token;
        dispatch({
            type: GET_DATA_TOKEN,
            payload: res.data.data
        })
        }
    }
    ).catch( (error) => {
        console.log(error);       
        })
    }
