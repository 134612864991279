import React, { Component } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import uriConfig from "../../config/api";

class CotizacionesPagoFracaso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      calculado: false,
      checked: [],
      adicional: [],
      imponible: [],
      deuda: [],
      cotiza7: [],
      bloqueados: []
    };
  }

  componentDidMount() {
    // this.props.dispatch(getDataToken());
  }

  render() {
    const { datosSession } = this.props;
    const { frontOrigin } = datosSession;

    return (
      <React.Fragment>
        <div
          role="main"
          className={`container-fluid ${
            frontOrigin === "B" ? "banmedica" : "vidaTres"
          } contenedor`}
          id="contenedor"
          name="contenedor"
        >
          <div className="row justify-content-left h2 m-l-0">
            <p>Pago cotizaciones</p>
          </div>
          <div className="row justify-content-center m-l-lg m-t-lg-2 h2">
            <div className="col-sm-12">
              <FaTimesCircle color="red" className="m-r m-b-7" />
              <span className="m-t-5">
                ¡Lo sentimos! Tu pago no se ha podido realizar
              </span>
            </div>
          </div>

          <div className="row justify-content-center m-l-lg m-t-lg-2">
            <div className="col-sm-12">
              <b>Las posibles causas de este rechazo son:</b>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="row justify-content-center m-t-lg-2">
              <ul className="lista">
                <li className="lista">
                  Error en el ingreso de los datos de su tarjeta de crédito o
                  débito (fecha y/o código de seguridad).
                </li>
                <li className="lista">
                  Su tarjeta de crédito o débito no cuenta con el cupo necesario
                  para realizar la compra.
                </li>
                <li className="lista">
                  Tarjeta aún no habilitada en el sistema financiero.
                </li>
              </ul>
            </div>
          </div>
          
        </div>{" "}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  datosSession: state.main.datosSession
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CotizacionesPagoFracaso);
