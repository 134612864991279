const DatosContactoState = {
    datos: null,
    messagesuccess: false,
    surcursal: null,
    guardando: false,
    cargando: false,
    mensajeError: "",
}

export default DatosContactoState;
