import { combineReducers } from 'redux';
import detalleDeudaReducer from './components/cotizaciones/DetalleDeuda/reducer'
import pagoCotizacionesReducer from './components/cotizaciones/Pago/reducer'
import historialPagoCotizacionesReducer from './components/cotizaciones/HistorialPago/reducer'
import mainReducer from './components/Main/reducer'
import datosEmpleadorReducer from "./components/empleador/DatosEmpleador/reducer"
import descargaCertificadosReducer from "./components/certificados/DescargaCertificados/reducer"
import datosContactoReducer from "./components/empleador/DatosContacto/reducer"
import datosDeudaEmpresa from "./components/deudaEmpresa/reducer"
import calculoInteresReducer from "./components/cotizaciones/CalculoIntereses/reducer"
import movimientoPersonalReducer from "./components/empleador/MovimientoPersonal/reducer"
import pagoExitosoReducer from "./components/PagoExitoFracaso/reducer";
import pagoLeyUrgenciaReducer from "./components/cotizaciones/PagoLeyUrgencia/reducer";
import { reducer as toastrReducer } from 'react-redux-toastr'


export default combineReducers({
    detalleDeuda: detalleDeudaReducer,
    main: mainReducer,
    datosEmpleador: datosEmpleadorReducer,
    descargaCertificados: descargaCertificadosReducer,
    datosContacto: datosContactoReducer,
    calculoIntereses: calculoInteresReducer,
    pagoCotizaciones: pagoCotizacionesReducer,
    historialPagoCotizaciones: historialPagoCotizacionesReducer,
    movimientoPersonal: movimientoPersonalReducer,
    pagoExitoso: pagoExitosoReducer,
    pagoLeyUrgencia: pagoLeyUrgenciaReducer,
    toastr: toastrReducer,
    datosDeudaEmpresa: datosDeudaEmpresa,
});