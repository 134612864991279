import {
   GET_DEUDA_EMPRESA,
   CARGANDO_DEUDA_EMPRESA,
   DEUDA_EMPRESA_EXPORTANDO_EXCEL,
   INTERNAL_ERROR
} from "../../config/types";
import datosDeudaEmpresa from "./state";
export default function (state = datosDeudaEmpresa, action) {
   switch (action.type) {
      case GET_DEUDA_EMPRESA:
         return Object.assign({}, state, {
            datos: action.payload
         });
      case CARGANDO_DEUDA_EMPRESA:
         return Object.assign({}, state, {
            loading: action.payload
         });
      case DEUDA_EMPRESA_EXPORTANDO_EXCEL:
         return Object.assign({}, state, {
            exportandoExcelDeudaEmpresa: action.payload
         });
      case INTERNAL_ERROR:
         return Object.assign({}, state, {
            internalerror: action.payload,
         });
      default:
         return state;
   }
}
