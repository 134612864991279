import React, { Component } from "react";
import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import PagoExitosoLeyUrgencia from "./pagoExitosoLeyUrgencia";
import PagoExitosoCotizaciones from "./pagoExitosoCotizaciones";

class PagoExitoso extends Component {
  render() {
    return (
      <React.Fragment>
        <PagoExitosoCotizaciones /> 
      </React.Fragment>
    );
  }
}

export default PagoExitoso;
