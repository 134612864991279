import React, { Component } from "react";
import "./css/custom.scss";
import "./css/App.scss";

import Main from "./components/Main/view";
import PagoCotizacionesExitoso from "./components/PagoExitoFracaso/pagoExitoso";
import PagoCotizacionesFracaso from "./components/PagoExitoFracaso/pagoFracaso";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReduxToastr from 'react-redux-toastr'

import { Provider } from "react-redux";
import store from "./store";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
                <ReduxToastr
              timeOut={3000}
              newestOnTop={false}
              preventDuplicates
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              closeOnToastrClick/>
        <React.Fragment>
          <Router>
            <React.Fragment>
              <Switch>
                <Route
                  exact
                  path="/pagoCotizaciones"
                  render={props => (
                    <Main {...props} direct={true} destino="cotizaciones" />
                  )}
                />
                <Route
                  exact
                  path="/deudaEmpresa"
                  render={props => (
                    <Main {...props} direct={true} destino="deudaEmpresa" />
                  )}
                />
                <Route
                  exact
                  path="/pagoLeyUrgencia"
                  render={props => (
                    <Main {...props} direct={true} destino="leyUrgencia" />
                  )}
                />
                <Route exact path="/" component={Main} />
                <Route
                  exact
                  path="/pagoCotizacionesExitoso"
                  component={PagoCotizacionesExitoso}
                />
                <Route
                  exact
                  path="/pagoCotizacionesFracaso"
                  component={PagoCotizacionesFracaso}
                />
              </Switch>
            </React.Fragment>
          </Router>
          
        </React.Fragment>
      </Provider>
    );
  }
}

export default App;
