const pagoExitosoState = {
    datosPago: null,
    cargando: false,
    descargando: false,
    imprimiendo: false,
    datosPlanillas: null,
    datosFolios: null,
    cargandoTabla:false,
    internalerror: {internalerror:false,msg:null}
};

export default pagoExitosoState;
