import React, { Component } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import Spinner from "react-bootstrap/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { FaFileAlt } from "react-icons/fa";
import { getInformacionPago,getInformacionPagoMDPG,descargarPlanillaBancobro } from "./actions";
import Table from "react-bootstrap/Table";
import { formatearNumero } from "../../helpers/util";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Error from "../error/view";
import { FaCheckCircle } from "react-icons/fa";

class CotizacionesPagoExitoso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      calculado: false,
      checked: [],
      adicional: [],
      imponible: [],
      deuda: [],
      cotiza7: [],
      bloqueados: []
    };
    this.redirect = this.redirect.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getInformacionPagoMDPG());
  }

  redirect() {
    this.props.history.push("/pagoCotizaciones");
  }


  handleClickPlanilla(event,v){
    //this.props.dispatch(descargarPlanillaBancobro('6742'));
    this.props.dispatch(descargarPlanillaBancobro(v.PPLAN_NFOLIO_PAGO));
  }

  render() {
    let { cargandoTabla, datosPago, internalerror } = this.props;
    let pendiente = false;
    if (datosPago){
      pendiente = datosPago.pendiente;
      datosPago = datosPago.result.rows;
    }
 
    const { frontOrigin } = this.props.datosSession;

    if (internalerror.internalerror){
      return <Error msg={internalerror.msg}/> ;
    } else {
      return (
          <React.Fragment>
            {frontOrigin && (
                <div
                    role="main"
                    className={`container-fluid ${
                        frontOrigin === "B" ? "banmedica" : "vidaTres"
                    } contenedor`}
                    id="contenedor"
                    name="contenedor"
                >
                  <div className="row justify-content-left h2 m-l-0">
                    <p>Comprobantes</p>
                  </div>
                  { pendiente ? 
                    (
                      <div className="row justify-content-left m-l-0">
                        <div className="col-sm-12">
                          <div className="alert alert-warning" role="alert">
                            <h4 className="alert-heading"><FaCheckCircle color="green" className="m-r m-b-7" />El pago ha sido realizado con éxito</h4>
                            <hr/>
                            <p className="mb-0">Te informamos que tu pago fue realizado correctamente, sin embargo detectamos un problema al registrar la operación en la Isapre. Por tal motivo, te enviaremos un mail a tu correo, con el fin de completar la transacción y obtener tu comprobante de pago.</p>
                          </div>
                        </div>
                      </div>
                    )
                  :
                    (
                      <div className="row justify-content-left m-l-0">
                        <div className="col-sm-12">
                          <div className="alert alert-success" role="alert">
                            <h4 className="alert-heading"><FaCheckCircle color="green" className="m-r m-b-7" />El pago ha sido realizado con éxito</h4>
                            <hr/>
                            <p className="mb-0">Tu transacción realizada queda a confirmación de transbank</p>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  
                  <div className="row justify-content-left m-l-0">
                    A continuación se encuentran los comprobantes de pago generados a
                    partir de las cotizaciones
                  </div>
                  <div className="col-sm-12 m-l-0 p-l-0 m-t">
                    <Table className="tableDeuda">
                      <thead>
                      <tr>
                        <th className="vertical-center">Folio de pago</th>
                        <th className="vertical-center">Tipo Deuda</th>
                        <th className="vertical-center">Monto Total</th>
                        <th className="vertical-center">Planilla</th>
                      </tr>
                      </thead>
                      <tbody>
                      {!cargandoTabla ? (
                          datosPago && datosPago.length > 0 ? (
                              datosPago.map((v, index) => (
                                  <tr>
                                    <td className="text-center">{v.PPLAN_NFOLIO_PAGO}</td>
                                    <td className="text-center">{v.WPCE_TIPO === '4' ? 'DNP' :
                                                                 v.WPCE_TIPO === '5'? 'DNP' : 'NDNP'}</td>
                                    <td className="text-center">
                                      $ {formatearNumero(v.WPCE_MONTO)}
                                    </td>
                                    <td className="text-center">
                                      <Button
                                          key={`${v.PPLAN_NFOLIO_PAGO}`}
                                          size="sm"
                                          id={`planilla-${index}`}
                                          onClick={(e) => this.handleClickPlanilla(e, v)}
                                          variant={
                                            this.props.datosSession &&
                                            this.props.datosSession.frontOrigin === "B"
                                                ? "danger"
                                                : "success"
                                          }
                                      >
                                        <FaFileAlt></FaFileAlt>
                                      </Button>
                                    </td>
                                  </tr>
                              ))
                          ) : (
                              <tr>
                                <td className="nowrap text-center" colSpan="5">
                                  No hay datos.
                                </td>
                              </tr>
                          )
                      ) : (
                          <tr>
                            <td className="text-center" colSpan="5">
                              Cargando...
                              <Spinner
                                  className="nowrap m-l"
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                              />
                            </td>
                          </tr>
                      )}
                      </tbody>
                    </Table>
                    <div className="row justify-content-center m-t-lg-2">
                      <Button
                          size="sm"
                          className="m-l"
                          variant={
                            this.props.datosSession &&
                            this.props.datosSession.frontOrigin === "B"
                                ? "danger"
                                : "success"
                          }
                          onClick={this.redirect}
                      >
                        Volver
                      </Button>
                    </div>
                  </div>
                </div>
            )}
          </React.Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({
  cargandoTabla: state.pagoExitoso.cargandoTabla,
  redirect: state.pagoExitoso.redirect,
  exportandoExcel: state.pagoExitoso.exportandoExcel,
  datosSession: state.main.datosSession,
  datosPago: state.pagoExitoso.datosPago,
  internalerror: state.pagoExitoso.internalerror
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CotizacionesPagoExitoso)
);
