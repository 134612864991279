import React, { Component } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import {
  getDatosEmpleador,
  guardarDesvinculacion,
  getDatosAfiliado
} from "./actions";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import es from "date-fns/locale/es";
import { formatRut, rutValidate, rutClean } from "../../../helpers/rut";
import { FaTimesCircle } from "react-icons/fa";
import InputGroup from "react-bootstrap/InputGroup";

registerLocale("es", es);

class MovimientoPersonal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 0,
      error: { rut: null },
      startDate: null,
      startDate2: new Date(),
      monto: "",
      montoFinal: "",
      calculado: false,
      rut: ""
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getDatosEmpleador());
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.mensajeCargandoAfiliado &&
      newProps.mensajeCargandoAfiliado !== this.props.mensajeCargandoAfiliado
    ) {
      const error = {
        rut: newProps.mensajeCargandoAfiliado
      };
      this.setState({ error });
    }
  }
  onChange(date) {
    this.setState({
      startDate: date
    });
  }

  onChange2(date) {
    this.setState({
      startDate2: date
    });
  }
  handleBlur() {
    if (this.validateField()) {
      this.props.dispatch(getDatosAfiliado(this.state));
    }
  }

  handleDateChangeRaw = e => {
    e.preventDefault();
  };
  validateField = () => {
    const error = {
      rut: null
    };
    const { rut } = this.state;

    if (rut === "") error.rut = "Campo obligatorio";
    else if (!rutValidate(rut)) error.rut = "Rut inválido";

    this.setState({ error: error });

    if (error.rut) {
      return false;
    }
    return true;
  };
  handleOnClick = event => {
    if (this.validateField())
      this.props.dispatch(guardarDesvinculacion(this.state));
  };

  handleOnChange(event) {
    const error = { email: null };
    const { id, value } = event.target;
    if (id !== "email" && id !== "rut" && value.length < 50)
      this.setState({ [id]: value });
    if (id === "email") {
      if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        error.email = "Mail con formato inválido";
      }

      this.setState({ error: error, [id]: value });
    }

    if (id === "rut" && value.length < 13)
      this.setState({ [id]: formatRut(rutClean(value)) });
  }
  render() {
    const {
      datos,
      cargandoEmpleador,
      cargandoAfiliado,
      guardando
    } = this.props;
    return (
      <React.Fragment>
        <div className="row justify-content-left m-l m-t-lg-2">
          <div className="col-sm-12">
            <div className="row">
              <b>
                Aquí podrá ingresar la desvinculación de su personal que es
                afiliado a nuestra Isapre.
              </b>
            </div>
          </div>
        </div>
        <div className="row justify-content-left m-l m-t">
          <div className="col-sm-12">
            <Table className="tableEmpleador">
              <tbody>
                <tr>
                  <td className="titulosTabla-horizontal align-middle titulos-horizontal-fix-1">
                    Rut Empleador
                  </td>
                  <td>
                    {cargandoEmpleador ? (
                      <Spinner
                        className="m-l"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                        <Col>
                          {this.props.datosSession.rutEmpresa +
                            "-" +
                            (datos && datos[0].EMPL_XDV)}
                        </Col>
                      )}
                  </td>
                </tr>
                <tr>
                  <td className="titulosTabla-horizontal align-middle titulos-horizontal-fix-1">
                    Nombre Empleador
                  </td>
                  <td>
                    {cargandoEmpleador ? (
                      <Spinner
                        className="m-l"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                        <Col>{datos && datos[0].EMPL_TNOMBRE_RAZON_SOCIAL}</Col>
                      )}
                  </td>
                </tr>
                <tr>
                  <td className="titulosTabla-horizontal align-middle titulos-horizontal-fix-1">
                    RUT trabajador
                  </td>
                  <td className="tableEditando-chica">

                    <Form.Group>
                      <InputGroup className="input" size="sm">
                        <div className="col-sm-6">
                          <Form.Control
                            size="sm"
                            className="m-l-0"
                            id="rut"
                            onChange={this.handleOnChange}
                            value={this.state.rut}
                            disabled={cargandoEmpleador || cargandoAfiliado}
                            onBlur={this.handleBlur}
                          />
                        </div>
                        <div className="col-sm-6">
                          {cargandoAfiliado && (
                            <Spinner
                              className="m-l m-t-5"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}

                          <Form.Control.Feedback
                            className="displayError m-t-5"
                            type="invalid"
                          >
                            {this.state.error.rut}
                          </Form.Control.Feedback>
                          {!this.state.error.rut && (
                            <Form.Control.Feedback
                              className="displayError"
                              type="invalid"
                            >
                              {}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </InputGroup>
                    </Form.Group>

                  </td>
                </tr>
                <tr>
                  <td className="titulosTabla-horizontal align-middle titulos-horizontal-fix-1">
                    Nombre trabajador
                  </td>
                  <td>
                    <Col>
                      {this.props.datosAfiliado &&
                        this.props.datosAfiliado.length > 0 &&
                        this.props.datosAfiliado[0].NOMBRE}
                    </Col>
                  </td>
                </tr>
                <tr>
                  <td className="titulosTabla-horizontal align-middle titulos-horizontal-fix-1 nowrap">
                    Fecha inicio relación laboral
                  </td>
                  <td className="tableEditando-chica">
                    <Col sm="6" className="p-r-0">
                      <DatePicker
                        autoComplete="off"
                        size="sm"
                        className="inputCalendar p-r-0"
                        id="fechaDesde"
                        selected={this.state.startDate}
                        onChange={this.onChange}
                        dateFormat="dd/MM/yyyy"
                        strictParsing
                        onChangeRaw={this.handleDateChangeRaw}
                        locale="es"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={this.state.startDate2}
                        disabled={cargandoEmpleador || cargandoAfiliado}
                        isClearable={true}
                      />{" "}
                    </Col>
                    <Col sm="6"></Col>
                  </td>
                </tr>

                <tr>
                  <td className="titulosTabla-horizontal align-middle titulos-horizontal-fix-1 nowrap">
                    Fecha término relación laboral
                  </td>
                  <td className="tableEditando-chica">
                    <Col sm="6" className="p-r-0">
                      <DatePicker
                        size="sm"
                        className="inputCalendar"
                        id="fechaHasta"
                        selected={this.state.startDate2}
                        onChange={this.onChange2}
                        dateFormat="dd/MM/yyyy"
                        strictParsing
                        onChangeRaw={this.handleDateChangeRaw}
                        locale="es"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={new Date()}
                        minDate={this.state.startDate === "" ? "" : this.state.startDate}
                        disabled={cargandoEmpleador || cargandoAfiliado}
                      />{" "}
                    </Col>
                    <Col sm="6"></Col>

                  </td>
                </tr>
              </tbody>
            </Table>
            <Row>
              <Col sm="3">
                <Button
                  size="sm"
                  id="guardarDesvinculacion"
                  disabled={
                    cargandoEmpleador || cargandoAfiliado || this.state.error.rut
                  }
                  onClick={this.handleOnClick}
                  variant={
                    this.props.datosSession &&
                      this.props.datosSession.frontOrigin === "B"
                      ? "danger"
                      : "success"
                  }
                >
                  Guardar
              {guardando &&
                    <Spinner
                      className="m-l"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  }

                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  calculando: state.movimientoPersonal.calculando,
  dataIntereses: state.movimientoPersonal.dataIntereses,
  imprimiendo: state.movimientoPersonal.imprimiendo,
  cargandoEmpleador: state.movimientoPersonal.cargandoEmpleador,
  datosSession: state.main.datosSession,
  mensaje: state.movimientoPersonal.mensaje,
  datos: state.movimientoPersonal.datos,
  cargandoAfiliado: state.movimientoPersonal.cargandoAfiliado,
  datosAfiliado: state.movimientoPersonal.datosAfiliado,
  mensajeCargandoAfiliado: state.movimientoPersonal.mensajeCargandoAfiliado,
  guardando: state.movimientoPersonal.guardando

});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MovimientoPersonal);
