const detalleDeudaState = {
    detalleDeuda: [],
    messagesuccess: false,
    cargandoTabla: false,
    exportandoExcel: false,
    generandoAclaracion: false,
    internalerror: {internalerror:false,msg:null},
};

export default detalleDeudaState;
