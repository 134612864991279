import React, { Component } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import {
  getDatosEmpleador,
  getDatosSucursal,
  getComunas,
  getRegiones,
  actualizarDatos
} from "./actions";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import _ from "lodash";

class DatosEmpleador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 0,
      calle: "",
      sucursales: [],
      comuna: "",
      numero: "",
      ciudad: "",
      telefono: "",
      fax: "",
      casilla: "",
      depto: "",
      block: "",
      villa: "",
      region: "",
      email: "",
      CEPA_CCORR: "",
      editando: false,
      error: {
        calle: null,
        numero: null,
        comuna: null,
        ciudad: null,
        telefono: null,
        region: null,
        email: null
      }
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.toggleEditar = this.toggleEditar.bind(this);
    this.cancelarEditar = this.cancelarEditar.bind(this);
    this.actualizarDatos = this.actualizarDatos.bind(this);
    this.validateField = this.validateField.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(getComunas());
    this.props.dispatch(getRegiones());
    this.props.dispatch(getDatosEmpleador());
    this.props.dispatch(getDatosSucursal());
  }

  handleOnChange(event) {
    const { id, value } = event.target;
    const { comunas } = this.props;
    const re = /^[0-9\b]+$/;
    if (id === "telefono") {
      if ((value === "" || re.test(value)) && value.length < 10) {
        this.setState({ [id]: value });
      }
    } else if (id === "block") {
      if ((value === "" || value)) {
        this.setState({ [id]: value });
      }
    } else if (id === "villa") {
      if ((value === "" || value)) {
        this.setState({ [id]: value });
      }
    } else if (id === "fax") {
      if ((value === "" || re.test(value)) && value.length < 10) {
        this.setState({ [id]: value });
      }
    } else if (id === "casilla") {
      if ((value === "" || value)) {
        this.setState({ [id]: value });
      }
    } else if (id === "numero") {
      if ((value === "" || re.test(value)) && value.length < 9) {
        this.setState({ [id]: value });
      }
    } else if (id === "comuna") {
      if ((value === "" || value)) {
        this.setState({ [id]: parseFloat(value) });
      }
    } else if (id === "ciudad") {
      const primeraComuna =
        comunas &&
        comunas.find(f => f.nombreCiudad.toUpperCase() === value) &&
        comunas.find(f => f.nombreCiudad.toUpperCase() === value).localidad;
      this.setState({ [id]: value });
      this.setState({ comuna: primeraComuna });
    } else if (id === "region") {
      const primeraCiudad =
        comunas &&
        comunas.find(f => parseInt(f.region) === parseInt(value)) &&
        String(
          comunas.find(f => parseInt(f.region) === parseInt(value)).nombreCiudad
        );
      const primeraComuna =
        comunas &&
        comunas.find(f => f.nombreCiudad === value) &&
        comunas.find(f => (f.nombreCiudad) === (value))
          .localidad;
      this.setState({
        [id]: value,
        ciudad: primeraCiudad,
        comuna: primeraComuna
      });
    } else if (value.length < 50) this.setState({ [id]: value });
  }

  componentWillReceiveProps(newProps) {
    const { sucursal, regiones, comunas } = newProps;
    if (sucursal && regiones && comunas && comunas !== this.props.comunas) {
      this.setState({
        CEPA_CCORR: sucursal.CEPA_CCORR && sucursal.CEPA_CCORR,
        calle:
          (sucursal.CEPA_TCALLE &&
            sucursal.CEPA_TCALLE.toString().trim()) ||
          "",
        comuna:
          comunas &&
          comunas.find(
            f =>
              f.localidad ===
              sucursal.CODIGO_COMUNA
          ).localidad,
        numero:
          (sucursal.CEPA_TNUMERO &&
            sucursal.CEPA_TNUMERO.toString().trim()) ||
          "",
        ciudad:
          (
            sucursal.LOCA_TNOMBRE_CIUDAD &&
            String(sucursal.LOCA_TNOMBRE_CIUDAD.toString().trim())
          ).toUpperCase() || "",
        telefono:
          (sucursal.CEPA_TFONO &&
            sucursal.CEPA_TFONO.toString().trim()) ||
          "",
        fax:
          (sucursal.CEPA_TFAX && sucursal.CEPA_TFAX.toString().trim()) ||
          "",
        casilla:
          (sucursal.CEPA_TCASILLA &&
            sucursal.CEPA_TCASILLA.toString().trim()) ||
          "",
        depto:
          (sucursal.CEPA_TDEPTO &&
            sucursal.CEPA_TDEPTO.toString().trim()) ||
          "",
        block:
          (sucursal.CEPA_TBLOCK &&
            sucursal.CEPA_TBLOCK.toString().trim()) ||
          "",
        villa:
          (sucursal.CEPA_TVILLA &&
            sucursal.CEPA_TVILLA.toString().trim()) ||
          "",
        region:
          (sucursal.CEPA_NREGION &&
            sucursal.CEPA_NREGION.toString().trim()) ||
          "",
        email:
          (sucursal.CEPA_TCORREO &&
            sucursal.CEPA_TCORREO.toString().trim()) ||
          ""
      });
    }

    if (
      sucursal !== this.props.sucursal &&
      regiones &&
      comunas &&
      comunas.length > 0
    ) {
      this.setState({
        comuna:
          comunas &&
          comunas.find(
            f =>
              String(f.nombreComuna).toUpperCase() ===
              sucursal.LOCA_TNOMBRE_COMUNA
          ).localidad
      });
    }
  }

  toggleEditar = () => {
    const sucursal = this.props.sucursal;
    const comunas = this.props.comunas;
    this.setState({
      editando: !this.state.editando,
      CEPA_CCORR: sucursal.CEPA_CCORR && sucursal.CEPA_CCORR,
      calle:
        (sucursal.CEPA_TCALLE &&
          sucursal.CEPA_TCALLE.toString().trim()) ||
        "",
      comuna:
        (sucursal.CODIGO_COMUNA &&
          sucursal.CODIGO_COMUNA) ||
        "",
      numero:
        (sucursal.CEPA_TNUMERO &&
          sucursal.CEPA_TNUMERO.toString().trim()) ||
        "",
      ciudad:
        (sucursal.LOCA_TNOMBRE_CIUDAD &&
          sucursal.LOCA_TNOMBRE_CIUDAD.toString().trim()) ||
        "",
      telefono:
        (sucursal.CEPA_TFONO &&
          sucursal.CEPA_TFONO.toString().trim()) ||
        "",
      fax:
        (sucursal.CEPA_TFAX && sucursal.CEPA_TFAX.toString().trim()) ||
        "",
      casilla:
        (sucursal.CEPA_TCASILLA &&
          sucursal.CEPA_TCASILLA.toString().trim()) ||
        "",
      depto:
        (sucursal.CEPA_TDEPTO &&
          sucursal.CEPA_TDEPTO.toString().trim()) ||
        "",
      block:
        (sucursal.CEPA_TBLOCK &&
          sucursal.CEPA_TBLOCK.toString().trim()) ||
        "",
      villa:
        (sucursal.CEPA_TVILLA &&
          sucursal.CEPA_TVILLA.toString().trim()) ||
        "",
      region:
        (sucursal.CEPA_NREGION &&
          sucursal.CEPA_NREGION.toString().trim()) ||
        "",
      email:
        (sucursal.CEPA_TCORREO &&
          sucursal.CEPA_TCORREO.toString().trim()) ||
        "",
      error: {
        calle: null,
        numero: null,
        comuna: null,
        ciudad: null,
        telefono: null,
        region: null,
        email: null
      }
    });
  };

  cancelarEditar = () => {
    this.setState({ editando: false });
  };

  actualizarDatos = () => {
    if (this.validateField()) {
      this.props.dispatch(actualizarDatos(this.state));
      this.toggleEditar();
    }
  };

  getNameRegion(id) {
    let idStr = null;
    let reg = '';
    if (typeof id === 'number') {
      idStr = String(id);
      reg = this.props.regiones.find(x => x.numero === idStr).nombre;
    }
    return reg;
  }

  getNameComuna(reg, com) {
    let comuna = '-';
    const comunas = this.props.comunas;

    comunas.map(c => {
      if (c.localidad === com && c.region === reg) {
        comuna = c.nombreComuna.toUpperCase();
      }
    })

    return comuna;
  }

  validateField = () => {
    const error = {
      calle: null,
      numero: null,
      comuna: null,
      ciudad: null,
      telefono: null,
      region: null,
      email: null
    };
    const {
      calle,
      numero,
      comuna,
      ciudad,
      telefono,
      region,
      email
    } = this.state;

    if (calle === "") error.calle = "Campo obligatorio";

    if (numero === "") error.numero = "Campo obligatorio";

    if (comuna === "") error.comuna = "Campo obligatorio";

    if (ciudad === "") error.ciudad = "Campo obligatorio";

    if (telefono === "") error.telefono = "Campo obligatorio";

    if (region === "") error.region = "Campo obligatorio";

    if (email === "") error.email = "Campo obligatorio";
    else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      error.email = "Mail con formato inválido";
    }
    this.setState({ error: error });

    if (
      error.calle ||
      error.numero ||
      error.comuna ||
      error.ciudad ||
      error.telefono ||
      error.region ||
      error.email
    ) {
      return false;
    }
    return true;
  };

  render() {
    const {
      datos,
      sucursal,
      guardando,
      cargando,
      cargandoSucursal,
      comunas,
      regiones
    } = this.props;
    const {
      editando,
      error,
      calle,
      comuna,
      numero,
      ciudad,
      depto,
      telefono,
      block,
      fax,
      villa,
      casilla,
      region,
      email
    } = this.state;

    return (
      <React.Fragment>
        <div className="row justify-content-left">
          <div className="col-md-12">
            <h2>Datos Razón Social y Representante Legal</h2>
          </div>
        </div>
        <div className="row justify-content-left">
          <div className="col-md-12">
            {datos && datos.length > 0 && datos[0] && (
              <Table className="tableEmpleador">
                <tbody>
                  <tr>
                    <td className="titulosTabla">RUT</td>
                    <td>
                      {this.props.datosSession &&
                        this.props.datosSession.rutEmpresa}
                    </td>
                  </tr>
                  <tr>
                    <td className="titulosTabla">Razón Social</td>
                    <td>{datos[0].EMPL_TNOMBRE_RAZON_SOCIAL}</td>
                  </tr>
                  <tr>
                    <td className="titulosTabla">RUT Rep. Legal</td>
                    <td>{datos[0].EMPL_NRUT_REPR_LEGAL}</td>
                  </tr>
                  <tr>
                    <td className="titulosTabla">Nombre Rep. Legal</td>
                    <td>{datos[0].NOMBRE_REPRESENTANTE}</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </div>
        <div className="row justify-content-left h2">
          <div className="col-md-12">
            <p>Casa matriz (Lugar de Habilitación FUN)</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            {cargandoSucursal && (
              <Spinner
                className="m-l"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
        <div className="row justify-content-left">
          <div className="col-md-12">
            {!cargandoSucursal && (
              <Table className="tableEmpleador">
                <tbody>
                  <tr>
                    <td className="titulosTabla-horizontal">Calle</td>
                    <td>
                      {editando ? (
                        <Form.Group className="tableEditando-chica">
                          <InputGroup className="input-addon" size="sm">
                            <Form.Control
                              required
                              className="tableEditando-chica"
                              id="calle"
                              value={this.state.calle}
                              onChange={this.handleOnChange}
                            />
                            <Form.Control.Feedback
                              className="displayError"
                              type="invalid"
                            >
                              {error.calle}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      ) :
                        sucursal ? sucursal.CEPA_TCALLE : ''
                      }
                    </td>
                    <td className="titulosTabla">Región</td>
                    <td>
                      {editando ? (
                        <Form.Group className="tableEditando-chica">
                          <Form.Control
                            as="select"
                            required
                            className="tableEditando-chica"
                            id="region"
                            value={this.state.region}
                            onChange={this.handleOnChange}
                          >
                            {regiones &&
                              regiones.map(v => (
                                <option key={v.numero} value={v.numero}>{v.nombre}</option>
                              ))}
                          </Form.Control>
                          <Form.Control.Feedback
                            className="displayError"
                            type="invalid"
                          >
                            {error.region}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) :
                        sucursal ? this.getNameRegion(sucursal.CEPA_NREGION) : ''
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="titulosTabla">Número</td>
                    <td>
                      {editando ? (
                        <Form.Group className="tableEditando-chica">
                          <InputGroup className="input-addon" size="sm">
                            <Form.Control
                              required
                              className="tableEditando-chica"
                              id="numero"
                              value={this.state.numero}
                              onChange={this.handleOnChange}
                            />
                            <Form.Control.Feedback
                              className="displayError"
                              type="invalid"
                            >
                              {error.numero}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      ) :
                        sucursal ? sucursal.CEPA_TNUMERO : 'S/N'
                      }
                    </td>
                    <td className="titulosTabla">Ciudad</td>
                    <td>
                      {editando ? (
                        <Form.Group className="tableEditando-chica">
                          <Form.Control
                            as="select"
                            required
                            className="tableEditando-chica"
                            id="ciudad"
                            value={this.state.ciudad}
                            onChange={this.handleOnChange}
                          >
                            {this.state.region &&
                              comunas &&
                              _.uniqBy(
                                comunas.filter(
                                  f =>
                                    f.region === parseInt(this.state.region)
                                ),
                                "nombreCiudad"
                              ).map(v => (
                                <option
                                  key={v.nombreCiudad}
                                  value={String(v.nombreCiudad).toUpperCase()}
                                >
                                  {String(v.nombreCiudad).toUpperCase()}
                                </option>
                              ))}
                          </Form.Control>
                          <Form.Control.Feedback
                            className="displayError"
                            type="invalid"
                          >
                            {error.ciudad}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) :
                        sucursal ? sucursal.LOCA_TNOMBRE_CIUDAD : ''
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="titulosTabla">Departamento</td>
                    <td>
                      {editando ? (
                        <Form.Group className="tableEditando-chica">
                          <Form.Control
                            className="tableEditando-chica"
                            id="depto"
                            value={this.state.depto}
                            onChange={this.handleOnChange}
                          />
                        </Form.Group>
                      ) :
                        sucursal ? sucursal.CEPA_TDEPTO : ''
                      }
                    </td>
                    <td className="titulosTabla">Comuna</td>
                    <td>
                      {editando ? (
                        <Form.Group className="tableEditando-chica">
                          <Form.Control
                            as="select"
                            required
                            className="tableEditando-chica"
                            id="comuna"
                            value={this.state.comuna}
                            onChange={this.handleOnChange}
                          >
                            {this.state.region &&
                              this.state.ciudad &&
                              comunas &&
                              _.uniqBy(
                                comunas.filter(
                                  f =>
                                    String(f.nombreCiudad).toUpperCase() ===
                                    String(this.state.ciudad).toUpperCase()
                                ),
                                "nombreComuna"
                              ).map(v => (
                                <option
                                  key={v.localidad}
                                  value={v.localidad}>
                                  {String(v.nombreComuna).toUpperCase()}
                                </option>
                              ))}
                          </Form.Control>
                          <Form.Control.Feedback
                            className="displayError"
                            type="invalid"
                          >
                            {error.comuna}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) :
                        // (
                        //     comunas &&
                        //     comunas.find(
                        //       f => f.localidad === this.state.comuna && f.region === this.state.region
                        //     ) &&
                        //     String(
                        //       comunas.find(
                        //         f =>
                        //           parseInt(f.localidad) ===
                        //           parseInt(this.state.comuna)
                        //       ).nombreComuna
                        //     ).toUpperCase()
                        //   )
                        sucursal ? this.getNameComuna(sucursal.CEPA_NREGION, sucursal.CODIGO_COMUNA) : ''
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="titulosTabla">Block</td>
                    <td>
                      {editando ? (
                        <Form.Group className="tableEditando-chica">
                          <Form.Control
                            className="tableEditando-chica"
                            id="block"
                            value={this.state.block}
                            onChange={this.handleOnChange}
                          />
                        </Form.Group>
                      ) :
                        sucursal ? sucursal.CEPA_TBLOCK : ''
                      }
                    </td>
                    <td className="titulosTabla">Celular</td>
                    <td>
                      {editando ? (
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              className="tableEditando-chica m-t-5"
                              id="inputGroupPrepend"
                            >
                              + 56
                              </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Group className="tableEditando-chica">
                            <Form.Control
                              className="tableEditando-chica"
                              id="fax"
                              value={this.state.fax}
                              onChange={this.handleOnChange}
                            />
                          </Form.Group>
                        </InputGroup>
                      ) : sucursal ? (sucursal.CEPA_TFAX !== ' ' ? '+56 ' + sucursal.CEPA_TFAX : '') : ''}
                    </td>
                  </tr>
                  <tr>
                    <td className="titulosTabla">Villa</td>
                    <td>
                      {editando ? (
                        <Form.Group className="tableEditando-chica">
                          <Form.Control
                            className="tableEditando-chica"
                            id="villa"
                            value={this.state.villa}
                            onChange={this.handleOnChange}
                          />
                        </Form.Group>
                      ) : sucursal ? sucursal.CEPA_TVILLA : ''
                      }
                    </td>

                    <td className="titulosTabla">Teléfono</td>
                    <td>
                      {editando ? (
                        <InputGroup>
                          <Form.Group className="tableEditando-chica">
                            <Form.Control
                              className="tableEditando-chica"
                              id="telefono"
                              value={this.state.telefono}
                              onChange={this.handleOnChange}
                            />
                            <Form.Control.Feedback
                              className="displayError"
                              type="invalid"
                            >
                              {error.telefono}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </InputGroup>
                      ) : sucursal ? sucursal.CEPA_TFONO : ''}
                    </td>
                  </tr>
                  <tr>
                    <td className="titulosTabla">Casilla</td>
                    <td>
                      {editando ? (
                        <Form.Group className="tableEditando-chica">
                          <Form.Control
                            className="tableEditando-chica"
                            id="casilla"
                            value={this.state.casilla}
                            onChange={this.handleOnChange}
                          />
                        </Form.Group>
                      ) :
                        sucursal ? sucursal.CEPA_TCASILLA : ''
                      }
                    </td>
                    <td className="titulosTabla">E-mail</td>
                    <td>
                      {editando ? (
                        <Form.Group className="tableEditando-chica">
                          <InputGroup className="input-addon" size="sm">
                            <Form.Control
                              required
                              className="tableEditando-chica"
                              id="email"
                              value={this.state.email}
                              onChange={this.handleOnChange}
                            />
                            <Form.Control.Feedback
                              className="displayError"
                              type="invalid"
                            >
                              {error.email}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      ) :
                        sucursal ? sucursal.CEPA_TCORREO : ''
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </div>
        <div className="row justify-content-start">
          {!editando && !cargando && (
            <Button
              id="actualizar"
              variant={
                this.props.datosSession &&
                  this.props.datosSession.frontOrigin === "B"
                  ? "danger"
                  : "success"
              }
              className="m-l"
              onClick={this.toggleEditar}
              disabled={editando}
            >
              Actualizar datos
            </Button>
          )}
          {editando && (
            <Button
              id="cancelar"
              variant={
                this.props.datosSession &&
                  this.props.datosSession.frontOrigin === "B"
                  ? "danger"
                  : "success"
              }
              className="m-l"
              onClick={this.cancelarEditar}
            >
              Cancelar
            </Button>
          )}
          {editando && (
            <Button
              id="guardar"
              variant={
                this.props.datosSession &&
                  this.props.datosSession.frontOrigin === "B"
                  ? "danger"
                  : "success"
              }
              className="m-l"
              onClick={this.actualizarDatos}
            >
              Guardar
              {guardando && (
                <Spinner
                  className="m-l"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  datos: state.datosEmpleador.datos,
  datosSession: state.main.datosSession,
  sucursal: state.datosEmpleador.sucursal,
  guardando: state.datosEmpleador.guardando,
  regiones: state.datosEmpleador.regiones,
  comunas: state.datosEmpleador.comunas,
  cargando: state.datosEmpleador.cargando,
  cargandoSucursal: state.datosEmpleador.cargandoSucursal
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatosEmpleador);
