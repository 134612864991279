const data = [
    {
      "nombre_afiliado": "Marquita Waller",
      "rut": '178788065',
      "periodo": "2019-04",
      "monto_pactado": 64150,
      "monto_pagado": 6415,
      "deuda_capital": 57735
    },
    {
      "nombre_afiliado": "Shaw Robbins",
      "rut": '101788201',
      "periodo": "2019-01",
      "monto_pactado": 162202,
      "monto_pagado": 64880.8,
      "deuda_capital": 97321.2
    },
    {
      "nombre_afiliado": "Douglas Houston",
      "rut": '147389557',
      "periodo": "2019-04",
      "monto_pactado": 104581,
      "monto_pagado": 52290.5,
      "deuda_capital": 52290.5
    },
    {
      "nombre_afiliado": "Tanya Ochoa",
      "rut": '133940488',
      "periodo": "2019-04",
      "monto_pactado": 78862,
      "monto_pagado": 31544.800000000003,
      "deuda_capital": 47317.2
    },
    {
      "nombre_afiliado": "Kathy Bennett",
      "rut": '172010809',
      "periodo": "2019-03",
      "monto_pactado": 66684,
      "monto_pagado": 13336.800000000001,
      "deuda_capital": 53347.2
    },
    {
      "nombre_afiliado": "Cecelia May",
      "rut": '145928067',
      "periodo": "2019-04",
      "monto_pactado": 86987,
      "monto_pagado": 17397.4,
      "deuda_capital": 69589.6
    },
    {
      "nombre_afiliado": "Bowers Mathis",
      "rut": '133113911',
      "periodo": "2019-01",
      "monto_pactado": 136806,
      "monto_pagado": 68403,
      "deuda_capital": 68403
    },
    {
      "nombre_afiliado": "Dodson Levine",
      "rut": '155470519',
      "periodo": "2019-02",
      "monto_pactado": 88598,
      "monto_pagado": 35439.200000000004,
      "deuda_capital": 53158.799999999996
    },
    {
      "nombre_afiliado": "Tessa Holden",
      "rut": '195629491',
      "periodo": "2019-02",
      "monto_pactado": 77279,
      "monto_pagado": 30911.600000000002,
      "deuda_capital": 46367.399999999994
    },
    {
      "nombre_afiliado": "Christina Buckley",
      "rut": '100855329',
      "periodo": "2019-03",
      "monto_pactado": 166824,
      "monto_pagado": 16682.4,
      "deuda_capital": 150141.6
    },
    {
      "nombre_afiliado": "Bender Graves",
      "rut": '158818068',
      "periodo": "2019-01",
      "monto_pactado": 56081,
      "monto_pagado": 5608.1,
      "deuda_capital": 50472.9
    },
    {
      "nombre_afiliado": "Latoya Burton",
      "rut": '136174467',
      "periodo": "2019-03",
      "monto_pactado": 115270,
      "monto_pagado": 57635,
      "deuda_capital": 57635
    },
    {
      "nombre_afiliado": "Ines Sanders",
      "rut": '176388244',
      "periodo": "2019-02",
      "monto_pactado": 141798,
      "monto_pagado": 28359.600000000002,
      "deuda_capital": 113438.4
    },
    {
      "nombre_afiliado": "Amber Carpenter",
      "rut": '182840849',
      "periodo": "2019-03",
      "monto_pactado": 174055,
      "monto_pagado": 34811,
      "deuda_capital": 139244
    },
    {
      "nombre_afiliado": "Calderon Rogers",
      "rut": '118695603',
      "periodo": "2019-02",
      "monto_pactado": 66846,
      "monto_pagado": 0,
      "deuda_capital": 66846
    },
    {
      "nombre_afiliado": "Wooten Mooney",
      "rut": '128319223',
      "periodo": "2019-02",
      "monto_pactado": 168308,
      "monto_pagado": 84154,
      "deuda_capital": 84154
    },
    {
      "nombre_afiliado": "Julianne Petty",
      "rut": '180861971',
      "periodo": "2019-01",
      "monto_pactado": 166281,
      "monto_pagado": 83140.5,
      "deuda_capital": 83140.5
    },
    {
      "nombre_afiliado": "Mosley Guerra",
      "rut": '179291255',
      "periodo": "2019-03",
      "monto_pactado": 148602,
      "monto_pagado": 59440.8,
      "deuda_capital": 89161.2
    },
    {
      "nombre_afiliado": "Mayra Boone",
      "rut": '138943516',
      "periodo": "2019-02",
      "monto_pactado": 149699,
      "monto_pagado": 59879.600000000006,
      "deuda_capital": 89819.4
    },
    {
      "nombre_afiliado": "Gates Young",
      "rut": '131135498',
      "periodo": "2019-04",
      "monto_pactado": 91380,
      "monto_pagado": 9138,
      "deuda_capital": 82242
    }
  ];

  export default data;