const DescargaCertificadosState = {
    detalleDeuda: [],
    messagesuccess: false,
    cargandoTabla: false,
    generandoDeuda: false,
    descargaCotizaciones: false,
    descargaError: "",
    internalerror: {internalerror:false,msg:null},
}

export default DescargaCertificadosState;
