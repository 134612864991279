const datosEmpleadorState = {
    datos: null,
    messagesuccess: false,
    surcursal: null,
    guardando: false,
    cargando: false,
    comunas: [],
    regiones: [],
}

export default datosEmpleadorState;
