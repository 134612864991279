import {
  MOVIMIENTO_PERSONAL_CALCULANDO,
  MOVIMIENTO_PERSONAL_IMPRIMIENDO,
  GET_DATOS_EMPLEADOR,
  GET_DATOS_AFILIADO,
  MOVIMIENTO_PERSONAL_MENSAJE,
  MOVIMIENTO_PERSONAL_CARGANDO_EMPLEADOR,
  MOVIMIENTO_PERSONAL_CARGANDO_AFILIADO,
  MOVIMIENTO_PERSONAL_MENSAJE_CARGANDO_AFILIADO,
  MOVIMIENTO_PERSONAL_GUARDANDO
} from "../../../config/types";
import MovimientoPersonalState from "./state";

export default function(state = MovimientoPersonalState, action) {
  switch (action.type) {
    case MOVIMIENTO_PERSONAL_CALCULANDO:
      return Object.assign({}, state, {
        calculando: action.payload
      });
    case MOVIMIENTO_PERSONAL_IMPRIMIENDO:
      return Object.assign({}, state, {
        imprimiendo: action.payload
      });
      case MOVIMIENTO_PERSONAL_GUARDANDO:
        return Object.assign({}, state, {
          guardando: action.payload
        });
    case GET_DATOS_EMPLEADOR:
      return Object.assign({}, state, {
        datos: action.payload
      });
    case GET_DATOS_AFILIADO:
      return Object.assign({}, state, {
        datosAfiliado: action.payload
      });
    case MOVIMIENTO_PERSONAL_MENSAJE:
      return Object.assign({}, state, {
        mensaje: action.payload
      });
    case MOVIMIENTO_PERSONAL_CARGANDO_EMPLEADOR:
      return Object.assign({}, state, {
        cargandoEmpleador: action.payload
      });
    case MOVIMIENTO_PERSONAL_CARGANDO_AFILIADO:
      return Object.assign({}, state, {
        cargandoAfiliado: action.payload
      });
    case MOVIMIENTO_PERSONAL_MENSAJE_CARGANDO_AFILIADO:
      return Object.assign({}, state, {
        mensajeCargandoAfiliado: action.payload
      });
    default:
      return state;
  }
}
