import {
   GET_DEUDA_EMPRESA,
   CARGANDO_DEUDA_EMPRESA, DEUDA_EMPRESA_EXPORTANDO_EXCEL, GET_DETALLE_DEUDA, INTERNAL_ERROR
} from "../../config/types";
import axios from "axios";
import uriConfig from "../../config/api";
import store from "../../store";
import data from "../../helpers/data";

export const cargandoDatos = params => async dispatch => {
   dispatch({
      type: CARGANDO_DEUDA_EMPRESA,
      payload: params
   });
};

export const internalerror = (value,msg) => async dispatch => {
    dispatch({
        type: INTERNAL_ERROR,
        payload: {internalerror:value,msg:msg}
    });
};

export const getDeudaEmpresa = (fecha) => async dispatch => {
   dispatch(cargandoDatos(true));
   const { main } = store.getState();
   axios
      .get(`${uriConfig.apiDeudaEmpresas.getDeudaEmpresa(fecha)}`)
      .then(res => {
         if (res.status === 200) {
            dispatch({
               type: GET_DEUDA_EMPRESA,
               payload: res.data.rows
            });
         } else {
            dispatch({
               type: GET_DEUDA_EMPRESA,
               payload: []
            });
         }
         dispatch(cargandoDatos(false));
      })
      .catch(error => {
         console.log("error", error.code);
         dispatch(cargandoDatos(false));
          if (error.response.status === 408) {
              dispatch(internalerror(true,error.response.data.msg));
          } else {
              dispatch(internalerror(true,"Error desconocido"));
          }
      });
};

export const exportarExcelDeudaEmpresa = (fecha) => async dispatch => {
   const { main } = store.getState();
   dispatch({
      type: DEUDA_EMPRESA_EXPORTANDO_EXCEL,
      payload: true
   });
   axios.get(`${uriConfig.apiDeudaEmpresas.getExportarExcelDeudaEmpresa(fecha)}`, { responseType: 'blob' })
       .then((res) => {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `Deuda_empresa_${main.datosSession.rutEmpresa}.xlsx`);
              document.body.appendChild(link);
              link.click();
              dispatch({
                 type: DEUDA_EMPRESA_EXPORTANDO_EXCEL,
                 payload: false
              });
           }
       ).catch((error) => {
            console.log("error", error);
            dispatch({
                type: DEUDA_EMPRESA_EXPORTANDO_EXCEL,
                payload: false
            });
           if (error.response.status === 408) {
               dispatch(internalerror(true,'El servicio se ha demorado bastante en responder'));
           } else {
               dispatch(internalerror(true,"Error desconocido"));
           }
       }
   )

};

