import {
    GET_DETALLE_DEUDA,
    DETALLE_DEUDA_CARGANDO_TABLA,
    DETALLE_DEUDA_EXPORTANDO_EXCEL,
    INTERNAL_ERROR
} from '../../../config/types'
import detalleDeudaState from './state'

export default function (state = detalleDeudaState, action) {
    switch(action.type){
        case GET_DETALLE_DEUDA:
            return Object.assign({}, state, {
                detalleDeuda: action.payload,
        
            });     
        case DETALLE_DEUDA_CARGANDO_TABLA:
            return Object.assign({}, state, {
                cargandoTabla: action.payload,         
                });       
        case DETALLE_DEUDA_EXPORTANDO_EXCEL:
            return Object.assign({}, state, {
                exportandoExcel: action.payload,         
                });
        case INTERNAL_ERROR:
            return Object.assign({}, state, {
                internalerror: action.payload,
            });
        default:
            return state
    }
}
