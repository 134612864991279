export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const HIDE_SUCCESS_MESSAGE = 'HIDE_SUCCESS_MESSAGE';
export const GET_DETALLE_DEUDA = 'GET_DETALLE_DEUDA';
export const GET_CALCULO_SIETE = 'GET_CALCULO_SIETE';
export const SET_CALCULO_FINAL_SUMA = 'SET_CALCULO_FINAL_SUMA';
export const SET_CALCULO_FINAL_RESTA = 'SET_CALCULO_FINAL_RESTA';
export const GET_HISTORIAL_PAGO = 'GET_HISTORIAL_PAGO';
export const GET_DETALLE_PAGO = 'GET_DETALLE_PAGO';
export const HISTORIAL_PAGO_CARGANDO_TABLA = 'HISTORIAL_PAGO_CARGANDO_TABLA';
export const GET_DATA_TOKEN = 'GET_DATA_TOKEN';
export const GET_DATOS_EMPLEADOR = 'GET_DATOS_EMPLEADOR';
export const GET_DATOS_SUCURSAL = 'GET_DATOS_SUCURSAL';
export const DETALLE_DEUDA_CARGANDO_TABLA = 'DETALLE_DEUDA_CARGANDO_TABLA'
export const DETALLE_DEUDA_EXPORTANDO_EXCEL = 'DETALLE_DEUDA_EXPORTANDO_EXCEL'
export const DEUDA_EMPRESA_EXPORTANDO_EXCEL = 'DEUDA_EMPRESA_EXPORTANDO_EXCEL'
export const INTERNAL_ERROR = 'INTERNAL_ERROR'
export const DATOS_EMPLEADOR_GUARDANDO = 'DATOS_EMPLEADOR_GUARDANDO'
export const DATOS_EMPLEADOR_CARGANDO = 'DATOS_EMPLEADOR_CARGANDO'
export const DATOS_SUCURSAL_CARGANDO = 'DATOS_SUCURSAL_CARGANDO'
export const CERTIFICADOS_GENERANDO_DEUDA = 'CERTIFICADOS_GENERANDO_DEUDA'
export const CERTIFICADOS_GENERANDO_ACLARACION = 'CERTIFICADOS_GENERANDO_ACLARACION'
export const CERTIFICADOS_GENERANDO_ACLARACION_MENSAJE_OK = 'CERTIFICADOS_GENERANDO_ACLARACION_MENSAJE_OK'
export const GET_DATOS_CONTACTO = 'GET_DATOS_CONTACTO'
export const DATOS_CONTACTO_CARGANDO = 'DATOS_CONTACTO_CARGANDO'
export const DATOS_CONTACTO_GUARDANDO = 'DATOS_CONTACTO_GUARDANDO'
export const MOVIMIENTO_PERSONAL_CALCULANDO = 'MOVIMIENTO_PERSONAL_CALCULANDO'
export const MOVIMIENTO_PERSONAL_IMPRIMIENDO = 'MOVIMIENTO_PERSONAL_IMPRIMIENDO'
export const MOVIMIENTO_PERSONAL_GUARDAR = 'MOVIMIENTO_PERSONAL_GUARDAR'
export const MOVIMIENTO_PERSONAL_MENSAJE = 'MOVIMIENTO_PERSONAL_MENSAJE'
export const CERTIFICADOS_DESCARGA_COTIZACIONES = 'CERTIFICADOS_DESCARGA_COTIZACIONES'
export const CERTIFICADOS_DESCARGA_COTIZACIONES_ERROR = 'CERTIFICADOS_DESCARGA_COTIZACIONES_ERROR'
export const CALCULO_INTERESES_CALCULANDO = 'CALCULO_INTERESES_CALCULANDO'
export const CALCULO_INTERESES_IMPRIMIENDO = 'CALCULO_INTERESES_IMPRIMIENDO'
export const CALCULO_INTERESES_GUARDAR = 'CALCULO_INTERESES_GUARDAR'
export const CALCULO_INTERESES_MENSAJE = 'CALCULO_INTERESES_MENSAJE'
export const MOVIMIENTO_PERSONAL_CARGANDO_EMPLEADOR = 'MOVIMIENTO_PERSONAL_CARGANDO_EMPLEADOR'
export const DATOS_CONTACTO_MENSAJE_ERROR = 'DATOS_CONTACTO_MENSAJE_ERROR'
export const MOVIMIENTO_PERSONAL_CARGANDO_AFILIADO = 'MOVIMIENTO_PERSONAL_CARGANDO_AFILIADO'
export const MOVIMIENTO_PERSONAL_MENSAJE_CARGANDO_AFILIADO = 'MOVIMIENTO_PERSONAL_MENSAJE_CARGANDO_AFILIADO'
export const GET_DATOS_AFILIADO = 'GET_DATOS_AFILIADO'
export const PAGO_REDIRECT = 'PAGO_REDIRECT'
export const PAGO_EXITOSO_DETALLE = 'PAGO_EXITOSO_DETALLE'
export const PAGO_EXITOSO_CARGANDO_TABLA = 'PAGO_EXITOSO_CARGANDO_TABLA'
export const PAGO_COTIZACIONES_PAGANDO = 'PAGO_COTIZACIONES_PAGANDO'
export const MOVIMIENTO_PERSONAL_GUARDANDO = 'MOVIMIENTO_PERSONAL_GUARDANDO'
export const GET_LEY_URGENCIA = 'GET_LEY_URGENCIA'
export const LEYURGENCIA_CARGANDO_TABLA = 'LEYURGENCIA_CARGANDO_TABLA'
export const PAGO_EXITOSO_DESCARGANDO = 'PAGO_EXITOSO_DESCARGANDO'
export const GET_DATOS_REGIONES = 'GET_DATOS_REGIONES'
export const GET_DATOS_COMUNAS = 'GET_DATOS_COMUNAS'

export const GET_DEUDA_EMPRESA = 'GET_DEUDA_EMPRESA';
export const CARGANDO_DEUDA_EMPRESA = 'CARGANDO_DEUDA_EMPRESA';
