import {
  GET_DATOS_CONTACTO,
  GET_DATOS_SUCURSAL,
  DATOS_CONTACTO_CARGANDO,
  DATOS_CONTACTO_GUARDANDO,
  DATOS_CONTACTO_MENSAJE_ERROR
} from "../../../config/types";
import DatosContactoState from "./state";

export default function(state = DatosContactoState, action) {
  switch (action.type) {
    case GET_DATOS_CONTACTO:
      return Object.assign({}, state, {
        datos: action.payload
      });
    case GET_DATOS_SUCURSAL:
      return Object.assign({}, state, {
        sucursal: action.payload
      });
    case DATOS_CONTACTO_GUARDANDO:
      return Object.assign({}, state, {
        guardando: action.payload
      });
    case DATOS_CONTACTO_CARGANDO:
      return Object.assign({}, state, {
        cargando: action.payload
      });
    case DATOS_CONTACTO_MENSAJE_ERROR:
      return Object.assign({}, state, {
        mensajeError: action.payload
      });
    default:
      return state;
  }
}
