import {
  CERTIFICADOS_GENERANDO_DEUDA,
  CERTIFICADOS_GENERANDO_ACLARACION,
  CERTIFICADOS_GENERANDO_ACLARACION_MENSAJE_OK,
  CERTIFICADOS_DESCARGA_COTIZACIONES,
  CERTIFICADOS_DESCARGA_COTIZACIONES_ERROR, INTERNAL_ERROR
} from "../../../config/types";
import axios from "axios";
import uriConfig from "../../../config/api";
import store from "../../../store";
import {toastr} from 'react-redux-toastr'

export const generandoDeuda = value => async dispatch => {
  dispatch({
    type: CERTIFICADOS_GENERANDO_DEUDA,
    payload: value
  });
};

export const generandoAclaracion = value => async dispatch => {
  dispatch({
    type: CERTIFICADOS_GENERANDO_ACLARACION,
    payload: value
  });
};

export const mensajeOkAclaracion = value => async dispatch => {
  dispatch({
    type: CERTIFICADOS_GENERANDO_ACLARACION_MENSAJE_OK,
    payload: value
  });
};

export const mensajeErrorCotizaciones = value => async dispatch => {
  dispatch({
    type: CERTIFICADOS_DESCARGA_COTIZACIONES_ERROR,
    payload: value
  });
};

export const descargaCotizaciones = value => async dispatch => {
  dispatch({
    type: CERTIFICADOS_DESCARGA_COTIZACIONES,
    payload: value
  });
};

export const internalerror = (value,msg) => async dispatch => {
  dispatch({
    type: INTERNAL_ERROR,
    payload: {internalerror:value,msg:msg}
  });
};

export const generarCertificadoDeuda = params => async dispatch => {
  const { main } = store.getState();
  dispatch(generandoDeuda(true));
  axios
    .post(
      `${uriConfig.apiCertificados.postGenerarDeuda}`,
      { dnp: params.checkDNP, nodnp: params.checkNODNP, tipoDeuda: params.tipoDeuda },
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf"
        }
      }
    )
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Detalle_deuda_${main.datosSession.rutEmpresa}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      dispatch(generandoDeuda(false));
    })
    .catch(error => {
      console.log("error ",error);
      dispatch(generandoDeuda(false));
      if (error.response.status === 408) {
        dispatch(internalerror(true,'El servicio se ha demorado bastante en responder'));
      } else {
        dispatch(internalerror(true,"Error desconocido"));
      }
    });
};

export const generarAclaracion = params => async dispatch => {
  const { main } = store.getState();
  dispatch(mensajeOkAclaracion(""));
  dispatch(generandoAclaracion(true));
  axios
    .post(`${uriConfig.apiCertificados.postBoletinLaboral}`, {
      emplNrut: main.datosSession.rutEmpresa,
      wuemNrut: main.datosSession.rutUsuario,
      isapCempresa: main.datosSession.frontOrigin,
      wuemEmail: params.email
    })
    .then(res => {
      dispatch(generandoAclaracion(false));
      dispatch(
        toastr.success("Respuesta","Solicitud enviada.")
      );
    })
    .catch(error => {
      dispatch(generandoAclaracion(false));
    });
};

export const descargarCotizaciones = params => async dispatch => {
  const { main } = store.getState();
  dispatch(descargaCotizaciones(true));
  dispatch(mensajeErrorCotizaciones(""));
  axios
    .post(`${uriConfig.apiCertificados.postCotizacionesPagadas}`, {
      P_EMPLEADOR: main.datosSession.rutEmpresa,
      P_RUT: parseInt(params.rut.split('-')[0].split('.').join('')),
      P_ISAPRE: main.datosSession.frontOrigin,
      P_DESDE: params.startDate,
      P_HASTA: params.startDate2,
      P_PERFIL: "WEB"
    },
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf"
      }
    })
    .then(res => {
      if(res.status === 404){
        dispatch(mensajeErrorCotizaciones("No se encontraron datos de cotizaciones pagadas."));
        dispatch(descargaCotizaciones(false));
      }
      else if(res.status===500){
        dispatch(mensajeErrorCotizaciones("Ha ocurrido un error. Favor intente nuevamente."));
        dispatch(descargaCotizaciones(false));
      }
      else if (res.status === 204) {
        dispatch(mensajeErrorCotizaciones("No hay datos"));
        dispatch(descargaCotizaciones(false));
      } else {
      dispatch(descargaCotizaciones(false));
      dispatch(mensajeErrorCotizaciones(""));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `CotizacionesPagadas_${main.datosSession.rutEmpresa}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      }
    })
    .catch(error => {
      console.log("error ", error);
      dispatch(descargaCotizaciones(false));
      //dispatch(mensajeErrorCotizaciones("No se encontraron datos de cotizaciones pagadas."));
      if (error.response.status === 408) {
        dispatch(internalerror(true,'El servicio se ha demorado bastante en responder'));
      } else {
        if(error.response.status === 404){
          dispatch(mensajeErrorCotizaciones("No se encontró el RUT de afiliado en la BD"));
          dispatch(descargaCotizaciones(false));
        } else{
          if (error.response.status === 204) {
            dispatch(mensajeErrorCotizaciones("No hay datos"));
            dispatch(descargaCotizaciones(false));
          } else {
            dispatch(internalerror(true,"Error desconocido"));
          }
        }
      }
    });
};
