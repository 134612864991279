import {
  CALCULO_INTERESES_CALCULANDO,
  CALCULO_INTERESES_IMPRIMIENDO,
  CALCULO_INTERESES_GUARDAR,
  CALCULO_INTERESES_MENSAJE
} from "../../../config/types";
import CalculoInteresesState from "./state";

export default function(state = CalculoInteresesState, action) {
  switch (action.type) {
    case CALCULO_INTERESES_CALCULANDO:
      return Object.assign({}, state, {
        calculando: action.payload
      });
    case CALCULO_INTERESES_IMPRIMIENDO:
      return Object.assign({}, state, {
        imprimiendo: action.payload
      });
    case CALCULO_INTERESES_GUARDAR:
      return Object.assign({}, state, {
        dataIntereses: action.payload
      });
    case CALCULO_INTERESES_MENSAJE:
      return Object.assign({}, state, {
        mensaje: action.payload
      });
    default:
      return state;
  }
}
