import {
  GET_DETALLE_DEUDA,
  DETALLE_DEUDA_EXPORTANDO_EXCEL,
  GET_DATA_TOKEN,
  PAGO_EXITOSO_DETALLE,
  PAGO_EXITOSO_CARGANDO_TABLA,
  PAGO_EXITOSO_DESCARGANDO, INTERNAL_ERROR
} from "../../config/types";
import axios from "axios";
import data from "../../helpers/data";
import uriConfig from "../../config/api";
import store from "../../store";
import {toastr} from "react-redux-toastr";

export const cargandoDatos = value => async dispatch => {
  dispatch({
    type: PAGO_EXITOSO_CARGANDO_TABLA,
    payload: value
  });
};
export const exportandoExcel = value => async dispatch => {
  dispatch({
    type: DETALLE_DEUDA_EXPORTANDO_EXCEL,
    payload: value
  });
};
export const descargando = value => async dispatch => {
  dispatch({
    type: PAGO_EXITOSO_DESCARGANDO,
    payload: value
  });
};
export const internalerror = (value,msg) => async dispatch => {
  dispatch({
    type: INTERNAL_ERROR,
    payload: {internalerror:value,msg:msg}
  });
};
export const getInformacionPago = () => async dispatch => {
  dispatch(cargandoDatos(true));
  axios
    .post(`${uriConfig.apiCotizaciones.postObtenerInformacionPago}`, {
      idPago: localStorage.getItem("pagoId")
    })
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: PAGO_EXITOSO_DETALLE,
          payload: res.data.rows
        });
        dispatch(cargandoDatos(false));
      }
      dispatch(cargandoDatos(false));
    })
    .catch(error => {
      console.log("error", error);
      // dispatch({
      //   type: GET_DETALLE_DEUDA,
      //   payload: data
      // });
      dispatch(cargandoDatos(false));
      if (error.response.status === 408) {
        dispatch(internalerror(true,error.response.data.msg));
      } else {
        dispatch(internalerror(true,"Error desconocido"));
      }
    });
};
export const getInformacionPagoMDPG = () => async dispatch => {
  dispatch(cargandoDatos(true));
  axios
    .post(`${uriConfig.apiCotizaciones.postObtenerInformacionPagoMDPG}`, {
      idPago: localStorage.getItem("pagoId"),
      tokenPago: localStorage.getItem("tokenPago")
    })
    .then(res => {
      if (res.status === 200) {
        console.log("res.data ",res.data);
        dispatch({
          type: PAGO_EXITOSO_DETALLE,
          payload: res.data
        });
        dispatch(cargandoDatos(false));
      }
      dispatch(cargandoDatos(false));
    })
    .catch(error => {
      console.log("error", error);
      // dispatch({
      //   type: GET_DETALLE_DEUDA,
      //   payload: data
      // });
      dispatch(cargandoDatos(false));
      if (error.response.status === 408) {
        dispatch(internalerror(true,error.response.data.msg));
      } else {
        dispatch(internalerror(true,"Error desconocido"));
      }
    });
};
export const getInformacionPagoLeyUrgencia = () => async dispatch => {
  dispatch(cargandoDatos(true));
  axios
    .post(
      `${uriConfig.apiCotizaciones.postObtenerInformacionPagoLeyUrgencia}`,
      {
        idPago: localStorage.getItem("pagoId")
      }
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: PAGO_EXITOSO_DETALLE,
          payload: res.data.rows
        });
        dispatch(cargandoDatos(false));
      }
      dispatch(cargandoDatos(false));
    })
    .catch(error => {
      console.log("error", error.code);
      // dispatch({
      //   type: GET_DETALLE_DEUDA,
      //   payload: data
      // });
      dispatch(cargandoDatos(false));
    });
};

//Planilla Bancobro
export const descargarPlanillaBancobro = params => async dispatch => {
  dispatch(cargandoDatos(true));
    axios
      .get(`${uriConfig.apiCotizaciones.getObtenerPlanillasPago}/${params}`)
      .then(res => {
        const link = document.createElement("a");
        link.href = 'data:application/octet-stream;base64,' + res.data.planillas;
        link.download = 'Comprobante_Bancobro.pdf';
        document.body.appendChild(link);
        link.click();
        dispatch(cargandoDatos(false));
      })
      .catch(error => {
        console.log("error", error);
        dispatch(cargandoDatos(false));
        dispatch(
            toastr.error("Respuesta","Error, favor intentar nuevamente")
        );
      });
};
//ley de urgencia
export const descargarComprobante = params => async dispatch => {
  dispatch(descargando(true));
  const { main } = store.getState();

  axios
    .post(
      `${uriConfig.apiCotizaciones.postObtenerComprobantePago}`,
      {
        idPago: localStorage.getItem("pagoId"),
        P_FECHA_PAGO: new Date(),
        P_MEDIO_PAGO: "Servipag",
        P_FOLIO: localStorage.getItem("pagoId")
      },
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf"
        }
      }
    )
    .then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `ComprobanteDesvinculacion${
            main.datosSession.rutEmpresa
          }-${new Date()}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        dispatch(descargando(false));
      }
      dispatch(descargando(false));
    })
    .catch(error => {
      console.log("error", error.code);
      dispatch(descargando(false));
    });
};

export const getDataToken = () => async dispatch => {
  axios
    .post(`${uriConfig.apiAuthorization.postTokenData}`, {
      token: localStorage.getItem("tokenApi")
    })
    .then(res => {
      if (res.status === 200) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("tokenApi");
        dispatch({
          type: GET_DATA_TOKEN,
          payload: res.data.data
        });
      }
    })
    .catch(error => {
      console.log("error", error.code);
    });
};
