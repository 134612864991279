import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getDeudaEmpresa,
    exportarExcelDeudaEmpresa
} from "./actions";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { formatearMiles, limpiarNumero, removeDotsFromString } from "../../helpers/util";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Error from "../error/view";


class DeudaEmpresa extends Component {

   constructor(props) {
      super(props);

      this.state = {
         datos: [],
         dataFormateada: [],
         total: 0,
         totalcopago: 0,
         mes: new Date().getMonth() < 10
            ? "0" + (new Date().getMonth() + 1).toString()
            : (new Date().getMonth() + 1).toString(),
         ano: new Date().getFullYear(),
         periodo: "",
         meses: [],
         anos: []
      }

      this.handleChange = this.handleChange.bind(this);
      this.handleOnChange = this.handleOnChange.bind(this);
      this.handleExportar = this.handleExportar.bind(this);
      this.handleBuscarPeriodo = this.handleBuscarPeriodo.bind(this);
   }

   componentWillMount() {
      const anos = this.generarAnos();
      //const meses = this.generarMeses(parseInt(this.state.ano));
      this.setState({
         mes:
            new Date().getMonth() < 10
               ? "0" + (new Date().getMonth()).toString()
               : (new Date().getMonth() + 1).toString(),
         anos,
         periodo: anos[0].split('/')[0] + anos[0].split('/')[1] + '01',
         ano: anos[0]
      });
      this.props.dispatch(getDeudaEmpresa(anos[0].split('/')[0] + anos[0].split('/')[1] + '01'));
   }

   componentWillReceiveProps(newProps) {
      if (newProps.datos !== null) {
         let totalcopago = 0;
         const dataFormateada = newProps.datos.map((x, ind) => {
            const row = {
               ID: ind,
               CHECK: false,
               PERIODO: x.PERIODO,
               //LINEA_CREDITO: x.LINEA_CREDITO,
               RUT_AFILIADO: x.RUT_AFILIADO,
               NOMBRE_COMPLETO: x.NOMBRE_COMPLETO,
               PRESTACION: formatearMiles(x.PRESTACION),
               BONIFICACION: formatearMiles(x.BONIFICACION),
               COPAGO: formatearMiles(x.COPAGO),
               TIPO_DOCUMENTO: x.TIPO_DOCUMENTO,
               FOLIO: x.FOLIO,
               FECHA_EMISION: x.FECHA_EMISION
            };
            totalcopago += x.COPAGO;
            return row
         });

         this.setState({ dataFormateada,totalcopago })
      } else {
         this.setState({ dataFormateada: [] });
      }
   }

   handleChange(e, row) {

      const total = !row.CHECK ? parseFloat(this.state.total + parseFloat(removeDotsFromString(row.COPAGO))) : parseFloat(this.state.total - parseFloat(removeDotsFromString(row.COPAGO)));

      const data = this.state.dataFormateada;

      data[row.ID].CHECK = !row.CHECK;

      this.setState({ dataFormateada: data, total });
   }

   handleExportar(){
      const periodo = this.state.periodo;
      this.props.dispatch(exportarExcelDeudaEmpresa(periodo));
   }

   generarAnos = () => {
      let temp = [];
      let fecha = new Date();
      let anio = fecha.getFullYear().toString();
      let finperiodo = 0;
      let j = 1;
      for (let i=1; i<13;i++){
         finperiodo = ('0' + (fecha.getMonth() - i + j)).slice(-2);
         if(finperiodo === '00'){
            j = 13;
            anio --;
            temp.push(anio + '/' + 12);
         } else {
            temp.push(anio + '/' + finperiodo);
         }
      }
      return temp;
   };

   // generarMeses = ano => {
   //    let i = 1;
   //    let temp = [];
   //    const maxAno =
   //       ano === new Date().getFullYear() ? new Date().getMonth() : 12;
   //    for (i; i <= maxAno; i++)
   //       temp.push(i < 10 ? "0" + i.toString() : i.toString());
   //    return temp;
   // };

   generarAllMeses() {
      let meses = [];
      for (let i = 1; i <= 12; i++) {
         meses.push(i < 10 ? "0" + i.toString() : i.toString())
      }
      return meses;
   }

   handleBuscarPeriodo() {
      const periodo = this.state.periodo;
      this.props.dispatch(getDeudaEmpresa(periodo));
   }


   handleOnChange(event) {
      const error = { email: null };
      const { id, value } = event.target;
      let periodo = value.split('/')[0] + value.split('/')[1] + '01';
      const arr = {};
      this.state['periodo'] = periodo;
      this.state['ano'] = value;
      arr['periodo'] =  this.state['periodo'];
      arr['ano'] =  this.state['ano'];
      this.setState(arr);
   }

   render() {
      const { detalleDeuda, exportandoExcelDeudaEmpresa, cargandoTabla, internalerror } = this.props;

      const columns = [
         {
            dataField: "PERIODO",
            text: "Periodo",
            classes: "",
            headerClasses: 'vertical-center'

         },
          /*
         {
            dataField: "LINEA_CREDITO",
            text: "LINEA_CREDITO",
            classes: "",
            headerClasses: 'vertical-center'
         },
         */
         {
            dataField: "RUT_AFILIADO",
            text: "RUT Afiliado",
            classes: 'text-center',
            headerClasses: 'text-center'
         },
         {
            dataField: "NOMBRE_COMPLETO",
            text: "Nombre",
            classes: 'text-center',
            headerClasses: 'text-center',
         },
         {
            dataField: "PRESTACION",
            text: "Prestación",
            classes: 'text-center ',
            headerClasses: 'text-center',
            formatter: (cell, row, rowIndex, formatExtraData) => `$ ${row.PRESTACION}`
         },
         {
            dataField: "BONIFICACION",
            text: "Bonificación",
            classes: 'text-center ',
            headerClasses: 'text-center',
            formatter: (cell, row, rowIndex, formatExtraData) => `$ ${row.BONIFICACION}`
         },
         {
            dataField: "COPAGO",
            text: "Copago",
            classes: 'text-center nowrap',
            headerClasses: 'text-center',
            formatter: (cell, row, rowIndex, formatExtraData) => `$ ${row.COPAGO}`
         },
         {
            dataField: "TIPO_DOCUMENTO",
            text: "Tipo Documento",
            classes: 'text-center ',
            headerClasses: 'text-center',
         },
         {
            dataField: "FOLIO",
            text: "Folio",
            classes: 'text-center nowrap',
            headerClasses: 'text-center',
         },
         {
            dataField: "FECHA_EMISION",
            text: "Fecha Emisión",
            classes: 'text-center ',
            headerClasses: 'text-center',
         },
          /*
         {
            dataField: "CHECK",
            text: "Sel",
            classes: 'text-center ',
            headerClasses: 'text-center',
            formatter: (cell, row, rowIndex, formatExtraData) => {
               return <Form.Check onChange={(e) => this.handleChange(e, row)} type="checkbox" label="" checked={row.CHECK} value={row.CHECK} />
            },
         },
           */

      ];

      if (internalerror.internalerror){
         return <Error msg={internalerror.msg}/> ;
      } else {
         return (
             <React.Fragment>
                <div className="row m-l m-t">
                   <div className="col-sm-12">
                      <h2>Deuda Cuenta Corriente</h2>
                      <div className="txt-bajada pb-2">
                         A continuación se despliega el filtrado de trabajadores que realizan pagos en su remuneración
                         por atenciones
                         canceladas con cuenta corriente
                      </div>
                      <p>Puedes filtrar por periodo</p>
                      <table className={
                         this.props.datosSession.frontOrigin === "B"
                             ? "form-periodo-banmedica"
                             : "form-periodo-tres"}>
                         <tbody>
                         <tr>
                            <td className={
                               this.props.datosSession.frontOrigin === "B"
                                   ? "label-periodo-banmedica"
                                   : "label-periodo-tres"}>Periodo
                            </td>
                            <td className="pl-3">
                               <Form.Control
                                   size="sm"
                                   id="ano"
                                   as="select"
                                   className="mr-3"
                                   onChange={this.handleOnChange}
                                   value={this.state.ano}
                               >
                                  {this.state.anos.map((value, index) => {
                                     return <option key={value}>{value}</option>;
                                  })}
                               </Form.Control>
                            </td>

                            <td>
                               <Button
                                   size="sm"
                                   id="calcularInteres"
                                   onClick={this.handleBuscarPeriodo}
                                   variant={
                                      this.props.datosSession &&
                                      this.props.datosSession.frontOrigin === "B"
                                          ? "danger"
                                          : "success"
                                   }

                               >
                                  Buscar
                               </Button>
                            </td>
                         </tr>
                         </tbody>
                      </table>
                      <table className={
                         this.props.datosSession.frontOrigin === "B"
                             ? "form-periodo-banmedica"
                             : "form-periodo-tres"}>
                         <tbody>
                         <tr>
                            <td className={
                               this.props.datosSession.frontOrigin === "B"
                                   ? "form-periodo-banmedica-td"
                                   : "form-periodo-tres-td"}>Total Copago</td>
                            <td className={
                               this.props.datosSession.frontOrigin === "B"
                                   ? "form-periodo-banmedica-td-res"
                                   : "form-periodo-tres-td-res"}>${formatearMiles(this.state.totalcopago)}</td>
                         </tr>
                         </tbody>
                      </table>
                      <div className="table-responsive">
                         <BootstrapTable
                             bootstrap4
                             keyField="ID"
                             className="flex-table tableDeudaPago"
                             data={this.state.dataFormateada}
                             columns={columns}
                             loading={this.props.loading}
                             overlay={overlayFactory({
                                spinner: <Spinner animation="border" size="sm"/>,
                                background: 'rgba(192,192,192,0.3)',
                                text: " Cargando..."
                             })}
                             hover
                             striped
                             noDataIndication="No hay datos disponibles"
                             bordered={false}
                             pagination={paginationFactory({
                                sizePerPage: 10,
                                showTotal: false, // display pagination information
                                withFirstAndLast: false, // hide the going to first and last page button
                                alwaysShowAllBtns: true, // always show the next and previous page button
                                firstPageText: 'Primero', // the text of first page button
                                prePageText: 'Anterior', // the text of previous page button
                                nextPageText: 'Siguiente', // the text of next page button
                                lastPageText: 'Último', // the text of last page button
                                nextPageTitle: 'Ir al siguiente', // the title of next page button
                                prePageTitle: 'Ir al anterior', // the title of previous page button
                                firstPageTitle: 'Ir al primero', // the title of first page button
                                lastPageTitle: 'Ir al último', // the title of last page button
                                hideSizePerPage: true, // hide the size per page dropdown
                                hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
                             })}
                         />
                      </div>
                      <div className="text-left">
                         <Button
                             size="sm"
                             id="exportar"
                             variant={
                                this.props.datosSession &&
                                this.props.datosSession.frontOrigin === "B"
                                    ? "danger"
                                    : "success"
                             }
                             onClick={this.handleExportar}
                             disabled={exportandoExcelDeudaEmpresa || this.state.dataFormateada.length === 0}
                         >
                            Exportar
                            {exportandoExcelDeudaEmpresa && (
                                <Spinner
                                    className="m-l"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                         </Button>
                      </div>
                   </div>
                </div>
             </React.Fragment>
         )
      }
   }
}

const mapStateToProps = state => ({
   datos: state.datosDeudaEmpresa.datos,
   loading: state.datosDeudaEmpresa.loading,
   exportandoExcelDeudaEmpresa: state.datosDeudaEmpresa.exportandoExcelDeudaEmpresa,
   datosSession: state.main.datosSession,
   internalerror: state.detalleDeuda.internalerror
});

const mapDispatchToProps = dispatch => ({
   dispatch: action => {
      dispatch(action);
   }
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(DeudaEmpresa);
