import React, { Component } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { formatearNumero } from "../../../helpers/util";
import { formatRut } from "../../../helpers/rut";
import { getDetalleDeuda, exportarExcel } from "./actions";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from 'react-bootstrap-table2-paginator';

const columns = [
  {
    dataField: "RUT_AFILIADO",
    text: "Rut afiliado",
    classes: "nowrap",
    headerClasses: 'vertical-center'

  },
  {
    dataField: "NOMBRE_AFILIADO",
    text: "Nombre afiliado",
    classes: "nowrap",
    headerClasses: 'vertical-center'
  },
  {
    dataField: "PERIODO",
    text: "Periodo remuneración",
    classes: 'text-center',
    headerClasses: 'text-center'
  },
  {
    dataField: "MONTO_PACTADO",
    text: "Monto Pactado",
    classes: 'text-center nowrap',
    headerClasses: 'text-center',
    formatter: (cell,row,rowIndex,formatExtraData)=> `$ ${row.MONTO_PACTADO}`
  },
  {
    dataField: "MONTO_PAGADO",
    text: "Monto Pagado",
    classes: 'text-center nowrap',
    headerClasses: 'text-center',
    formatter: (cell,row,rowIndex,formatExtraData)=> `$ ${row.MONTO_PAGADO}`

  },
  {
    dataField: "MONTO_DEUDA",
    text: "Deuda Capital",
    classes: 'text-center nowrap',
    headerClasses: 'text-center',
    formatter: (cell,row,rowIndex,formatExtraData)=> `$ ${row.MONTO_DEUDA}`

  }
];

class DetalleDeuda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: 0,
      dataFormateada: []
    };
  }

  componentDidMount() {
    this.props.dispatch(getDetalleDeuda());
  }
  componentWillReceiveProps(newProps){
    const { detalleDeuda } = newProps;
    if(detalleDeuda && detalleDeuda!==this.props.detalleDeuda){
    const dataFormateada =
      detalleDeuda && detalleDeuda.length > 0
        ? detalleDeuda
            .sort((a, b) => (a.PERIODO < b.PERIODO ? 1 : -1))
            .map((row, i) => {
              const formatRow = {
                ID: row.RUT_AFILIADO + row.PERIODO,
                NOMBRE_AFILIADO: row.NOMBRE_AFILIADO,
                RUT_AFILIADO: formatRut(String(row.RUT_AFILIADO) + row.DV),
                PERIODO: row.PERIODO,
                MONTO_PACTADO: formatearNumero(row.MONTO_PACTADO),
                MONTO_PAGADO: formatearNumero(row.MONTO_PAGADO),
                MONTO_DEUDA: formatearNumero(row.MONTO_DEUDA)
              };
              return formatRow;
            })
        : [];

        this.setState({dataFormateada: dataFormateada})}

  }

  handleOnClick = () => {
    this.props.dispatch(exportarExcel());
  };
  render() {
    const { detalleDeuda, exportandoExcel, cargandoTabla } = this.props;
    const deudaTotal =
      detalleDeuda &&
      detalleDeuda.length > 0 &&
      detalleDeuda.reduce((a, b) => {
        return a + b.MONTO_DEUDA;
      }, 0);

    

    return (
      <React.Fragment>
        <div className="row justify-content-left m-l-0">
          <h2>Detalle de deuda</h2>
        </div>
        <div className="row justify-content-left m-l-0">
          A continuación se presenta el detalle de la deuda no declarada capital
          que presenta el empleador
        </div>
        <div className="col-sm-11 m-l-0 p-l-0 m-t">
          <BootstrapTable
            bootstrap4
            keyField="ID"
            data={this.state.dataFormateada}
            columns={columns}
            loading={cargandoTabla}
            overlay={overlayFactory({ spinner: <Spinner animation="border" size="sm" />, background: 'rgba(192,192,192,0.3)' , text:" Cargando..."})}
            hover
            striped
            noDataIndication="  "
            bordered={ false }
            pagination={ paginationFactory({
              sizePerPage: 10,
              showTotal: false, // display pagination information
              withFirstAndLast: false, // hide the going to first and last page button
              alwaysShowAllBtns: true, // always show the next and previous page button
              firstPageText: 'Primero', // the text of first page button
              prePageText: 'Anterior', // the text of previous page button
              nextPageText: 'Siguiente', // the text of next page button
              lastPageText: 'Último', // the text of last page button
              nextPageTitle: 'Ir al siguiente', // the title of next page button
              prePageTitle: 'Ir al anterior', // the title of previous page button
              firstPageTitle: 'Ir al primero', // the title of first page button
              lastPageTitle: 'Ir al último', // the title of last page button
              hideSizePerPage: true, // hide the size per page dropdown
              hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
            }) }
          />

          
        </div>
        <div className="row justify-content-center m-t m-l m-r-xs">
          Nota: estos valores no constituyen el valor a pagar. Al momento de
          realizar el pago, se realizará el cálculo de intereses y gastos de
          cobranza en los casos que corresponda.
        </div>

        <div className="row justify-content-center m-t">
          <Button
            size="sm"
            id="exportar"
            variant={
              this.props.datosSession &&
              this.props.datosSession.frontOrigin === "B"
                ? "danger"
                : "success"
            }
            onClick={this.handleOnClick}
            disabled={exportandoExcel}
          >
            {" "}
            Exportar
            {exportandoExcel && (
              <Spinner
                className="m-l"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  detalleDeuda: state.detalleDeuda.detalleDeuda,
  cargandoTabla: state.detalleDeuda.cargandoTabla,
  exportandoExcel: state.detalleDeuda.exportandoExcel,
  datosSession: state.main.datosSession
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetalleDeuda);
