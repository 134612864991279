import React, { Component } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from 'react-bootstrap-table2-paginator';

import { formatearNumero } from "../../../helpers/util";
import { formatRut, rutClean, rutValidate } from "../../../helpers/rut";
import {
  getDatosContacto,
  eliminarContactoAct,
  agregarContacto,
  editarContacto,
} from "./actions";
// import MaskedFormControl from 'react-bootstrap-maskedinput';

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import BootstrapTable from "react-bootstrap-table-next";

import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { FaTrash } from "react-icons/fa";
import { FaPen } from "react-icons/fa";

class DatosContacto extends Component {
  constructor(props) {
    super(props);


    this.state = {
      show: false, // Modal Nuevo,
      showModalEditar: false, // Modal Editar,
      test: 0,
      sucursales: [],
      nombre: "",
      cargo: "",
      telefono: "",
      email: "",
      rut: "",
      modalData: "",
      editando: false,
      error: {
        nombre: null,
        rut: null,
        cargo: null,
        telefono: null,
        email: null
      },
      smShow: false,

    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.validateField = this.validateField.bind(this);
    this.nuevoContacto = this.nuevoContacto.bind(this);
    this.eliminarContacto = this.eliminarContacto.bind(this);
    this.toggleEditar = this.toggleEditar.bind(this);
    this.cancelarEditar = this.cancelarEditar.bind(this);
    this.smClose = this.smClose.bind(this);

    // Modal Nuevo
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    // Modal Editar
    this.handleShowEdit = this.handleShowEdit.bind(this);
    this.handleCloseEdit = this.handleCloseEdit.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.datos && newProps.datos !== this.props.datos)
      this.setState({
        nombre: "",
        cargo: "",
        telefono: "",
        email: "",
        rut: "",
        editando: false,
        show: false,
        showModalEditar: false,
        error: {
          nombre: null,
          rut: null,
          cargo: null,
          telefono: null,
          email: null
        },
        smShow: false,
        modalData: "",

      });
  }

  componentDidMount() {
    this.props.dispatch(getDatosContacto());
  }

  toggleEditar() {
    this.setState({ editando: true });
    this.setState({
      nombre: "",
      cargo: "",
      telefono: "",
      email: "",
      rut: "",
      modalData: "",
      error: {
        nombre: null,
        rut: null,
        cargo: null,
        telefono: null,
        email: null
      }
    });
  }

  cancelarEditar() {
    this.setState({ editando: false });
    this.setState({
      nombre: "",
      cargo: "",
      telefono: "",
      email: "",
      rut: "",
      modalData: "",
      error: {
        nombre: null,
        rut: null,
        cargo: null,
        telefono: null,
        email: null
      }
    });
  }
  handleOnChange(event) {

    const { id, name, value } = event.target;
    const re = /^[0-9\b]+$/;
    const re2 = /[0-9\b]+$/;

    if (id === "nombre") {
      if ((!re2.test(value) || value === "") && value.length < 40)
        this.setState({ [id]: value });
    } else if (id === "fax") {
      if ((value === "" || re.test(value)) && value.length < 9) {
        this.setState({ [id]: value });
      }
    } else if (name === "telefono") {
      const sinPar = value.replace('(', '');
      const sinP = sinPar.replace(')', '');
      const sinGui = sinP.replace('_', '');
      const sinEsp = sinGui.replace(' ', '');

      if ((value === "" || re.test(sinEsp)) && value.length <= 13) {
        this.setState({ [name]: sinEsp });
      }
    } else if (id === "numero") {
      if ((value === "" || re.test(value)) && value.length < 9) {
        this.setState({ [id]: value });
      }
    } else if (id === "rut") {
      if (value.length < 13)
        this.setState({ [id]: formatRut(rutClean(value)) });
    }
    else if (id === "cargo") {
      if ((value === "" || !re2.test(value)) && value.length < 30) {
        this.setState({ [id]: value });
      }
    }
    else if (value.length < 40) this.setState({ [id]: value });
  }

  eliminarContacto = data => {
    if (data && data !== "") {
      let rutDelete = data.split('-')[0];
      this.props.dispatch(eliminarContactoAct(this.replaceAll(rutDelete, '.', '')));
    }
    this.setState({ smShow: false, modalData: data });

  };

  replaceAll = (text, busca, reemplaza) => {
    while (text.toString().indexOf(busca) !== -1)
      text = text.toString().replace(busca, reemplaza);
    return text;
  }


  nuevoContacto = () => {
    if (this.validateField()) this.props.dispatch(agregarContacto(this.state));
  };

  editarContacto = () => {
    if (this.validateField()) this.props.dispatch(editarContacto(this.state));
  };

  validateField = () => {
    const error = {
      nombre: null,
      rut: null,
      cargo: null,
      telefono: null,
      email: null
    };
    const { nombre, rut, cargo, telefono, email } = this.state;

    if (nombre === "") error.nombre = "Campo obligatorio";

    if (rut === "") error.rut = "Campo obligatorio";
    else if (!rutValidate(rut)) error.rut = "Rut inválido";
    //  else if (this.props.datos.find((f) => rutClean(rut) === f.COEMP_NRUT_CONTACTO.toString() + f.COEMP_XDV_CONTACTO.toString())) error.rut = "Rut ya está agregado como contacto";
    if (cargo === "") error.cargo = "Campo obligatorio";

    if (telefono === "") error.telefono = "Campo obligatorio";
    else if (telefono.length < 10 || telefono.length > 10) error.telefono = "Teléfono debe tener formato código + número (ej: xx12345678)";
    if (email === "") error.email = "Campo obligatorio";
    else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      error.email = "Mail con formato inválido";
    }
    this.setState({ error: error });

    if (
      error.nombre ||
      error.rut ||
      error.cargo ||
      error.telefono ||
      error.email
    ) {
      return false;
    }
    return true;
  };

  smClose() {
    this.setState({ smShow: false });
  }

  openModal = (data) => {
    this.setState({ smShow: true, modalData: data });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleCloseEdit() {
    this.setState({ showModalEditar: false });
  }

  handleShowEdit(row) {
    this.setState({
      nombre: row.NOMBRE,
      cargo: row.CARGO,
      telefono: row.TELEFONO +' ', // Se agrega espacio para que el MaskedInput lo pueda procesar
      email: row.EMAIL,
      rut: row.RUT,
      showModalEditar: true
    });
  }


  render() {
    const { datos, guardando, cargando, mensajeError } = this.props;
    const { editando, error } = this.state;
    const columns = [
      {
        dataField: "NOMBRE",
        text: "Nombre",
        classes: "nowrap",
        headerClasses: 'vertical-center'

      },
      {
        dataField: "RUT",
        text: "Rut",
        classes: "nowrap",
        headerClasses: 'vertical-center'
      },
      {
        dataField: "CARGO",
        text: "Cargo",
        classes: 'text-center',
        headerClasses: 'text-center'
      },
      {
        dataField: "TELEFONO",
        text: "Teléfono",
        classes: 'text-center nowrap',
        headerClasses: 'text-center',
      },
      {
        dataField: "EMAIL",
        text: "Email",
        classes: 'text-center nowrap',
        headerClasses: 'text-center',
      },
      {
        dataField: "ELIMINAR",
        text: "Acciones",
        classes: 'text-center nowrap',
        headerClasses: 'text-center',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <div>
              <FaPen
                id={row.RUT.toString()}
                color="black"
                size={16}
                className="iconPointer"
                onClick={() => this.handleShowEdit(row)}
              />
              <FaTrash
                id={row.RUT.toString()}
                color="black"
                size={16}
                className="iconPointer"
                onClick={() => this.openModal(row.RUT)}
              />
            </div>
          )
        }
      }
    ];

    const dataFormateada =
      datos && datos.length > 0
        ? datos
          .sort((a, b) => (a.PERIODO < b.PERIODO ? 1 : -1))
          .map((row, i) => {
            const formatRow = {
              ID: row.COEMP_NRUT_CONTACTO,
              NOMBRE: row.COEMP_NOMBRE_CONTACTO,
              RUT: formatRut(String(row.COEMP_NRUT_CONTACTO) + row.COEMP_XDV_CONTACTO),
              DV: row.COEMP_XDV_CONTACTO,
              CARGO: row.COEMP_CARGO,
              EMAIL: row.COEMP_MAIL_CONTACTO,
              TELEFONO: row.COEMP_TFONO
            };
            return formatRow;
          })
        : [];

    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            Aquí podrá registrar los contactos de su empresa que serán
            responsables del contacto con la Isapre
          </div>
        </div>
        <div className="row justify-content-center m-t">
          <div className="col-sm-12">

            {/* MODAL EDITAR */}
            <Modal show={this.state.showModalEditar} onHide={this.handleCloseEdit}>
              <Modal.Header closeButton>
                <Modal.Title>Editar contacto</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>Rut *</Form.Label>
                  <InputGroup className="input" size="md">
                    <Form.Control
                      required
                      className="tableEditando"
                      id="rut"
                      value={this.state.rut}
                      onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback
                      className="displayErrorInside"
                      type="invalid">
                      {error.rut}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Nombre *</Form.Label>
                  <InputGroup className="input" size="md">
                    <Form.Control
                      required
                      className="tableEditando"
                      id="nombre"
                      value={this.state.nombre}
                      onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback
                      className="displayErrorInside"
                      type="invalid">
                      {error.nombre}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Cargo *</Form.Label>
                  <InputGroup className="input" size="md">
                    <Form.Control
                      required
                      className="tableEditando"
                      id="cargo"
                      value={this.state.cargo}
                      onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback
                      className="displayErrorInside"
                      type="invalid">
                      {error.cargo}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Teléfono *</Form.Label>
                  <InputGroup className="input" size="md">
                    <Form.Control
                        required
                        className="tableEditando"
                        id="telefono"
                        value={this.state.telefono}
                        onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback
                      className="displayErrorInside"
                      type="invalid">
                      {error.telefono}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email válido *</Form.Label>
                  <InputGroup className="input" size="md">
                    <Form.Control
                      required
                      className="tableEditando"
                      id="email"
                      value={this.state.email}
                      onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback
                      className="displayErrorInside"
                      type="invalid">
                      {error.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={this.handleCloseEdit}>
                  Cancelar
                </Button>
                <Button
                  id="editar"
                  size="sm"
                  variant={
                    this.props.datosSession &&
                      this.props.datosSession.frontOrigin === "B"
                      ? "danger"
                      : "success"
                  }
                  className="m-l"
                  onClick={this.editarContacto}
                  disabled={editando}
                >
                  Guardar
              {editando && (
                    <Spinner
                      className="m-l"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

            {/* MODAL NUEVO */}
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Nuevo contacto</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>Rut *</Form.Label>
                  <InputGroup className="input" size="md">
                    <Form.Control
                      required
                      className="tableEditando"
                      id="rut"
                      value={this.state.rut}
                      onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback
                      className="displayErrorInside"
                      type="invalid">
                      {error.rut}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Nombre *</Form.Label>
                  <InputGroup className="input" size="md">
                    <Form.Control
                      required
                      className="tableEditando"
                      id="nombre"
                      value={this.state.nombre}
                      onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback
                      className="displayErrorInside"
                      type="invalid">
                      {error.nombre}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Cargo *</Form.Label>
                  <InputGroup className="input" size="md">
                    <Form.Control
                      required
                      className="tableEditando"
                      id="cargo"
                      value={this.state.cargo}
                      onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback
                      className="displayErrorInside"
                      type="invalid">
                      {error.cargo}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Teléfono *</Form.Label>
                  <InputGroup className="input" size="md">
                    <Form.Control
                        required
                        className="tableEditando"
                        id="telefono"
                        value={this.state.telefono}
                        onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback
                      className="displayErrorInside"
                      type="invalid">
                      {error.telefono}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email válido *</Form.Label>
                  <InputGroup className="input" size="md">
                    <Form.Control
                      required
                      className="tableEditando"
                      id="email"
                      value={this.state.email}
                      onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback
                      className="displayErrorInside"
                      type="invalid">
                      {error.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={this.handleClose}>
                  Cancelar
                </Button>
                <Button
                  id="guardar"
                  size="sm"
                  variant={
                    this.props.datosSession &&
                      this.props.datosSession.frontOrigin === "B"
                      ? "danger"
                      : "success"
                  }
                  className="m-l"
                  onClick={this.nuevoContacto}
                  disabled={guardando}
                >
                  Guardar
              {guardando && (
                    <Spinner
                      className="m-l"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

            <BootstrapTable
              bootstrap4
              keyField="ID"
              data={dataFormateada}
              columns={columns}
              loading={cargando}
              overlay={overlayFactory({ spinner: <Spinner animation="border" size="sm" />, background: 'rgba(192,192,192,0.3)', text: " Cargando..." })}
              hover
              striped
              noDataIndication="  "
              bordered={false}
            />


          </div>
        </div>
        <div className="row justify-content-start">
          <Button
            size="sm"
            // eslint-disable-next-line react/jsx-no-duplicate-props
            variant={
              this.props.datosSession &&
                this.props.datosSession.frontOrigin === "B"
                ? "danger"
                : "success"
            }
            disabled={guardando}
            className="m-l"
            onClick={this.handleShow}>
            Agregar nuevo Contacto
            </Button>
        </div>
        <Modal
          size="sm"
          show={this.state.smShow}
          onHide={this.smClose}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-sm">
              Eliminar contacto
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Esta acción eliminará el contacto con rut: {this.state.modalData}</Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={this.smClose}>Cancelar</Button>
            <Button size="sm" onClick={() => this.eliminarContacto(this.state.modalData)} variant={
              this.props.datosSession &&
                this.props.datosSession.frontOrigin === "B"
                ? "danger"
                : "success"
            }>Aceptar</Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  datos: state.datosContacto.datos,
  datosSession: state.main.datosSession,
  sucursal: state.datosContacto.sucursal,
  guardando: state.datosContacto.guardando,
  cargando: state.datosContacto.cargando,
  mensajeError: state.datosContacto.mensajeError

});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatosContacto);
