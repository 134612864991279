import {
  GET_DETALLE_DEUDA,
  LEYURGENCIA_CARGANDO_TABLA,
  DETALLE_DEUDA_EXPORTANDO_EXCEL,
  PAGO_REDIRECT,
  PAGO_COTIZACIONES_PAGANDO,
  GET_LEY_URGENCIA
} from "../../../config/types";
import axios from "axios";
import data from "../../../helpers/data";
import uriConfig from "../../../config/api";
import store from "../../../store";

export const cargandoDatos = value => async dispatch => {
  dispatch({
    type: LEYURGENCIA_CARGANDO_TABLA,
    payload: value
  });
};

export const pagando = value => async dispatch => {
  dispatch({
    type: PAGO_COTIZACIONES_PAGANDO,
    payload: value
  });
};

export const exportandoExcel = value => async dispatch => {
  dispatch({
    type: DETALLE_DEUDA_EXPORTANDO_EXCEL,
    payload: value
  });
};
export const getLeyUrgencia = () => async dispatch => {
  dispatch(cargandoDatos(true));
  const { main } = store.getState();

  axios
    .post(`${uriConfig.apiCotizaciones.postObtenerLeyUrgencia}`,
    {
      P_RUTEMP: main.datosSession.rutEmpresa,
      P_ISAPRE: main.datosSession.frontOrigin,

    }
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: GET_LEY_URGENCIA,
          payload: res.data.rows
        });
        dispatch(cargandoDatos(false));
      }
      dispatch(cargandoDatos(false));
    })
    .catch(error => {
      dispatch({
        type: GET_DETALLE_DEUDA,
        payload: data
      });
      dispatch(cargandoDatos(false));
    });
};

export const realizarPago = params => async dispatch => {
  dispatch(pagando(true));
  const { main } = store.getState();
  dispatch({
    type: PAGO_REDIRECT,
    payload: false
  });
  const arrProcesado = params.arrPagar.map( (v) => {
    return {
      pr_empl_nrut: main.datosSession.rutEmpresa,
      pr_wuem_nrut: main.datosSession.rutUsuario,
      pr_isap_cempresa: main.datosSession.frontOrigin,
      pr_afil_nrut: v.AFIL_NRUT,
      pr_afil_tnombres: v.COPA_NOMBREAFILIADO,
      pr_wplu_nperiodo: v.PERIODO_REC.toString(),
      pr_wplu_fec_evento: "2019-06-24",
      pr_wplu_ncuota: v.COPA_NUMCUOTA.toString(),
      pr_wplu_total: v.TOTAL,
    }
  });
  localStorage.clear();
  axios
    .post(`${uriConfig.apiCotizaciones.postInsertarPagoCompletoLeyUrgencia}`, {
      data: arrProcesado
    })
    .then(res => {
      if (res.status === 201) {
        localStorage.setItem("pagoId", res.data.pagoId);
        localStorage.setItem("origen", "leyUrgencia");
        localStorage.setItem(
          "datosSession.rutEmpresa",
          main.datosSession.rutEmpresa
        );
        localStorage.setItem(
          "datosSession.rutUsuario",
          main.datosSession.rutUsuario
        );
        localStorage.setItem(
          "datosSession.frontOrigin",
          main.datosSession.frontOrigin
        );
        localStorage.setItem("token", res.data.token.InicializaPagoResult);
        localStorage.setItem("tokenApi", params.token);
        dispatch(pagando(false));

        console.log(res);
        dispatch({
          type: PAGO_REDIRECT,
          payload: true
        });
      } else {
        dispatch(pagando(false));
      }
    })
    .catch(error => {
      dispatch(pagando(false));
    });
};
