import React, { Component } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { getLeyUrgencia, realizarPago } from "./actions";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import "react-datepicker/dist/react-datepicker.css";
import { formatearNumero } from "../../../helpers/util";
import Form from "react-bootstrap/Form";
import uriConfig from "../../../config/api";

import "react-datepicker/dist/react-datepicker-cssmodules.css";

class PagoLeyUrgencia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arraySeleccionados: [],
      totalPagar: 0,
      checked: []
    };
    this.onChange = this.onChange.bind(this);
    this.handlePagar = this.handlePagar.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(getLeyUrgencia());
  }

  componentWillReceiveProps(newProps) {
    const { frontOrigin } = this.props.datosSession;

    if (newProps.redirect && newProps.redirect !== this.props.redirect)
    window.top.location =
      `http://${uriConfig.ambiente}portalpagos.isapre${frontOrigin==="B"?"banmedica":"vidatres"}.cl:5555/?id=${localStorage.getItem("token")}`;
  }

  handlePagar() {
    const { datosLeyUrgencia, token } = this.props;
    const arrPagar = datosLeyUrgencia.filter((v, i) => this.state.checked[i]);
    this.props.dispatch(realizarPago({ arrPagar, token }));
  }

  onChange(event) {
    const { checked } = this.state;
    const { id } = event.target;
    const idArray = parseInt(id.split(":")[1]);
    const copy = checked.slice();
    copy[idArray] = event.target.checked;
    this.setState({ checked: copy });
  }
  render() {
    const { datosLeyUrgencia, cargandoTabla, pagando } = this.props;
    return (
      <React.Fragment>
        <div className="row justify-content-left h2 m-l">
          <p>Ley de urgencia</p>
        </div>
        <div className="row justify-content-left m-l">
          <p>
            A continuación se despliega el listado de trabajadores que realizan
            pagos en su remuneración por Ley de Urgencia
          </p>
        </div>
        <div className="col-sm-11 m-l-0 p-l-0 m-t">
          <Table className="tableDeudaPago m-l-xs m-t-xs m-b-xs">
            <thead>
              <tr>
                <th className="vertical-center">Período</th>
                <th className="vertical-center">Rut</th>
                <th className="vertical-center">Nombre</th>
                <th className="vertical-center">N° Cuotas</th>
                <th className="vertical-center">Valor deuda total</th>
                <th className="vertical-center">Valor cuota</th>
                <th className="vertical-center">Intereses</th>
                <th className="vertical-center">Reajustes</th>
                <th className="vertical-center">Gastos de cobranza</th>
                <th className="vertical-center">Monto total</th>
                <th className="vertical-center" />
              </tr>
            </thead>
            <tbody>
              {!cargandoTabla ? (
                datosLeyUrgencia && datosLeyUrgencia.length > 0 ? (
                  datosLeyUrgencia.map((row, i) => (
                    <tr>
                      <td className="nowrap text-left">{row.PERIODO_REC}</td>
                      <td className="text-left">{row.AFIL_NRUT}</td>
                      <td className="nowrap text-center">
                        {row.COPA_NOMBREAFILIADO}
                      </td>
                      <td className="nowrap text-right">
                        {`${row.CUOTA}/${row.COPA_NUMCUOTA}`}
                      </td>
                      <td className="nowrap text-right">
                        $ {formatearNumero(row.COPA_MMONTO_DEUDA)}
                      </td>
                      <td className="nowrap text-right">
                        $ {formatearNumero(row.COPA_VALORCUOTA)}
                      </td>
                      <td className="nowrap text-right">
                        $ {formatearNumero(row.INTERES)}
                      </td>
                      <td className="nowrap text-right">
                        $ {formatearNumero(row.REAJUSTE)}
                      </td>
                      <td className="nowrap text-right">
                        $ {formatearNumero(row.GASTO_COBRANZA)}
                      </td>
                      <td className="nowrap text-right">
                        $ {formatearNumero(row.TOTAL)}
                      </td>
                      <td className="nowrap text-right">
                        <Form.Check
                          custom
                          checked={this.state.checked[i]}
                          onChange={this.onChange}
                          id={`${row.AFIL_NRUT}_${row.PERIODO_REC}:${i}`}
                          inline
                          label=""
                          type="checkbox"
                          className="m-r-0"
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="nowrap text-center" colSpan="11">
                      No hay datos.
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td className="text-center" colSpan="11">
                    Cargando...
                    <Spinner
                      className="nowrap m-l"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div className="row justify-content-right m-l">
          <div className="col-sm-4 offset-8 m-t">
            <Button
              size="sm"
              onClick={this.handlePagar}
              disabled={pagando || !this.state.checked.find(v => v)}
              variant={
                this.props.datosSession &&
                this.props.datosSession.frontOrigin === "B"
                  ? "danger"
                  : "success"
              }
            >
              Pagar seleccionados{" "}
              {pagando && (
                <Spinner
                  className="m-l"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  datosLeyUrgencia: state.pagoLeyUrgencia.datosLeyUrgencia,
  cargandoTabla: state.pagoLeyUrgencia.cargandoTabla,
  redirect: state.pagoLeyUrgencia.redirect,
  exportandoExcel: state.pagoLeyUrgencia.exportandoExcel,
  pagando: state.pagoLeyUrgencia.pagando,
  datosSession: state.main.datosSession
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PagoLeyUrgencia);
