import {
  GET_DETALLE_DEUDA,
  PAGO_EXITOSO_CARGANDO_TABLA,
  DETALLE_DEUDA_EXPORTANDO_EXCEL,
  PAGO_REDIRECT,
  PAGO_EXITOSO_DETALLE,
  PAGO_EXITOSO_DESCARGANDO,
  INTERNAL_ERROR
} from "../../config/types";
import pagoExitosoState from "./state";

export default function(state = pagoExitosoState, action) {
  switch (action.type) {
    case GET_DETALLE_DEUDA:
      return Object.assign({}, state, {
        detalleDeuda: action.payload
      });
      case PAGO_EXITOSO_DETALLE:
      return Object.assign({}, state, {
        datosPago: action.payload
      });
    case PAGO_EXITOSO_CARGANDO_TABLA:
      return Object.assign({}, state, {
        cargandoTabla: action.payload
      });
    case DETALLE_DEUDA_EXPORTANDO_EXCEL:
      return Object.assign({}, state, {
        exportandoExcel: action.payload
      });
    case PAGO_REDIRECT:
      return Object.assign({}, state, {
        redirect: action.payload
      });
      case PAGO_EXITOSO_DESCARGANDO:
        return Object.assign({}, state, {
          descargando: action.payload
        });
    case INTERNAL_ERROR:
      return Object.assign({}, state, {
        internalerror: action.payload,
      });
    default:
      return state;
  }
}
