const cotizaciones = {
  detalleDeuda: "detalleDeuda",
  detalleDeudaDnp: "detalleDeudaDnp",
  detalleDeudaNdnp: "detalleDeudaNdnp",
  certificados: "certificados",
  calcularIntereses: "calcularIntereses",
  pagoCotizaciones: "pagoCotizaciones",
  pagoCotizacionesExitoso: "pagoCotizacionesExitoso",
  pagoCotizacionesFracaso: "pagoCotizacionesFracaso",
  historialPagoCotizaciones: "historialPagoCotizaciones",
  pagoLeyUrgencia: "pagoLeyUrgencia"
};

const empleador = {
  datosEmpleador: "datosEmpleador",
  datosContacto: "datosContacto",
  movimientoPersonal: "movimientoPersonal",
};

const deudaEmpresa = 'deudaEmpresa';

const pathsConfig = {
  cotizaciones,
  empleador,
  deudaEmpresa
};

export default pathsConfig;
