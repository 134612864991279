import { dateFilter } from "react-bootstrap-table2-filter";

/**
 * Limita el largo del telefono a 9 números
 * @param {string} telefono
 * @returns {string} telefono formateado
 */
const formatTelefono = (tel) => (tel ? tel.toString().substring(0, 9).replace(/[^0-9]+/g, "") : "");

/**
 * Agrega separador de miles a un número
 * @param {string} numero de entrada
 * @returns {string} número formateado
 */
const formatearNumero = (valor) => (typeof valor === "number" ? parseInt(valor.toFixed(0), 10).toLocaleString("es-CL") : parseInt(valor && valor.length > 0 ? valor.replace(/[^0-9]+/g, "") : "0", 10).toLocaleString("es-CL"));

/**
 * Quita separador de miles a un número
 * @param {string} valor formateado
 * @returns {number} entero
 */
const limpiarNumero = (valor) => (!Number.isNaN(valor) && valor.length > 0 ? parseInt(valor.replace(/[^0-9]+/g, ""), 10) : 0);

/**
 * Quita separador de miles a un número (usa IsNaN para determinar si es número)
 * @param {string} valor formateado
 * @returns {number} entero
 */
const limpiarNumero2 = (valor) => (!isNaN(valor) && valor.length > 0 ? parseInt(valor.replace(/[^0-9]+/g, ""), 10) : "");

/**
 * Formatear float
 * @param {any} numero formateado
 * @returns {string} float
 */
const formatearFloat = (valor) => (typeof valor === "number" ? valor.toLocaleString("es-CL")  : parseFloat(valor.length > 0 ? valor.replace(/[^0-9]/g, "").replace(",", ".") : "0").toLocaleString("es-CL"));

/**
 * Formatear float
 * @param {any} numero formateado
 * @param {any} decimales cantidad de decimales
 * @returns {string} float
 */
const formatearFloatDecimales = (valor, decimales) => (typeof valor === "number" ? parseFloat(valor.toLocaleString("es-CL")).toFixed(decimales)  : parseFloat(valor.length > 0 ? valor.replace(/[^0-9]/g, "").replace(",", ".") : "0").toFixed(decimales).toLocaleString("es-CL"));

/**
 * Verifica si el valor es número
 * @param {string} numero formateado
 * @returns {boolean} entero
 */
const esNumero = (valor) => !((valor === null || valor === undefined || Number.isNaN(valor) || typeof valor !== "number"));

/**
 * Agrega separador de miles a un string
 * @param {string} valor String sin formato
 * @returns {number} valor formateado en miles en tipo string
 */
const formatearMiles = (valor) => valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

/**
 * Remueve todos los puntos de un string. Ejemplo : 123.456 => 123456
 * @param {string} value : Valor a evaluar
 * @returns {string} : String sin puntos
 */
const removeDotsFromString = (value) => ((value) ? value.toString().split(".").join("") : "");
/**
 * Evalua una expresión regular a un valor determinado.
 * @param {RegExp} rgEx : Expresión regular
 * @param {string} value : Valor a evaluar
 * @returns {boolean} : Resultado de la validación
 */
/**
 * Agrega separador de miles a un número
 * @param {string} fecha : Fecha de entrada
 * @returns {string} fecha formateada 
 */
const formatearFecha = (valor) => { 
  var d = new Date(valor);
  valor = ((d.getMonth() + 1) < 10) ? `${d.getDate()}/0${d.getMonth() + 1}/${d.getFullYear()}` : `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  return valor;
};

const evalRegEx = (rgEx, value) => rgEx.test(value);

const isInt = (n) => Number(n) === n && n % 1 === 0;

const isFloat = (n) => Number(n) === n && n % 1 !== 0;

export { isInt, isFloat,
  formatTelefono,  evalRegEx, formatearNumero,
  formatearMiles, limpiarNumero, removeDotsFromString, esNumero, formatearFloat,
  formatearFloatDecimales, limpiarNumero2, formatearFecha };
