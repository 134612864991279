import React, { Component } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { getHistorialPago, descargarPlanilla } from "./actions";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import { formatearNumero, formatearFecha } from "../../../helpers/util";
import { formatRut } from "../../../helpers/rut";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import uriConfig from "../../../config/api";

import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from 'react-bootstrap-table2-paginator';

import { FaFileAlt } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const moment = require('moment');

class HistorialPagoCotizaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFormateada: [],
      show: false,
      modalData: []
    };

    this.detallePagoId = this.detallePagoId.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(getHistorialPago(this.props.datosSession));
  }

  componentWillReceiveProps(newProps) {
    const { detalleHistorial } = newProps;
    if (detalleHistorial[0] && detalleHistorial[0] !== this.props.detalleHistorial) {
      const dataFormateada =
        detalleHistorial[0] && detalleHistorial[0].length > 0
          ? detalleHistorial[0]
            // .sort((a, b) => (a.WPCE_ID_PAGO < b.WPCE_ID_PAGO ? 1 : -1))
            .map((row, i) => {
              // console.log(row)
              const formatRow = {
                ID_PAGO: row.WPCE_ID_PAGO,
                PPLAN_NFOLIO_PAGO: row.PPLAN_NFOLIO_PAGO,
                PLAN_NFOLIO: row.PLAN_NFOLIO,
                PLAN_NPERIODO: row.PLAN_NPERIODO,
                WPCE_MONTO: formatearNumero(row.WPCE_MONTO),
                WPCE_TIPO: row.WPCE_TIPO,
                WPCE_FECHA: formatearFecha(row.WPCE_FECHA)

                // ID: row.EMPL_NRUT + row.PLAN_NPERIODO,
                // PLAN_NFOLIO: row.PLAN_NFOLIO,
                // WUEM_NRUT: formatRut(String(row.WUEM_NRUT)),
                // PERIODO: row.PERIODO,
                // MONTO_PACTADO: formatearNumero(row.MONTO_PACTADO),
                // MONTO_PAGADO: formatearNumero(row.MONTO_PAGADO),
                // MONTO_DEUDA: formatearNumero(row.MONTO_DEUDA)
              };
              return formatRow;
            })
          : [];

      this.setState({ dataFormateada: dataFormateada })
    }

  }

  detallePagoId(data) {
    this.props.dispatch(descargarPlanilla(data))
  }

  handleClose() {
    this.setState({ show: false })
  }

  render() {
    const { historialPago, cargandoTabla } = this.props;
    const columns = [
      {
        dataField: "PPLAN_NFOLIO_PAGO",
        text: "Folio de pago",
        classes: "nowrap",
        headerClasses: 'vertical-center'
      },/*
      {
        dataField: "PLAN_NPERIODO",
        text: "Periodo planilla",
        classes: 'text-center nowrap',
        headerClasses: 'text-center',
      },*/
      {
        dataField: "WPCE_FECHA",
        text: "Fecha Pago",
        classes: 'text-center nowrap',
        headerClasses: 'text-center',
      },
      {
        dataField: "WPCE_MONTO",
        text: "Monto total",
        classes: 'text-center nowrap',
        headerClasses: 'text-center',
        formatter: (cell, row, rowIndex, formatExtraData) => `$ ${row.WPCE_MONTO}`
      },
      {
        dataField: "WPCE_TIPO",
        text: "Tipo",
        classes: 'text-center nowrap',
        headerClasses: 'text-center',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <Badge variant="secondary">{row.WPCE_TIPO === '4' ? 'DNP' : row.WPCE_TIPO === '5'? 'DNP' : 'NDNP'}</Badge>
          )
        }
      },
      {
        dataField: "DETALLE",
        text: "Planilla",
        classes: 'text-center nowrap',
        headerClasses: 'text-center',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <div>
              <FaFileAlt
                key={row.PPLAN_NFOLIO_PAGO.toString()}
                id={row.PPLAN_NFOLIO_PAGO.toString()}
                color="gray"
                size={16}
                className="iconPointer"
                onClick={() => this.detallePagoId(row)}>
              </FaFileAlt>
            </div>
          )
        }
      },
    ];

    return (
      <React.Fragment>
        <div className="row justify-content-left m-l-0">
          <h2>Historial de pagos</h2>
        </div>
        <div className="col-sm-12 m-l-0 p-l-0 m-t">
          {/*<Modal show={this.state.show} onHide={this.handleClose}>*/}
          {/*  <Modal.Header closeButton>*/}
          {/*    <Modal.Title>Detalle de pago</Modal.Title>*/}
          {/*  </Modal.Header>*/}
          {/*  <Modal.Body>*/}
          {/*    <div className={this.props.datosSession.frontOrigin === 'B' ? 'banmedica' : 'vidaTres'}>*/}
          {/*      <table className={'table table-hover table-striped'}>*/}
          {/*        <thead>*/}
          {/*          <tr>*/}
          {/*            <th>Items</th>*/}
          {/*            <th>Detalle</th>*/}
          {/*          </tr>*/}
          {/*        </thead>*/}
          {/*        <tbody>*/}
          {/*          <tr>*/}
          {/*            <td>ID</td>*/}
          {/*            <td>#{this.state.modalData.ID_PAGO}</td>*/}
          {/*          </tr>*/}
          {/*          <tr>*/}
          {/*            <td>Folio Planilla</td>*/}
          {/*            <td>{this.state.modalData.PPLAN_NFOLIO_PAGO}</td>*/}
          {/*          </tr>*/}
          {/*          <tr>*/}
          {/*            <td>Folio de Pago</td>*/}
          {/*            <td>{this.state.modalData.PLAN_NFOLIO}</td>*/}
          {/*          </tr>*/}
          {/*          <tr>*/}
          {/*            <td>Periodo</td>*/}
          {/*            <td>{this.state.modalData.PLAN_NPERIODO}</td>*/}
          {/*          </tr>*/}
          {/*          <tr>*/}
          {/*            <td>Tipo</td>*/}
          {/*            <td>{this.state.modalData.WPCE_TIPO}</td>*/}
          {/*          </tr>*/}
          {/*          <tr>*/}
          {/*            <td>Fecha de pago</td>*/}
          {/*            <td>{moment(this.state.modalData.WPCE_FECHA).locale('es-CL').format('DD-MM-YYYY')}</td>*/}
          {/*          </tr>*/}
          {/*          <tr>*/}
          {/*            <td>Monto Total</td>*/}
          {/*            <td>${formatearNumero(this.state.modalData.WPCE_MONTO)}</td>*/}
          {/*          </tr>*/}
          {/*        </tbody>*/}
          {/*      </table>*/}
          {/*    </div>*/}
          {/*  </Modal.Body>*/}
          {/*  <Modal.Footer>*/}
          {/*    <Button variant="secondary" size="sm" onClick={this.handleClose}>*/}
          {/*      Cerrar*/}
          {/*      </Button>*/}
          {/*  </Modal.Footer>*/}
          {/*</Modal>*/}


          <BootstrapTable
            bootstrap4
            keyField="ID_PAGO"
            data={this.state.dataFormateada}
            columns={columns}
            loading={cargandoTabla}
            overlay={overlayFactory({ spinner: <Spinner animation="border" size="sm" />, background: 'rgba(192,192,192,0.3)', text: " Cargando..." })}
            hover
            striped
            noDataIndication="NO SE HAN REALIZADO PAGOS"
            bordered={false}
            pagination={paginationFactory({
              sizePerPage: 15,
              showTotal: false, // display pagination information
              withFirstAndLast: false, // hide the going to first and last page button
              alwaysShowAllBtns: true, // always show the next and previous page button
              firstPageText: 'Primero', // the text of first page button
              prePageText: 'Anterior', // the text of previous page button
              nextPageText: 'Siguiente', // the text of next page button
              lastPageText: 'Último', // the text of last page button
              nextPageTitle: 'Ir al siguiente', // the title of next page button
              prePageTitle: 'Ir al anterior', // the title of previous page button
              firstPageTitle: 'Ir al primero', // the title of first page button
              lastPageTitle: 'Ir al último', // the title of last page button
              hideSizePerPage: true, // hide the size per page dropdown
              hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
            })}
          />


        </div>
      </React.Fragment >
    );
  }
}

const mapStateToProps = state => ({
  detalleHistorial: state.historialPagoCotizaciones.detalleHistorial,
  cargandoTabla: state.historialPagoCotizaciones.cargandoTabla,
  redirect: state.historialPagoCotizaciones.redirect,
  exportandoExcel: state.historialPagoCotizaciones.exportandoExcel,
  datosSession: state.main.datosSession,

});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistorialPagoCotizaciones);
