import {
  GET_DATOS_CONTACTO,
  DATOS_CONTACTO_CARGANDO,
  DATOS_CONTACTO_GUARDANDO,
  DATOS_CONTACTO_MENSAJE_ERROR
} from "../../../config/types";
import axios from "axios";
import uriConfig from "../../../config/api";
import store from "../../../store";
import { toastr } from 'react-redux-toastr'

export const guardandoDatos = value => async dispatch => {
  dispatch({
    type: DATOS_CONTACTO_GUARDANDO,
    payload: value
  });
};
export const cargandoDatos = value => async dispatch => {
  dispatch({
    type: DATOS_CONTACTO_CARGANDO,
    payload: value
  });
};

export const mensajeError = value => async dispatch => {
  dispatch({
    type: DATOS_CONTACTO_MENSAJE_ERROR,
    payload: value
  });
};

export const getDatosContacto = () => async dispatch => {
  dispatch({
    type: GET_DATOS_CONTACTO,
    payload: null
  });
  dispatch(cargandoDatos(true));
  const { main } = store.getState();
  axios
    .get(uriConfig.apiEmpleador.getDatosContacto(main.datosSession.rutEmpresa))
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: GET_DATOS_CONTACTO,
          payload: res.data.rows
        });
      }
      dispatch(cargandoDatos(false));
    })
    .catch(error => {
      dispatch(cargandoDatos(false));
    });
};

export const eliminarContactoAct = data => async dispatch => {
  const { main } = store.getState();
  axios
    .delete(
      `${uriConfig.apiEmpleador.eliminarContacto(
        main.datosSession.rutEmpresa,
        parseInt(data)
      )}`,
      {}
    )
    .then(res => {
      if (res.status === 200 || res.status === 202) {
        dispatch(getDatosContacto());
        dispatch(
          toastr.success("Felicidades!", "El contacto fue eliminado correctamente")
        );
      }
    })
    .catch(error => { });
};

export const agregarContacto = data => async dispatch => {
  dispatch(mensajeError(""));
  dispatch(guardandoDatos(true));
  const { main } = store.getState();
  axios
    .post(
      `${uriConfig.apiEmpleador.agregarContacto(main.datosSession.rutEmpresa)}`,
      {
        rutContacto: data.rut
          .split("-")[0]
          .split(".")
          .join(""),
        rutEmpresa: main.datosSession.rutEmpresa,
        isapre: main.datosSession.frontOrigin,
        dvComntacto: data.rut.split("-")[1],
        nombreContacto: data.nombre,
        mailContacto: data.email,
        fonoContacto: data.telefono,
        cargo: data.cargo
      }
    )
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        dispatch(getDatosContacto());

        dispatch(
          toastr.success("Felicidades!", "El contacto fue creado correctamente")
        );
      }
      dispatch(guardandoDatos(false));
      dispatch(mensajeError(""));
    })
    .catch(error => {
      dispatch(guardandoDatos(false));
      dispatch(mensajeError("Ha ocurrido un error al agregar el nuevo contacto."));
    });
};

export const editarContacto = data => async dispatch => {
  dispatch(mensajeError(""));
  dispatch(guardandoDatos(true));
  const { main } = store.getState();
  axios
    .put(
      `${uriConfig.apiEmpleador.editarContacto(main.datosSession.rutEmpresa)}`,
      {
        rutContacto: data.rut
          .split("-")[0]
          .split(".")
          .join(""),
        rutEmpresa: main.datosSession.rutEmpresa,
        isapre: main.datosSession.frontOrigin,
        dvComntacto: data.rut.split("-")[1],
        nombreContacto: data.nombre,
        mailContacto: data.email,
        fonoContacto: data.telefono,
        cargo: data.cargo
      }
    )
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        dispatch(getDatosContacto());

        dispatch(
          toastr.success("Felicidades!", "El contacto fue editado correctamente")
        );
      }
      dispatch(guardandoDatos(false));
      dispatch(mensajeError(""));
    })
    .catch(error => {
      dispatch(guardandoDatos(false));
      dispatch(mensajeError("Ha ocurrido un error al editar el contacto."));
    });
};
