import React, { Component } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { connect } from "react-redux";

class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    const { datosSession, msg } = this.props;
    const { frontOrigin } = datosSession;

    return (
      <React.Fragment>
        <div
          role="main"
          className={`container-fluid ${
            frontOrigin === "B" ? "banmedica" : "vidaTres"
          } contenedor`}
          id="contenedor"
          name="contenedor"
        >
          <div className="row justify-content-center m-l-lg m-t-lg-2 h2">
            <div className="col-sm-12">
              <div className="alert alert-danger" role="alert">
                <h4 className="alert-heading"><FaTimesCircle color="red" className="m-r m-b-7" /> Error!</h4>
                <p>Ocurrió un error inesperado</p>
                <hr/>
                <p className="mb-0">{msg || " "}</p>
              </div>

              {/*<FaTimesCircle color="red" className="m-r m-b-7" />*/}
              {/*<span className="m-t-5">*/}
              {/*  ¡Ocurrió un error inesperado!, intenta nuevamente.*/}
              {/*</span>*/}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  datosSession: state.main.datosSession
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Error);
